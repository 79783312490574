import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";

// import fr from "moment/locale/fr"; // without this line it didn't work
// Moment.locale("fr");
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contenuListeActions from "../../store/contenuListe/actions";
export default class contenuListeSkeleton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="p-b-200">
        <div className="col-xl-12 col-lg-12 event-page">
          <div className="row m-b-15">
            <div class="info-content">
              <h1>Contenus</h1>
              <span>
                Crees avec <i class="fa fa-heart animated infinite pulse"></i>{" "}
                par nos apprenants{" "}
              </span>
            </div>

            <div className="row content-block">
              <div className="col-md-4 dis-flex mb-5">
                <div class="column">
                  <div class="post-module">
                    <Skeleton width={`100%`} height={`100%`} />
                  </div>
                </div>
              </div>
              <div className="col-md-4 dis-flex mb-5">
                <div class="column">
                  <div class="post-module">
                    <Skeleton width={`100%`} height={`100%`} />
                  </div>
                </div>
              </div>
              <div className="col-md-4 dis-flex mb-5"> 
                <div class="column">
                  <div class="post-module">
                    <Skeleton width={`100%`} height={`100%`} />
                  </div>
                </div>
              </div>
              <div className="col-md-4 dis-flex mb-5">
                <div class="column">
                  <div class="post-module">
                    <Skeleton width={`100%`} height={`100%`} />
                  </div>
                </div>
              </div>
              <div className="col-md-4 dis-flex mb-5">
                <div class="column">
                  <div class="post-module">
                    <Skeleton width={`100%`} height={`100%`} />
                  </div>
                </div>
              </div>
              <div className="col-md-4 dis-flex mb-5">
                <div class="column">
                  <div class="post-module">
                    <Skeleton width={`100%`} height={`100%`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ contenuListe }) => ({ ...contenuListe }),
//     dispatch => bindActionCreators({ ...contenuListeActions }, dispatch)
//   )( contenuListe );
