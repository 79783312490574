import React, { Component } from "react";
import { useState } from "react";

import Header from "../header/header";
import HeaderDashboard from "../headerDashboard/index";
import NavVertical from "../navVertical/index";
import Content from "../content/content";
import Button from "../button/button";
import Adresse from "../adresse/adresse";

import Profil from "../profil/Profil";

import Webinar from "../webinar/webinar";
import CardRentree from "../cardRentree/cardRentree";
import CardStage from "../cardStage/cardStage";
import ContentModal from "../contentModal/contentModal";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as viewAccueilActions from "../../store/viewAccueil/actions";

export default class viewProfil extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      //   <div className="component-view-accueil">
      //     <Header />
      //     <div className="container-fluid px-0 contain-start">
      //       <div className="row">
      //         <div className="col-xl-2 col-lg-4 col-md-5 col-sm-12 px-0">
      //           <Sidebar />
      //         </div>
      //         <div className="col-xl-10 col-lg-8 col-md-7 col-sm-12">
      //           <div className="container-fluid">
      //             <div className="row">
      //               <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12">
      //               <Profil />
      //               </div>
      //               <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
      //               <div className="col-xl-12">
      //                       <ContentModal />
      //                   </div>
      //                   <div className="col-xl-12">
      //                       <Button />
      //                   </div>
      //                   <div className="col-xl-12">
      //                       <Adresse />
      //                   </div>
      //                   <div className="col-xl-12">
      //                       <Webinar />
      //                   </div>
      //                   <div className="col-xl-12">
      //                       <CardRentree />
      //                   </div>
      //                   <div className="col-xl-12">
      //                       <CardStage />
      //                   </div>
      //               </div>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // );
      <div className="component-view-message">
        {" "}
        <HeaderDashboard />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVertical />
            </div>
            <div className="col-lg-10 pt-3">
              <Profil />
            </div>
            <div className="col-xl-3 col-lg-3 pr-3 my-5 py-4">
            <div className="row">
              <div className="col-sm-12 width_card">
                <Button />
              </div>
              <div className="col-sm-12 width_card">
                <Adresse />
              </div>
              <div className="col-sm-12 width_card">
                <Webinar />
              </div>
              <div className="col-sm-12 width_card">
                <cardRentree />
              </div>
              <div className="col-sm-12 width_card">
                <CardStage />
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ viewAccueil }) => ({ ...viewAccueil }),
//     dispatch => bindActionCreators({ ...viewAccueilActions }, dispatch)
//   )( viewAccueil );
