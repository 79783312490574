import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Connexion from "./components/connexion/index";
import DashboardBakelisteView from "./components/dashboardBakelisteView/index";
import AccountBakelisteView from "./components/accountBakelisteView/index";
import ViewEvenement from "./components/viewEvenement/index";
import Inscription from "./components/inscription/index";
import viewAccueil from "./components/viewAccueil/index";
import ViewCandidature from "./components/ViewCandidature/index";
import ViewDemandeCandidature from "./components/viewDemandeCandidature/index";
import ViewDossierCandidature from "./components/viewDossierCandidature/index";
import ViewOffreStage from "./components/viewOffreStage/index";
import ViewPostuler from "./components/viewPostuler/index";
import ViewAnnonces from "./components/viewAnnonces/index";
import ViewSenDeal from "./components/viewSenDeal/index";
import ViewSendealPostule from "./components/viewSendealPostule/index";
import ViewProfil from "./components/viewProfil/index";
import ViewFormations from "./components/viewFormations/ViewFormations";
import RecuperationParmail from "./components/recuperation/recupMail/index";
import RecuperationParTelephone from "./components/recuperation/recupTelephone/index";
import ViewProgrammation from "./components/viewProgrammation/index";
import ViewMarketing from "./components/viewMarketing/index";
import ViewGestionProjet from "./components/viewGestionProjet/index";
import ViewBureautique from "./components/viewBureautique/index";
import ViewAnglais from "./components/viewAnglais/index";
import ContentPhoto from "./components/contentPhoto/index";
import viewPhoto from "./components/viewPhoto/index";
import ViewVideo from "./components/viewVideo/index";
import ViewEventKokutana from "./components/viewEventKokutana/index";
import ViewEventJeemacoder from "./components/viewEventJeemacoder/index";
import ViewSavoirplus from "./components/viewSavoirplus/index";
import ViewReservation from "./components/viewReservation/index";
import ViewMessage from "./components/viewMessage/index";
// import ViewReservation from "./components/viewReservation/index";
// import Provider from "react-redux";
import Store from "./Store/configureStore";
import ChangePassword from "./components/changePassword/index";
import ViewDesign from "./components/viewDesign/index";
import CardLeaflet from "./components/cardLeaflet/index";
import WPPost from "./components/wppost/index";
import viewEvenement from "./components/viewEvenement/index";
import CandidatureForm from "./components/candidatureForm/candidatureForm";
import CandidatureFormAvril from "./components/candidatureForm/candidatureFormAvril";
import CandidatureFormJuillet from "./components/candidatureForm/candidatureFormJuillet"
import CandidatureFormEnLigne from "./components/candidatureForm/candidatureFormEnLigne";

import MesEvenementsView from "./components/mesEvenementsView/index";
import InscriptionRencontre from "./components/inscriptionRencontre/index";

import ViewnetworkLeading from "./components/viewnetworkLeading/index";
import inscriptionRencontreGuinee from "./components/inscriptionRencontreGuinee/inscriptionRencontreGuinee";
import ContenuListeView from "./components/contenuListeView/index";
import DetailsContenuView from "./components/detailsContenuView/detailsContenuView";
import InscriptionMasterclass from "./components/inscriptionMasterclass/inscriptionMasterclass";
import InscriptionMasterclassDev from "./components/inscriptionMasterclassDev/inscriptionMasterclassDev";
import InscriptionMasterClassDesign from "./components/inscriptionMasterClassDesign/inscriptionMasterClassDesign";
import FormationsView from "./components/formationsView/index";
import CandidatureSpecialForm from "./components/candidatureSpecialForm/index";
import InscriptionBakeliMbour from "./components/inscriptionBakeliMbour/inscriptionBakeliMbour";
import InscriptionMbour from "./components/inscriptionMbour/index";
import InscriptionEss from "./components/inscriptionEss/inscriptionEss";
import candidatureFormMai from "./components/candidatureForm/candidatureFormMai";

function App() {
  return (
    // <Provider store={Store}>
    <Router>
      <div>
        <Route exact path="/" component={ViewnetworkLeading} />
        <Route path="/connexion" component={Connexion} />
        {/* <Route path="/inscription-bakeli" component={CandidatureForm} /> */}
        <Route path="/inscription-bakeli-fevrier" component={CandidatureForm} />
        <Route path="/inscription-bakeli-avril" component={CandidatureFormAvril} />
        <Route path="/inscription-bakeli-juillet" component={CandidatureFormJuillet} />

        
        <Route path="/inscription-bakeli-mai" component={candidatureFormMai} />
        <Route path="/inscription-formation-en-ligne" component={CandidatureFormEnLigne} />

        <Route path="/inscription-bakeli-mbour-thies" component={InscriptionBakeliMbour} />
        <Route path="/inscription-bakeli-mbour" component={InscriptionMbour} />
        <Route path="/inscription-bakeli-espace-senegal-service" component={InscriptionEss} />
        <Route path="/inscription-programme-bakeli" component={CandidatureSpecialForm} />
        <Route
          path="/inscription-masterclass-marketing-digital"
          component={InscriptionMasterclass}
        />
        <Route
          path="/inscription-masterclass-design"
          component={InscriptionMasterClassDesign}
        />
        <Route
          path="/inscription-masterclass"
          component={InscriptionMasterclassDev}
        />

        <Route
          path="/workshop-bakeli-guinee/:id"
          component={inscriptionRencontreGuinee}
        />
         <Route
          path="/inscription-rencontre/:id"
          component={InscriptionRencontre}
        />
        {/* <Route
          path="/journée-portes-ouvertes"
          component={InscriptionRencontre}
        /> */}
          {/* <Route
          path="/workshop-en-wordpress-guinee"
          component={InscriptionRencontre}
        /> */}
        <Route path="/dashboard-bakeliste" component={DashboardBakelisteView} />

        <Route path="/compte-utilisateur" component={AccountBakelisteView} />
        <Route path="/home" component={viewEvenement} />
        <Route path="/programmation-web-mobile" component={ViewProgrammation} />

        <Route path="/details-contenu" component={DetailsContenuView} />

        <Route path="/inscription" component={Inscription} />
        <Route path="/articles" component={viewAccueil} />
        <Route path="/event" component={ViewEvenement} />
        <Route path="/photo" component={viewPhoto} />
        <Route path="/video" component={ViewVideo} />
        <Route path="/details-events" component={ViewEventKokutana} />
        <Route path="/reinitialiser-mdp" component={ChangePassword} />
        <Route
          path="/recuperation-par-telephone"
          component={RecuperationParTelephone}
        />
        <Route path="/candidature" component={ViewCandidature} />
        <Route path="/demande-candidature" component={ViewDemandeCandidature} />
        <Route
          path="/dossier-de-candidature"
          component={ViewDossierCandidature}
        />
        <Route path="/mes-evenements" component={MesEvenementsView} />
        <Route path="/liste-contenus" component={ContenuListeView} />

        {/* <Route
          path="/dossier-de-candidature/:id"
          component={(props) => (
            <ViewDossierCandidature id={props.match.params.id} />
          )}
        /> */}
        <Route path="/offre-de-stage" component={ViewOffreStage} />
        <Route path="/postuler" component={ViewPostuler} />
        <Route path="/annonces" component={ViewAnnonces} />
        <Route path="/sendeal" component={ViewSenDeal} />
        <Route path="/postuler-sendeal" component={ViewSendealPostule} />
        <Route path="/mon-profil" component={ViewProfil} />
        <Route path="/evenement" component={ViewFormations} />
        <Route path="/marketing" component={ViewMarketing} />
        <Route path="/design" component={ViewDesign} />
        <Route path="/gestion-de-projet" component={ViewGestionProjet} />
        <Route path="/bureautique" component={ViewBureautique} />
        <Route path="/anglais" component={ViewAnglais} />
        <Route path="/recuperation-par-mail" component={RecuperationParmail} />
        <Route path="/savoir-plus" component={ViewSavoirplus} />
        <Route path="/reserver" component={ViewReservation} />
        <Route path="/chat" component={ViewMessage} />
        <Route path="/jeemacoder" component={ViewEventJeemacoder} />
        <Route path="/map" component={CardLeaflet} />
      </div>
    </Router>
    // </Provider>
  );
}

export default App;
