import React, { Component } from "react";
import "./detailsContenu.css";
import axios from "axios";
import API from "../../variablesGlobales";
import Resume from "../../img/sum1.jpg";
import Article2 from "../../img/article2.jpg";
import Tutoriel from "../../img/tut1.jpg";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";

import content from "../content";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as detailsContenuActions from "../../store/detailsContenu/actions";
export default class detailsContenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      content: [],
    };
  }
  componentDidMount() {
    this.getDetailsContenubyId();
  }

  getDetailsContenubyId() {
    axios
      .get(this.state.api + "textual-contents/" + this.props.id)
      .then((result) => {
        console.log(result.data);
        this.setState({ content: result.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div className="component-details-contenu">
          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item h-theme ff-inter fw-600">
                  <Link to="/home" className="lien_nav">
                    {" "}
                    Accueil
                  </Link>
                </li>
                <li className="breadcrumb-item h-theme ff-inter fw-600">
                  <Link to="/liste-contenus" className="lien_nav">
                    {" "}
                    Liste contenus
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                Détails contenu
                </li>
              </ol>
            </nav>
        <article>
          <div class="article-header pr-5">
            <div class="headline">
              {/* <p>The Sights & Sounds of</p> */}
              <h1>{this.state.content.title}</h1>
              <div class="byline">par {this.state.content.fullName}</div>
            </div>
            <div class="image"></div>
          </div>
          <div class="article-text"> 
            <p class="first">
              <div
                className="bot"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(this.state.content.content),
                }}
              ></div>
            </p>
          </div>
        </article>
        <div></div>
      </div>
    );
  }
}
// export default connect(
//     ({ detailsContenu }) => ({ ...detailsContenu }),
//     dispatch => bindActionCreators({ ...detailsContenuActions }, dispatch)
//   )( detailsContenu );
