import React, {Component} from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import TodayIcon from '@material-ui/icons/Today';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Carousel from '../carousel/carousel';
import './contentReservation.css';
import Adresse from "../adresse/adresse";
import Webinar from "../webinar/webinar";
import CardRentree from "../cardRentree/cardRentree";
import CardStage from "../cardStage/cardStage";
import Button from "../button/button";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentReservationActions from "../../store/contentReservation/actions";
export default class contentReservation extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
      <div className="component-content-reservation">
        <div className="row">
          <div className="col-lg-9">
          <div className="article my-3 py-2">
                    <a>Formations <NavigateNextIcon /></a>
                    
                    <a>Réservation</a>
                </div>

               
                <div className="container-fluid px-0 kokutana pb-3 mb-5">
                <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 textgreen py-3 pl-4">
                        Réserver une place
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 pb-5 ml-3">
                        Bakeli organise une séance de formation gratuite en création de logo

                        </div>
                    </div>
                    <div className="test">
                        <Carousel />
                    </div>
                    <div className="row" >
                        <div className="col-xl-12 col-lg-12 col-md-12 px-5 py-2">
                            <div className="bloctexte">
                                <TodayIcon />
                            </div>
                            <div className="bloctexte px-3">LUNDI, Journée entière</div>
                        </div>
                        
                    </div>
                    <div className="row" >
                        <div className="col-xl-12 col-lg-12 col-md-12 px-5 py-2">
                            <div className="bloctextt">
                                <LocationOnIcon />
                            </div>
                            <div className="bloctextt">Bakeli, HLM GRAND YOFF 241</div>
                        </div>
                        
                    </div>
                    <div className="">
                            <hr className="ligne"></hr>
                    </div> 
                    <div className="row">
                        <div className="offset-9 pb-3">
                            <button className="btn btn-chekk">
                            <CheckCircleOutlineIcon />Réserver
                            </button>
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-6 col-md-3 py-5">
                            <div className="texteblokkk ml-3">
                            <button className="btn btn-rouge">
                                ATTENTION
                            </button>
                            </div>
                            <div className="texteblokkk px-3 texte1">
                                NOMBRE DE PLACES LIMITES !
                            </div>
                         </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 py-4">
            <div className="row pr-5">
              <div className="col-sm-12 width_card">
                <Button />
              </div>
              <div className="col-sm-12 width_card">
                <Adresse />
              </div>
              <div className="col-sm-12 width_card">
                <Webinar />
              </div>
              <div className="col-sm-12 width_card">
                <cardRentree />
              </div>
              <div className="col-sm-12 width_card">
                <CardStage />
              </div>
            </div>
          </div>
            </div>
      </div>
      );
    }
  }
// export default connect(
//     ({ contentReservation }) => ({ ...contentReservation }),
//     dispatch => bindActionCreators({ ...contentReservationActions }, dispatch)
//   )( contentReservation );