import React, {Component} from 'react';

import Ellipse from '../../img/Ellipse.png';
import './contentMessage.css'
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentMessageActions from "../../store/contentMessage/actions";
export default class contentMessage extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
        <div className="component-content-message">
            
            <div class="container">
                <div class="row no-gutters">
                
                <div class="col-md-12">
                    {/* <div class="settings-tray">
                        <div class="friend-drawer no-gutters friend-drawer--grey">
                        <img class="profile-image" src="" alt="" />
                        <div class="text">
                        <h6>Robo Cop</h6>
                        <p class="text-muted">Layin' down the law since like before Christ...</p>
                        </div>
                        <span class="settings-tray--right">
                        <i class="material-icons">cached</i>
                        <i class="material-icons">message</i>
                        <i class="material-icons">menu</i>
                        </span>
                    </div>
                    </div> */}
                    <div class="chat-panel">
                    <div class="row no-gutters">
                        <div class="col-md-3">
                        <div class="chat-bubble chat-bubble--left">
                            Hello dude!
                        </div>
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-md-3 offset-md-9">
                        <div class="chat-bubble chat-bubble--right">
                            Hello dude!
                        </div>
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-md-3 offset-md-9">
                        <div class="chat-bubble chat-bubble--right">
                            Hello dude!
                        </div>
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-md-3">
                        <div class="chat-bubble chat-bubble--left">
                            Hello dude!
                        </div>
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-md-3">
                        <div class="chat-bubble chat-bubble--left">
                            Hello dude!
                        </div>
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-md-3">
                        <div class="chat-bubble chat-bubble--left">
                            Hello dude!
                        </div>
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-md-3 offset-md-9">
                        <div class="chat-bubble chat-bubble--right">
                            Hello dude!
                        </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                        <div class="chat-box-tray">
                            <i class="material-icons">sentiment_very_satisfied</i>
                            <input type="text" placeholder="Type your message here..." />
                            <i class="material-icons">mic</i>
                            <i class="material-icons">send</i>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
      );
    }
  }
// export default connect(
//     ({ contentMessage }) => ({ ...contentMessage }),
//     dispatch => bindActionCreators({ ...contentMessageActions }, dispatch)
//   )( contentMessage );