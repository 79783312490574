import React, { Component } from "react";
import ContentMessage from "../contentMessage/contentMessage";

import Header from "../header/header";
import Content from "../content/content";
import Button from "../button/button";
import Adresse from "../adresse/adresse";
import Webinar from "../webinar/webinar";
import CardRentree from "../cardRentree/cardRentree";
import HeaderDashboard from "../headerDashboard/index";
import NavVertical from "../navVertical/index";
import ContentModal from "../contentModal/contentModal";
import CardStage from "../cardStage/cardStage";
import "./viewMessage.css";
import contentMessage from "../contentMessage/contentMessage";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as viewMessageActions from "../../store/viewMessage/actions";
export default class viewMessage extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      // <div className="component-view-message">
      //     <Header />
      //     <div className="container-fluid px-0 contain-start">
      //       <div className="row px-0">
      //         <div className="col-xl-2 col-lg-4 px-0">
      //           <Sidebar />
      //         </div>
      //         <div className="col-xl-10 col-lg-8" id="contenu">
      //           <div className="container-fluid body">
      //             <div className="row">
      //               <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 main">
      //                 <ContentMessage />

      //               </div>
      //               <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 aside">
      //                 <div className="container-fluid">
      //                   <div className="row">
      //                   <div className="col-xl-12">
      //                       <ContentModal />
      //                   </div>
      //                   <div className="col-xl-12">
      //                       <Button />
      //                   </div>
      //                   <div className="col-xl-12">
      //                       <Adresse />
      //                   </div>
      //                   <div className="col-xl-12">
      //                       <Webinar />
      //                   </div>
      //                   <div className="col-xl-12">
      //                       <CardRentree />
      //                   </div>
      //                   <div className="col-xl-12">
      //                       <CardStage />
      //                   </div>
      //                   </div>
      //                 </div>
      //               </div>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      // </div>
      // );
      <div className="component-view-message">
        {" "}
        <HeaderDashboard />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVertical />
            </div>
            <div className="col-lg-10 pt-3">
              <contentMessage />
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 aside">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xl-12">
                    <ContentModal />
                  </div>
                  <div className="col-xl-12">
                    <Button />
                  </div>
                  <div className="col-xl-12">
                    <Adresse />
                  </div>
                  <div className="col-xl-12">
                    <Webinar />
                  </div>
                  <div className="col-xl-12">
                    <CardRentree />
                  </div>
                  <div className="col-xl-12">
                    <CardStage />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ viewMessage }) => ({ ...viewMessage }),
//     dispatch => bindActionCreators({ ...viewMessageActions }, dispatch)
//   )( viewMessage );
