import React, {Component} from 'react';
import './contentModal.css'
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentModalActions from "../../store/contentModal/actions";
export default class contentModal extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
        <div className="component-content-modal">
            <div className="bouton-modal">
                <button type="button" className="btn btn-modal py-3" data-toggle="modal" data-target="#exampleModal">
                Besoin d'aide ?
                </button>
            </div>

            <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Contacter le support</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                   <input type="email" placeholder="Adreesse mail" />
                   <textarea placeholder="Votre message"></textarea>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary">Envoyer</button>
                </div>
                </div>
            </div>
            </div>
        </div>
      );
    }
  }
// export default connect(
//     ({ contentModal }) => ({ ...contentModal }),
//     dispatch => bindActionCreators({ ...contentModalActions }, dispatch)
//   )( contentModal );