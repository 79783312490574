import React, {Component} from 'react';
import './modalContactUs.css';
import { Modal} from 'rsuite';

export default class modalContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }
  close() {
    this.setState({
      show: false
    });
  }
  open(size) {
    this.setState({
      size,
      show: true
    });
  }
  render() {
    return  <div className="component-modal-contact-us">
              <button className="link-aide" size="md" onClick={() => this.open('md')}>
                Besoin d’aide  ?
              </button>
              <Modal className="modal-responsive-md" size={this.state.size} show={this.state.show} onHide={this.close}>
                <Modal.Header>
                  <Modal.Title className="modal-contact-us">Contacter le support</Modal.Title>
                </Modal.Header>
                <Modal.Body className="max-height-modal">
                  <div className="form-group">
                    <input type="email" className="form-control input-contact-us" placeholder="Adresse mail" />
                  </div>
                  <div className="form-group">
                    <textarea className="form-control input-contact-us pt-3 pb-3" rows="12" placeholder="Votre message"></textarea>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="row dis-flex justify-content-end">
                    <div className="col-md-5 col-sm-6">
                      <button className="btn-theme text-up w-full" onClick={this.open}>
                        Envoyer
                      </button>
                    </div>
                  </div>
                </Modal.Footer>
              </Modal>
            </div>;
  }
}
// export default connect(
//     ({ modalContactUs }) => ({ ...modalContactUs }),
//     dispatch => bindActionCreators({ ...modalContactUsActions }, dispatch)
//   )( modalContactUs );