import React, { Component } from "react";
import frenchShortFormatDate from "../frenchFormatDate";

import { Link } from "react-router-dom";
import "./contentEventRecent.css";
import Skeleton from "react-loading-skeleton";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentEventRecentActions from "../../store/contentEventRecent/actions";
export default class contentEventRecent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formatDate: frenchShortFormatDate.frenchShortFormatDate,
      loading2: true,
    };
  }

  render() {
    return (
      <div className="component-content-event-recent">
        {/* <h3 className="article my-3 py-1">Evènements récents</h3> */}

        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-9 col-lg-9 col-md-9">
              <div className="card my-1 mt-2 content">
                <div className="card-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-xl-8 col-lg-8 col-md-6 contenu_texte">
                        <h5 className="card-title">
                          <div>
                            <span className="coder">{this.props.name}</span>
                          </div>
                          <div>
                            <span className="descrip">Description</span>
                          </div>
                        </h5>
                        <div className="mb-3">
                          <p className="card-text">{this.props.description} </p>
                        </div>
                        <div>
                          <span className="descrip">Adresse</span>
                        </div>
                        <div>
                          <span className="cvvv">HLM grand yoff, 241</span>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 dd">
                        <div className="mt-1">
                          <i className="fal fa-clock"></i>
                        </div>
                        <div className="">
                          <div>
                            <span className="textt">
                              Il y a{" "}
                              {this.props.year !== 0
                                ? this.props.year + " an "
                                : ""}
                              {this.props.month !== 0
                                ? this.props.month + " mois "
                                : ""}
                              {this.props.day !== 0
                                ? this.props.day + " jours "
                                : ""}
                              {this.props.hour !== 0
                                ? this.props.hour + "heures"
                                : ""}
                            </span>
                          </div>
                          <div>
                            <span className="textt hh">
                              {" "}
                              {this.state.formatDate(this.props.date)}
                            </span>
                          </div>
                          <div>
                            <span className="textt">Journée entière</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3">
              <div className="buttonphoto">
                <Link className="btn-voir my-2 py-2 p-3" to="/photo">
                  Voir les photos{" "}
                </Link>
              </div>

              <div className="buttonphoto">
                <Link className="btn-voir my-2 py-2  p-3" to="/video">
                  Voir les vidéos{" "}
                </Link>
                <h1>
                  {this.state.loading2 && (
                    <Skeleton
                      animation="wave"
                      height={60}
                      width="100%"
                      variant="rect"
                    />
                  )}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <hr className="pt-5"></hr>
      </div>
    );
  }
}
// export default connect(
//     ({ contentEventRecent }) => ({ ...contentEventRecent }),
//     dispatch => bindActionCreators({ ...contentEventRecentActions }, dispatch)
//   )( contentEventRecent );
