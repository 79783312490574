import React, { Component } from "react";
import * as Icon from "react-feather";
import rectFolder from "../../img/rectFolder.png";
import Ellipse1 from "../../img/Ellipse1.png";
import "./candidature.css";
import { Link } from "react-router-dom";
import ContentModal from "../contentModal/contentModal";
import Webinar from "../webinar/webinar";
import CardRentree from "../cardRentree/cardRentree";
import CardStage from "../cardStage/cardStage";
import Content from "../content/content";
import ModalContactUs from "../modalContactUs/index";
import API from "../../variablesGlobales";
import axios from "axios";

import Button from "../button/button";
import Adresse from "../adresse/adresse";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentActions from "../../store/content/actions";
export default class Candidature extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = localStorage.getItem("user");
    this.state = {
      user: JSON.parse(retrievedObject),
      candidature: [],
      api: API.API,

    };
    console.log(this.state.user)
  } 

  componentDidMount() {
    this.getCandidature();
  }

  getCandidature = () => {
    const user_id = this.state.user.id;
    axios
      .get(this.state.api + "candidatures/user/" + user_id)
      .then((response) => {
        this.setState({
          candidature: response.data.data,
        });
        console.log("cand", this.state.candidature);
      })
      .catch((error) => {
        console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
      });
  };
  render() {
    return (
      
      <div className="p-b-200">
        <div className="row">
          <div className="col-lg-9">
            <div className="row m-b-15">
              <div className="col m-b-15">
                {/* <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item h-theme ff-inter fw-600">
                      <Link to="/home" className="lien_nav">
                        {" "}
                        Accueil /
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                      className="lien_nav_current"
                    >
                      Candidatures
                    </li>
                  </ol>
                </nav> */}
                <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item h-theme ff-inter fw-600">
                  <Link to="/home" className="lien_nav">
                    {" "}
                    Accueil
                  </Link>
                </li>
             
                <li className="breadcrumb-item active" aria-current="page">
                Candidatures
                </li>
              </ol>
            </nav>
                {/* <h5 className="h-theme ff-inter fw-600">Candidature</h5> */}
                {/* <h3 className="article my-3 py-1">Evènements récents</h3> */}
              </div>
              <div className="col dis-flex m-b-15">
                <ModalContactUs />
              </div>
            </div>
            {this.state.candidature.length > 0 ? (
              this.state.candidature.map((item, index) => (
                <div className="card my-1 mt-2 candidature">
                  <div className="card-body">
                    <Link
                      key={index}
                      className="position-dossier"
                      to={{
                        pathname: "/dossier-de-candidature",
                        state: { id: item.id },
                      }}
                    >
                      <img
                        alt=""
                        src={rectFolder}
                        className="proprite-folder"
                      />
                      <div id="folder-txt">
                        <span>Dossier de Candidature</span>
                      </div>
                    </Link>

                    <div className="dropdown pos-img1">
                      <img
                        alt=""
                        src={Ellipse1}
                        className="btn dropdown-toggle btn-costum d-flex align-items-center pt-1 mr-4"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                      />
                      <img
                        alt=""
                        src={Ellipse1}
                        className="btn dropdown-toggle btn-costum d-flex align-items-center pt-1 mr-4"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                      />
                      <img
                        alt=""
                        src={Ellipse1}
                        className="btn dropdown-toggle btn-costum d-flex align-items-center pt-1 mr-4"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                      />
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <Link to="/demande-candidature">
                          <a className="dropdown-item" href="#">
                            Modifier
                          </a>
                        </Link>
                        <Link to="#">
                          <a className="dropdown-item" href="#">
                            Supprimer
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>Vous n'avez pas encore de dossier de candidature</p>
            )} 
            {/* <div id="comp-activite-grid">
              <h3 className="article my-3 py-1">Activités</h3>
              <div class="row articles-bottom">
                <div className="col-3 bg-white date-horaires">
                  01/10/2020 <br /> 15h36
                </div>
                <div className="col-9 bg-white">
                  <p>
                    vous avez reservé une place pour le prochaine formation
                    gratuite qui se tiendra dans les locaux de Bakeli
                  </p>
                </div>
              </div>

              <div className="row articles-bottom">
                <div className="col-3 bg-white date-horaires">
                  01/10/2020 <br /> 15h36
                </div>
                <div className="col-9 bg-white">
                  <p>
                    vous avez deposé votre candidature pour offre de stage
                    disponible
                  </p>
                </div>
              </div>

              <div className="row articles-bottom">
                <div className="col-3 bg-white date-horaires">
                  01/10/2020 <br /> 15h36
                </div>
                <div className="col-9 bg-white">
                  <p>vous avez participé au webinar du 12/10/2020 sur Zoom</p>
                </div>
              </div>

              <div className="row articles-bottom">
                <div className="col-3 bg-white date-horaires">
                  01/10/2020 <br /> 15h36
                </div>
                <div className="col-9 bg-white">
                  <p>
                    vous avez reservé une place pour le prochaine formation
                    gratuite qui se tiendra dans les locaux de Bakeli
                  </p>
                </div>
              </div>
            </div> */}
          </div>
          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
                <Button />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                <Adresse />
              </div>
              {/* <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-2 dis-flex">
                <Webinar />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
                <CardRentree />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
                <CardStage />
              </div> */}
            </div>
          </div>
        </div>
      </div>
   
   );
  }
}
// export default connect(
//     ({ content }) => ({ ...content }),
//     dispatch => bindActionCreators({ ...contentActions }, dispatch)
//   )( content );
