import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";


// import fr from "moment/locale/fr"; // without this line it didn't work
// Moment.locale("fr");
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contenuListeActions from "../../store/contenuListe/actions";
export default class tutorielSkeleton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="p-b-200">
        <div className="col-xl-9 col-lg-9 event-page">
          <div className="row m-b-15">
            {/* <div class="info-content">
              <h1>Contenus</h1>
              <span>
                Crees avec <i class="fa fa-heart animated infinite pulse"></i>{" "}
                par nos apprenants{" "}
              </span>
            </div> */}
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item h-theme ff-inter fw-600">
                  <Link to="/home" className="lien_nav">
                    {" "}
                    Accueil
                  </Link>
                </li>
             
                <li className="breadcrumb-item active" aria-current="page">
                  Programmation
                </li>
              </ol>
            </nav>
            <div className="row content-block">
              <div className="col-lg-6 col-md-6 col-sm-6 mb-4 px-lg-3 px-md-2">
                <div className="columne">
                  <div className="post-moduleA">
                    <Skeleton width={`100%`} height={`100%`} />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 mb-4 px-lg-3 px-md-2">
                <div className="columne">
                  <div className="post-moduleA">
                    <Skeleton width={`100%`} height={`100%`} />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 mb-4 px-lg-3 px-md-2"> 
                <div className="columne">
                  <div className="post-moduleA">
                    <Skeleton width={`100%`} height={`100%`} />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 mb-4 px-lg-3 px-md-2">
                <div className="columne">
                  <div className="post-moduleA">
                    <Skeleton width={`100%`} height={`100%`} />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 mb-4 px-lg-3 px-md-2">
                <div className="columne">
                  <div className="post-moduleA">
                    <Skeleton width={`100%`} height={`100%`} />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 mb-4 px-lg-3 px-md-2">
                <div className="columne">
                  <div className="post-moduleA">
                    <Skeleton width={`100%`} height={`100%`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ contenuListe }) => ({ ...contenuListe }),
//     dispatch => bindActionCreators({ ...contenuListeActions }, dispatch)
//   )( contenuListe );
