import React, { Component } from 'react';
import * as Icon from 'react-feather';
import './postuleCandidature.css'

export default class PostuleCandidature extends Component {
    // constructor(props) {
    //     super(props);

    // }

    render() {
        return (
            <div className="component-postuler-candidature-stage">
                <h3 className="article my-3 py-1">Postuler</h3>
                <div className="card my-1 mt-2 postuler-demande-cand-stage">
                  
                      <div className="col volkeno-bord-card-top">
                            <h2 id="txt-volkeno-center"><b>Volkeno recherche 100 stagiaires</b></h2>
                        <div className="row">
                            <p>Volkeno est une start-up studio dont le cœur
                                 de métier est la formation des jeunes dans 
                                 les nouvelles technologies. Elle cherche aujourd'hui
                                  des stagiaires dans différents domaines pour contribuer
                                   à la croissance rapide de l'entreprise et celles de ses 
                                   partenaires. 
                            </p>
                        </div>
                        <div className="row">
                            <p>   
                                E-mail: <a href="mailto:contact@volkeno-tank.com">contact@volkeno-tank.com</a><br />
                                Le nom et la photo associés à votre compte Google seront enregistrés 
                                lorsque vous téléverserez des fichiers et enverrez ce formulaire. 
                                Vous n'êtes pas<strong> aita31@gmail.com?</strong>  <a href="#">Changer de compte</a> <br />
                                <span className="txt-orange">* Obligatoire</span> 
                            </p>
                        </div>
                      </div>

                      <div className="col volkeno-bord-card-top-2">
                            <span>Nom & Prénom *</span>
                            <input type="text" className="form-control txt-input-2" placeholder="votre réponse" />
                      </div>
                  
                      <div className="col volkeno-bord-card-top-2">
                            <span>Email *</span>
                            <input type="text" className="form-control txt-input-2" placeholder="votre réponse" />
                      </div>
                      <div className="col volkeno-bord-card-top-2">
                            <span>Numéro de Téléphone *</span>
                            <input type="text" className="form-control txt-input-2" placeholder="votre réponse" />
                      </div>

                      <div className="col volkeno-bord-card-top-2">
                            <span>Vous souhaitez faire votre stage dans quelle ville ?</span>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label class="form-check-label" for="defaultCheck1">
                                    Dakar
                                </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                                    <label class="form-check-label" for="defaultCheck2">
                                        Thiès
                                </label>
                                </div>

                                <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label class="form-check-label" for="defaultCheck1">
                                   Zinguichor
                                </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                                    <label class="form-check-label" for="defaultCheck2">
                                        Tambacounda
                                </label>
                                </div>

                                <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label class="form-check-label" for="defaultCheck1">
                                    Mbour
                                </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                                    <label class="form-check-label" for="defaultCheck2">
                                        Autre
                                </label>
                                </div>
                      </div>
                      

                      <div className="col volkeno-bord-card-top-2">
                            <span>Vous habitez dans quelle ville ?</span>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label class="form-check-label" for="defaultCheck1">
                                    Dakar
                                </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                                    <label class="form-check-label" for="defaultCheck2">
                                        Thiès
                                </label>
                                </div>

                                <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label class="form-check-label" for="defaultCheck1">
                                   Zinguichor
                                </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                                    <label class="form-check-label" for="defaultCheck2">
                                        Tambacounda
                                </label>
                                </div>

                                <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label class="form-check-label" for="defaultCheck1">
                                    Mbour
                                </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                                    <label class="form-check-label" for="defaultCheck2">
                                        Autre
                                </label>
                                </div>
                      </div>

                      <div className="col volkeno-bord-card-top-2">
                            <span>Quelle formation avez-vous suivie apres le BAC? *</span>
                            <input type="text" className="form-control txt-input-2" placeholder="votre réponse" />
                      </div>

                      <div className="col volkeno-bord-card-top-2">
                            <span>Diplomes obtenus</span>
                            <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                                    <label class="form-check-label" for="defaultCheck2">
                                        BAC
                                </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                                    <label class="form-check-label" for="defaultCheck2">
                                        Licence  (1, 2 et 3)
                                </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                                    <label class="form-check-label" for="defaultCheck2">
                                        Master (2 et 3)
                                </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                                    <label class="form-check-label" for="defaultCheck2">
                                        Autre
                                </label>
                                </div>

                      </div>

                      <div className="col volkeno-bord-card-top-2">
                            <span>Votre Niveau d'etudes actuel *</span>
                            <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                                    <label class="form-check-label" for="defaultCheck2">
                                        Bac+1
                                </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                                    <label class="form-check-label" for="defaultCheck2">
                                    Bac+2
                                </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                                    <label class="form-check-label" for="defaultCheck2">
                                    Bac+3
                                </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                                    <label class="form-check-label" for="defaultCheck2">
                                    Bac+4
                                </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                                    <label class="form-check-label" for="defaultCheck2">
                                    Bac+5
                                </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                                    <label class="form-check-label" for="defaultCheck2">
                                    Je n'ai pas le BAC
                                </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                                    <label class="form-check-label" for="defaultCheck2">
                                    Autre
                                </label>
                                </div>
                      </div>

                      <div className="col volkeno-bord-card-top-2">
                            <span>Avez-vous un smartphone*</span>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            oui
                                        </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                            <label class="form-check-label" for="flexRadioDefault2">
                                            non
                                    </label>
                                </div>
                      </div>

                      <div className="col volkeno-bord-card-top-2">
                            <span>Avez-vous un ordinateur*</span>
                            <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            oui
                                        </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                            <label class="form-check-label" for="flexRadioDefault2">
                                            non
                                    </label>
                                </div>
                      </div>

                      <div className="col volkeno-bord-card-top-2">
                            <span>Avez-vous déja fait un stage*</span>
                            <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            oui
                                        </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                            <label class="form-check-label" for="flexRadioDefault2">
                                            non
                                    </label>
                                </div>
                      </div>

                      <div className="col volkeno-bord-card-top-2">
                            <span>Vous avez combien de mois d'experience *</span>
                            <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            0
                                        </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                            <label class="form-check-label" for="flexRadioDefault2">
                                            3mois
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            6mois
                                        </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                            <label class="form-check-label" for="flexRadioDefault2">
                                            Entre 6mois et 1an
                                    </label>
                                </div>
                                <div class="form-check">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                            <label class="form-check-label" for="flexRadioDefault2">
                                           Plus de 1an
                                    </label>
                                </div>
                      </div>

                      <div className="col volkeno-bord-card-top-2">
                            <span>Motivations : Pourquoi devrons-nous vous prendre en stage ? *</span>
                          <div className="col">
                                <p>
                                    Décrivez un peu  qui vous etes, ce que vous aimez faire, vos reves
                                    et ambitions d'ici quelques années, vos atouts et vos forces. 
                                    qui est-ce qui vous différencie des autres étudiants ?
                                </p>
                          </div>
                          <input type="text" className="form-control txt-input-2" placeholder="votre réponse" />
                      </div>

                      <div className="col volkeno-bord-card-top-2">
                            <span>Telechargez votre cv</span>
                          <div className="col">
                              <form>
                                <label for="file">Ajouter un fichier</label>
                                    <input type="file" accept="image/png, image/jpg, .pdf" name="file" id="file"/>
                                    <i className="fas fa-upload"></i>
                              </form>
                          
                          </div>
                      </div>
                      <div className="row">
                    <div className="col">
                        <button type="button" class="btn btn-primary btn-lg" id="demande-postuler-buton">Postuler</button>
                
                    </div>
               </div>
              </div>
            </div>
        );
    }
}
