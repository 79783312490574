import React, { Component } from "react";
import "./navVertical.css";
import { NavLink } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

export default class navVertical extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="container-nav-vertical no-view-tablette">
        <div
          className="nav flex-column nav-pills ml-3"
          id="v-pills-tabCustom"
          role="tablist"
          aria-orientation="vertical"
        >
          <NavLink className="nav-link nav-link-side-navbar-custom" to="/home">
            <FeatherIcon className="icon-side-navbar-custom" icon="layout" />
            Accueil
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/articles"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="layout" />
            Articles
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/evenement"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="grid" />
            Evenements 
          </NavLink>

          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/candidature"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="folder" />
            Candidatures
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/liste-contenus"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="save" />
            Contenus
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/mes-evenements"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="save" />
            Mes évenements
          </NavLink>
          {/* <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/annonces"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="users" />
            Annonces
          </NavLink> */}
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ navVertical }) => ({ ...navVertical }),
//     dispatch => bindActionCreators({ ...navVerticalActions }, dispatch)
//   )( navVertical );
