import React, { Component } from "react";
import "./contenuListe.css";
import $ from "jquery";
import axios from "axios";
import API from "../../variablesGlobales";
// import Resume from "../../img/sum1.jpg";
// import Article2 from "../../img/article2.jpg";
// import Tutoriel from "../../img/tut1.jpg";
import DOMPurify from "dompurify";
import InfiniteScroll from "react-infinite-scroll-component";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import ContenuListeSkeleton from "./contenuListeSkeleton";
import Resume from "../../img/img/resume.jpeg";
import Article from "../../img/img/article.jpeg";
import Tutoriel from "../../img/img/tutoriel.jpeg";
import Rapport from "../../img/img/rapport.jpeg";

import FeatherIcon from "feather-icons-react";

import { Tooltip } from "@material-ui/core";
import Truncate from "react-truncate-html";
// import fr from "moment/locale/fr"; // without this line it didn't work
// Moment.locale("fr");
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contenuListeActions from "../../store/contenuListe/actions";
// import { toast } from "react-toastify";
// toast.configure();
export default class contenuListe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      contents: [],
      hasMore: true,
      currentPage: 1,
      loading: true,
      isLoaded: true,
      article: [],
      rapportDeStage: [],
      tutoriel: [],
      resume: [], 
      tab_All: true,
      tab_Rapports: false,
      tab_tutoriels: false,
      tab_resumes: false,
      tab_articles: false,
      navs: [],
      rapport: "",
      articlesss:"",
    };
  }

  componentDidMount() {
    $(".post-module").hover(function () {
      $(this).find(".description").stop().animate(
        {
          height: "toggle",
          opacity: "toggle",
        },
        300
      );
    });
    this.getTextualContents();
    this.getArticles();
    this.getListResumes();
    this.getTutoriels();
    this.getRapportDeStage();
  }

  getTextualContents = () => {
    axios
      .get(
        this.state.api +
          "validated-textual-contents/?page=" +
          this.state.currentPage
      )
      .then((result) => {
        // console.log(result.data.data.data);
        this.setState({
          loading: false,
          contents: result.data.data.data,
          isLoaded: false,
        });
        $("#start-hidden").removeClass("start-hidden");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // archiveContent = (id) => {
  //   // console.log(id,"delete id", id)
  //   axios
  //     .post(this.state.api + "archive-content/" + id)
  //     .then((res) => {
  //       if (res.data.success) {

  //         toast.success(
  //           "Contenu archivé avec succes",
  //           { position: toast.POSITION.BOTTOM_LEFT },
  //           { autoClose: 1500 }
  //         );
  //       } else {
  //         toast.error(
  //           "Erreur l'archivage de ce contenu",
  //           { position: toast.POSITION.TOP_CENTER },
  //           { autoClose: 1500 }
  //         );
  //       }
  //     })
  //     .catch((error) => {
  //       // console.log(error);
  //     });
  // };

  setNavTabsCA(event) {
    let navs = this.state.navs;
    navs[event.target.name] = event.target.value;
    if (navs["drone"] === "all") {
      this.setState({
        tab_All: true,
        tab_Rapports: false,
        tab_tutoriels: false,
        tab_resumes: false,
        tab_articles: false,
      });
    }
    if (navs["drone"] === "rapports") {
      this.setState({
        tab_All: false,
        tab_Rapports: true,
        tab_tutoriels: false,
        tab_resumes: false,
        tab_articles: false,
      });
    }
    if (navs["drone"] === "tutoriels") {
      this.setState({
        tab_All: false,
        tab_Rapports: false,
        tab_tutoriels: true,
        tab_resumes: false,
        tab_articles: false,
      });
    }
    if (navs["drone"] === "resumes") {
      this.setState({
        tab_All: false,
        tab_Rapports: false,
        tab_tutoriels: false,
        tab_resumes: true,
        tab_articles: false,
      });
    }
    if (navs["drone"] === "articles") {
      this.setState({
        tab_All: false,
        tab_Rapports: false,
        tab_tutoriels: false,
        tab_resumes: false,
        tab_articles: true,
      });
    }
  }
  getListResumes = () => {
    axios
      .get(this.state.api + "liste-validated-resumes")
      .then((res) => {
        const contenu = res.data;
        this.setState({
          resume: contenu.data,
        });
        //  console.log(res, this.state.resume)
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        // Sentry.captureException(error);
      });
  };

  getTutoriels = () => {
    axios
      .get(this.state.api + "liste-validated-tutoriels")
      .then((res) => {
        const contenu = res.data;
        this.setState({
          tutoriel: contenu.data,
        });
        //  console.log(res, this.state.tutoriel)
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        // Sentry.captureException(error);
      });
  };

  getRapportDeStage = () => {
    axios
      .get(this.state.api + "liste-validated-rapport-de-stage")
      .then((res) => {
        const contenu = res.data;
        this.setState({
          rapportDeStage: contenu.data,
          rapport: contenu.message,
        });
        console.log("rappstage", res, this.state.rapport);
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        // Sentry.captureException(error);
      });
  };

  getArticles = () => {
    axios
      .get(this.state.api + "liste-validated-articles")
      .then((res) => {
        const contenu = res.data;
        this.setState({
          article: contenu.data,
          articlesss: contenu.message,
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        // Sentry.captureException(error);
      });
  };
  getResume = () => {
    // var fd = new FormData();
    // fd.append("bakeliste_id", this.state.userID);
    axios
      .get(
        this.state.api +
          "validated-textual-contents" +
          "/?page=" +
          this.state.currentPage
      )
      .then((res) => {
        // console.log(res,"response")
        const contenu = res.data.data;
        this.setState({
          contenus: contenu.data,
        });
        // console.log(this.state.contenus);
      })
      .catch((error) => {
        // console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        // Sentry.captureException(error);
      });
  };
  trunc = (content, n) => {
    return content.substr(0, n - 1) + (content > n ? "&hellip;" : "");
  };

  fetchMoreData = () => {
    setTimeout(() => {
      this.setState({
        currentPage: this.state.currentPage + 1,
        loading: true,
      });
      axios
        .get(
          this.state.api + "textual-contents/?page=" + this.state.currentPage
        )
        .then((response) => {
          // console.log("jdcjcndzl", response);

          if (response.data.success === true) {
            Object.values(response.data.data.data).forEach((element) => {
              this.state.contents.push(element);
            });

            if (
              response.data.data.current_page === response.data.data.last_page
            ) {
              this.setState({ hasMore: false, loading: false });
            } else {
              this.setState({ hasMore: true, loading: true });
            }
          }
        });
    }, 500);

    return this.state.contents;
  };
  render() {
    return (
      <div className="p-b-200">
        {this.state.isLoaded && <ContenuListeSkeleton />}

        <div className="col-xl-12 col-lg-12 event-page">
        <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item h-theme ff-inter fw-600">
                  <Link to="/home" className="lien_nav">
                    {" "}
                    Accueil
                  </Link>
                </li>
             
                <li className="breadcrumb-item active" aria-current="page">
                Contenus
                </li>
              </ol>
            </nav>
          <div className="row m-b-15">
          
            <div class="info-content">
              <h1>Contenus</h1>
              <span>
                Crees avec <i class="fa fa-heart animated infinite pulse"></i>{" "}
                par nos apprenants{" "}
              </span>
            </div>

            <InfiniteScroll
              className="hello"
              dataLength={this.state.contents.length}
              next={this.fetchMoreData}
              hasMore={this.state.hasMore}
              loader={
                this.state.loading &&
                "Les donnees sont entrain de se charger...."
              }
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Vous avez tous visualise!!!</b>
                </p>
              }
            >
              <div>
                <div className=" tab-data-col">
                  <form>
                    <div onChange={(event) => this.setNavTabsCA(event)}>
                      <div className="row tb">
                        <div className="col-md-4">
                          <div className="radiobtn1">
                            <input
                              defaultChecked
                              type="radio"
                              value="all"
                              name="drone"
                              id="all"
                              data-target="#all"
                            />
                            <label for="all">All</label>
                          </div>
                          <div className="radiobtn1">
                            <input
                              type="radio"
                              value="articles"
                              name="drone"
                              id="article"
                              data-target="#articles"
                            />
                            <label for="article">Articles</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="radiobtn1">
                            <input
                              type="radio"
                              value="resumes"
                              name="drone"
                              id="resume"
                              data-target="#resumes"
                            />
                            <label for="resume">Résumé de cours</label>
                          </div>
                          <div className="radiobtn1">
                            <input
                              type="radio"
                              value="rapports"
                              name="drone"
                              id="rapport"
                              data-target="#rapports"
                            />
                            <label for="rapport">Rapports de stage</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="radiobtn1">
                            <input
                              type="radio"
                              value="tutoriels"
                              name="drone"
                              id="tutoriel"
                              data-target="#tutoriel"
                            />
                            <label for="tutoriel">Tutoriels</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="tab-content">
                {this.state.tab_Rapports ? (
                  <div className="tab-pane active" id="rapports">
                    {/* <div className="row content-block"> */}
                    {/* {this.state.rapportDeStage.length > 0 ? ( */}
                    {this.state.rapport === "empty data" ? (
                      <p style={{ textAlign: "center" }}>
                        <b>Aucun rapport disponible.</b>
                      </p>
                    
                    ) : (
                      <div className="row content-block">
                        {this.state.rapportDeStage.map((item, index) => (
                          <div key={index} className="col-md-4 dis-flex mb-5">
                            <div class="column">
                              <div class="post-module">
                                <div class="thumbnail">
                                  <div class="date">
                                    <div class="day">
                                      {" "}
                                      <Moment format="DD" local>
                                        {item.created_at}
                                      </Moment>
                                    </div>
                                    <div class="month">
                                      {" "}
                                      <Moment format="MMMM" local>
                                        {item.created_at}
                                      </Moment>
                                    </div>
                                  </div>
                                  <img src={Rapport} />
                                </div>
                                <div class="post-content p-2">
                                  <div class="category">
                                    {item.content_type}
                                  </div>
                                  <Link
                                    to={{
                                      pathname:
                                        "/details-contenu",
                                      state: { id: item.id },
                                    }}
                                  >
                                    <h1 class="title">{item.title}</h1>
                                  </Link>
                                  <p class="description">
                                    <div>
                                      <Truncate
                                        lines={3}
                                        dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(
                                            item.content
                                          ),
                                        }}
                                      />
                                    </div>
                                    ....
                                  </p>
                                  <div class="post-meta">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <span class="timestamp">
                                          <div className="auteur">
                                            {item.fullName}
                                          </div>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {/* </div> */}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="tab-content">
                {this.state.tab_tutoriels ? (
                  <div className="tab-pane active" id="tutoriel">
                    <div className="row content-block">
                      {this.state.tutoriel.length > 0 ? (
                        this.state.tutoriel.map((item, index) => (
                          <div key={index} className="col-md-4 dis-flex mb-5">
                            <div class="column">
                              <div class="post-module">
                                <div class="thumbnail">
                                  <div class="date">
                                    <div class="day">
                                      {" "}
                                      <Moment format="DD" local>
                                        {item.created_at}
                                      </Moment>
                                    </div>
                                    <div class="month">
                                      {" "}
                                      <Moment format="MMMM" local>
                                        {item.created_at}
                                      </Moment>
                                    </div>
                                  </div>
                                  <img src={Tutoriel} />
                                </div>
                                <div class="post-content p-2">
                                  <div class="category">
                                    {item.content_type}
                                  </div>
                                  <Link
                                    to={{
                                      pathname:
                                        "/details-contenu",
                                      state: { id: item.id },
                                    }}
                                  >
                                    <h1 class="title">{item.title}</h1>
                                  </Link>
                                  <p class="description">
                                    <div>
                                      <Truncate
                                        lines={3}
                                        dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(
                                            item.content
                                          ),
                                        }}
                                      />
                                    </div>
                                    ....
                                  </p>
                                  <div class="post-meta">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <span class="timestamp">
                                          <div className="auteur">
                                            {item.fullName}
                                          </div>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p style={{ textAlign: "center" }}>
                          <b>Aucun tutoriel disponible.</b>
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="tab-content">
                {this.state.tab_resumes ? (
                  <div className="tab-pane active" id="resumes">
                    <div className="row content-block">
                      {this.state.resume.length > 0 ? (
                        this.state.resume.map((item, index) => (
                          <div key={index} className="col-md-4 dis-flex mb-5">
                            <div class="column">
                              <div class="post-module">
                                <div class="thumbnail">
                                  <div class="date">
                                    <div class="day">
                                      {" "}
                                      <Moment format="DD" local>
                                        {item.created_at}
                                      </Moment>
                                    </div>
                                    <div class="month">
                                      {" "}
                                      <Moment format="MMMM" local>
                                        {item.created_at}
                                      </Moment>
                                    </div>
                                  </div>
                                  <img src={Resume} />
                                </div>
                                <div class="post-content p-2">
                                  <div class="category">
                                    {item.content_type}
                                  </div>
                                  <Link
                                    to={{
                                      pathname:
                                        "/details-contenu",
                                      state: { id: item.id },
                                    }}
                                  >
                                    <h1 class="title">{item.title}</h1>
                                  </Link>
                                  <p class="description">
                                    <div>
                                      <Truncate
                                        lines={3}
                                        dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(
                                            item.content
                                          ),
                                        }}
                                      />
                                    </div>
                                    ....
                                  </p>
                                  <div class="post-meta">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <span class="timestamp">
                                          <div className="auteur">
                                            {item.fullName}
                                          </div>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p style={{ textAlign: "center" }}>
                          <b>Aucun resume disponible.</b>
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="tab-content">
                {this.state.tab_articles ? (
                  <div className="tab-pane active" id="articles">
                    {/* <div className="row content-block"> */}
                    {this.state.articlesss === "empty data" ? (
 <p style={{ textAlign: "center" }}>
 <b>Aucun article disponible.</b>
</p>
                    
                      ) : (
                       
                        <div className="row content-block">

{this.state.article.map((item, index) => (
  <div key={index} className="col-md-4 dis-flex mb-5">
    <div class="column">
      <div class="post-module">
        <div class="thumbnail">
          <div class="date">
            <div class="day">
              {" "}
              <Moment format="DD" local>
                {item.created_at}
              </Moment>
            </div>
            <div class="month">
              {" "}
              <Moment format="MMMM" local>
                {item.created_at}
              </Moment>
            </div>
          </div>
          <img src={Article} />
        </div>
        <div class="post-content p-2">
          <div class="category">
            {item.content_type}
          </div>
          <Link
            to={{
              pathname:
                "/details-contenu",
              state: { id: item.id },
            }}
          >
            <h1 class="title">{item.title}</h1>
          </Link>
          <p class="description">
            <div>
              <Truncate
                lines={3}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    item.content
                  ),
                }}
              />
            </div>
            ....
          </p>
          <div class="post-meta">
            <div className="row">
              <div className="col-md-6">
                <span class="timestamp">
                  <div className="auteur">
                    {item.fullName}
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
))}</div>
                      )}
                    {/* </div> */}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="tab-content">
                {this.state.tab_All ? (
                  <div className="tab-pane active" id="all">
                    <div className="row content-block">
                      {this.state.contents.length > 0 ? (
                        this.state.contents.map((item, index) => (
                          <div key={index} className="col-md-4 dis-flex mb-5">
                            <div class="column">
                              <div class="post-module">
                                <div class="thumbnail">
                                  <div class="date">
                                    <div class="day">
                                      {" "}
                                      <Moment format="DD" local>
                                        {item.created_at}
                                      </Moment>
                                    </div>
                                    <div class="month">
                                      {" "}
                                      <Moment format="MMMM" local>
                                        {item.created_at}
                                      </Moment>
                                    </div>
                                  </div>
                                  {item.content_type === "article" && (
                                    <img src={Article} />
                                  )}
                                  {item.content_type === "résumé" && (
                                    <img src={Resume} />
                                  )}
                                  {item.content_type === "tutoriel" && (
                                    <img src={Tutoriel} />
                                  )}
                                  {item.content_type === "rapport de stage" && (
                                    <img src={Rapport} />
                                  )}
                                  {item.content_type ===
                                    "cahier de charges" && (
                                    <img src={Tutoriel} />
                                  )}
                                  {item.content_type === "backlog" && (
                                    <img src={Tutoriel} />
                                  )}
                                </div>
                                <div class="post-content p-2">
                                  <div class="category">
                                    {item.content_type}
                                  </div>
                                  <Link
                                    to={{
                                      pathname:
                                        "/details-contenu",
                                      state: { id: item.id },
                                    }}
                                  >
                                    <h1 class="title">{item.title}</h1>
                                  </Link>
                                  <p class="description">
                                    <div>
                                      <Truncate
                                        lines={3}
                                        dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(
                                            item.content
                                          ),
                                        }}
                                      />
                                    </div>
                                    ....
                                  </p>
                                  <div class="post-meta">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <span class="timestamp">
                                          <div className="auteur">
                                            {item.fullName}
                                          </div>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p style={{ textAlign: "center" }}>
                          <b>Aucun contenu disponible pour le moment.</b>
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </InfiniteScroll>

            {/* <InfiniteScroll
              className="hello"
              dataLength={this.state.contents.length}
              next={this.fetchMoreData}
              hasMore={this.state.hasMore}
              loader={
                this.state.loading &&
                "Les donnees sont entrain de se charger...."
              }
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Vous avez tous visualise!!!</b>
                </p>
              }
            >
              
              <div className="row content-block">
                {this.state.contents.map((content) => (
                  <div className="col-md-4 dis-flex mb-5">
                    <div class="column">
                      <div class="post-module">
                        <div class="thumbnail">
                          <div class="date">
                            <div class="day">
                              {" "}
                              <Moment format="DD" local>
                                {content.created_at}
                              </Moment>
                            </div>
                            <div class="month">
                              {" "}
                              <Moment format="MMMM" local>
                                {content.created_at}
                              </Moment>
                            </div>
                          </div>
                          {content.content_type === "article" && (
                            <img src={Article} />
                          )}
                          {content.content_type === "résumé" && (
                            <img src={Resume} />
                          )}
                          {content.content_type === "tutoriel" && (
                            <img src={Tutoriel} />
                          )}
                        </div>
                        <div class="post-content p-2">
                          <div class="category">{content.content_type}</div>
                          <Link
                            to={{
                              pathname: "/details-contenu",
                              state: { id: content.id },
                            }}
                          >
                            <h1 class="title">{content.title}</h1>
                          </Link>
                          <p class="description">
                            <div
                              className="bot"
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  this.trunc(content.content, 100)
                                ),
                              }}
                            ></div>
                            ....
                          </p>
                          <div class="post-meta">
                            <span class="timestamp">
                              <i class="fa fa-clock-">o</i>
                            
                              <Moment format="DD-MM-YYYY">
                                {content.created_at}
                              </Moment>
                            </span>
                           
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            
            </InfiniteScroll>  */}
          </div>

          <div className=""></div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ contenuListe }) => ({ ...contenuListe }),
//     dispatch => bindActionCreators({ ...contenuListeActions }, dispatch)
//   )( contenuListe );
