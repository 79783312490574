import React, { Component } from "react";
import * as Icon from "react-feather";
import "./marketing.css";
import marketing from "../../img/marketing.png";
import { Link } from "react-router-dom";
import Button from "../button/button";
import Adresse from "../adresse/adresse";
import Webinar from "../webinar/webinar";
import CardRentree from "../cardRentree/cardRentree";
import CardStage from "../cardStage/cardStage";

export default class Marketing extends Component {
  // constructor(props) {
  //     super(props);

  // }

  goToMarkting = () => {
    window.location = "/demande-candidature";
  }
  render() {
    return (
      <div className="p-b-200">
        <div className="row dev-page">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
              <li className="breadcrumb-item h-theme ff-inter fw-600">
                      <Link to="/home" className="lien_nav">
                        {" "}
                        Accueil 
                      </Link>
                    </li>
                {/* <li className="breadcrumb-item h-theme ff-inter fw-600">
                  <Link to="/formations" className="lien_nav">
                    {" "}
                 Formations 
               </Link>
                </li> */}
                <li
                  className="breadcrumb-item active"
                  aria-current="page"
                >
                  Marketing
             </li>
              </ol>
            </nav>
            <div class="card mb-3 formations-card-marketing">
              <img
                src={marketing}
                class="card-img-top t-img-marketing"
                alt="Marketing Digitale"
              />
              <div class="card-body">
                <ul id="color-li">
                  <li className="dist-li">
                    <span className="txt-prog">
                      Lancement d'une campagne marketing en ligne
          </span>
                  </li>
                  <li className="dist-li">
                    <span className="txt-prog">Référencement (SEO)</span>
                  </li>
                  <li className="dist-li">
                    <span className="txt-prog">Marketing de contenu </span>
                  </li>
                  <li className="dist-li">
                    <span className="txt-prog">Marketing inbound</span>
                  </li>
                  <li className="dist-li">
                    <span className="txt-prog">
                      Rédactino d'article web
          </span>
                  </li>
                </ul>
              </div>
            </div>
            <button
              className="col-lg-12 btn-theme-light1"
              type="button"
              onClick={() => this.goToMarkting()}
            >
              Rejoindre l'aventure
                </button>
          </div>

          <div className="col-xl-3 col-lg-3 pr-5 my-5 py-4">
            <div className="row pr-5">
              <div className="col-sm-12 width_card">
                <Button />
              </div>
              <div className="col-sm-12 width_card">
                <Adresse />
              </div>
              {/* <div className="col-sm-12 width_card">
                <Webinar />
              </div>
              <div className="col-sm-12 width_card">
                <CardRentree />
              </div>
              <div className="col-sm-12 width_card">
                <CardStage /> 
              </div>*/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}


