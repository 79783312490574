import React, { Component } from "react";

import Header from "../header/header";
import Button from "../button/button";
import Adresse from "../adresse/adresse";
import Webinar from "../webinar/webinar";
import HeaderDashboard from "../headerDashboard/index";
import NavVertical from "../navVertical/index";
import CardRentree from "../cardRentree/cardRentree";
import ContentModal from "../contentModal/contentModal";

import CardStage from "../cardStage/cardStage";
import ContentEventJeemacoder from "../contentEventJeemacoder/contentEventJeemacoder";
import "./viewEventJeemacoder.css";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as viewEventJeemacoderActions from "../../store/viewEventJeemacoder/actions";
export default class viewEventJeemacoder extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="component-view-event-jeemacoder">
        {" "}
        <HeaderDashboard />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVertical />
            </div>
            <div className="col-lg-10 pt-3">
              <ContentEventJeemacoder />
            </div>
      
            <div className="col-xl-3 col-lg-3 pr-3 my-5 py-4">
            <div className="row">
              <div className="col-sm-12 width_card">
                <Button />
              </div>
              <div className="col-sm-12 width_card">
                <Adresse />
              </div>
              <div className="col-sm-12 width_card">
                <Webinar />
              </div>
              <div className="col-sm-12 width_card">
                <cardRentree />
              </div>
              <div className="col-sm-12 width_card">
                <CardStage />
              </div>
            </div>
         
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ viewEventJeemacoder }) => ({ ...viewEventJeemacoder }),
//     dispatch => bindActionCreators({ ...viewEventJeemacoderActions }, dispatch)
//   )( viewEventJeemacoder );
