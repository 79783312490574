import React, {Component} from 'react';
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import './cardRentree.css'
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as cardRentreeActions from "../../store/cardRentree/actions";
export default class cardRentree extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
      <div className="component-card-rentree py-2">
        <div class="right_sidebar_card">
          <div class="card-body py-1 px-1">
            <h6 class="card-title">
              <div className="ajuster">
                <Icon.Calendar className="loc" />
              </div>
              <div className="ajuster">
                <span className="rentre">Prochaine séance de </span><br />
                <span className="rentre">formation gratuite</span>
              </div>
             </h6>
             <p class="card-text tps">Mar 12/10/2020 - 10H00</p>
            <span className="titre pl-5 pt-3">Bakeli</span>
            <div className="py-2 d-flex align-items-center">
                <Link className=" btn btn-reserver" to="/reserver">
                    Réserver une place
                </Link>
            </div>
           
            
          </div>
        </div>
      </div>
      );
    }
  }
// export default connect(
//     ({ cardRentree }) => ({ ...cardRentree }),
//     dispatch => bindActionCreators({ ...cardRentreeActions }, dispatch)
//   )( cardRentree );