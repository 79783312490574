import React, {Component} from 'react';
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import CardLeaflet from '../cardLeaflet/index';
import './adresse.css'
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as adresseActions from "../../store/adresse/actions";
export default class adresse extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
      <div className="component-adresse py-2">
        <div class="right_sidebar_card">
          <div class="card-body card-content">
            <div className="line">
              <Icon.MapPin className="loc" /> 
            </div>
         
            <div className="line">
              <span className="texte">Bakeli HLM GRD YOFF</span>
              <div>
                <span className="espace">241</span>
              </div>
              <div className="map-texte">
                <Link className="theme" to="/map">Voir sur la carte</Link>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      );
    }
  }
// export default connect(
//     ({ adresse }) => ({ ...adresse }),
//     dispatch => bindActionCreators({ ...adresseActions }, dispatch)
//   )( adresse );