import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import './cardStage.css'
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as cardStageActions from "../../store/cardStage/actions";
export default class cardStage extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
      <div className="component-card-stage py-2">
        <div className="right_sidebar_card">
          <div className="card-body">
                <div>
                <h6 className="card-title stagetitre">Offre de stage disponible</h6>
                </div>
                <div className="py-2 d-flex align-items-center">
                    <Link className="btn-plus" to="/savoir-plus">
                        En savoir plus
                    </Link>
                </div>
          </div>
        </div>
      </div>
      );
    }
  }
// export default connect(
//     ({ cardStage }) => ({ ...cardStage }),
//     dispatch => bindActionCreators({ ...cardStageActions }, dispatch)
//   )( cardStage );