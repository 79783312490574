import React, { Component } from "react";
import "./inscription.css";
import logo from "../../img/logo.png";
import google from "../../img/google.png";
import facebook from "../../img/facebook.png";
import { Link } from "react-router-dom";
import axios from "axios";
import API from "../../variablesGlobales";
import FeatherIcon from "feather-icons-react";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as connexionActions from "../../store/connexion/actions";
export default class Inscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      isSendingRegiterInProgress: false,
      api: API.API,
      successRegister: true,
      showingLink: true,
      showing: true,
      show: true,
      email_empty: false,
      errors: {},
      type: "text",
      input: {},
    };
  }

  handleClick = () =>
    this.setState(({ type }) => ({
      type: type === "text" ? "password" : "text",
    }));

  handleChange = (e) => {
    let errors = this.state.errors;
    let input = this.state.input;
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    input[e.target.name] = e.target.value;
    this.setState({ fields });
    if (
      typeof fields["password"] !== "undefined" &&
      typeof fields["confirm_password"] !== "undefined"
    ) {
      if (fields["password"] != fields["confirm_password"]) {
        errors["password"] = "Passwords don't match.";
      }
    }
    this.setState({
      errors: errors,
      input,
    });
  };
  onValidateFormData() {
    let errors = this.state.errors;
    let email = this.state.email;

    var isValidForm = true;
    if (typeof email !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        isValidForm = false;
        this.setState({
          email_empty: true,
        });
        errors["email"] = "Le champ email est obligatoire";
      }
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  }
  onSubmitRegister = (e) => {
    e.preventDefault();
    if (this.onValidateFormData()) {
      this.setState({
        isSendingRegiterInProgress: true,
      });
      var data = this.state.fields;
      var fd = new FormData();
      fd.append("first_name", data.first_name);
      fd.append("last_name", data.last_name);
      fd.append("address", data.address);
      fd.append("email", data.email);
      fd.append("password", data.password);
      fd.append("phone", data.phone);
      //fd.append("user_fullname", data.first_name + data.last_name);
      axios
        .post(this.state.api + "user/bakeliste-subscribe", fd)
        .then((response) => {
          this.setState({
            isSendingRegiterInProgress: false,
          });
          if (response.status === 201) {
            this.setState({
              show: false,
              showing: false,
              showingLink: false,
              successRegister: true,
            });
          } else {
            this.setState({
              show: true,
              showing: true,
              showingLink: true,
              successRegister: false,
            });
          }
        })
        .catch((error) => {
          if ((error.message = "Request failed with status code 422")) {
          }
          this.setState({
            isSendingRegiterInProgress: false,
          });
        });
    }
  };
  state = { showing: true };


  validate(){
    let input = this.state.input;
    let errors = {};
    let isValid = true;


    if (!input["phone"]) {
      isValid = false;
      errors["phone"] = "Please enter your phone number.";
    }
    if (typeof input["phone"] !== "undefined") {
          
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(input["phone"])) {
        isValid = false;
        errors["phone"] = "Please enter only number.";
      }else if(input["phone"].length != 10){
        isValid = false;
        errors["phone"] = "Please enter valid phone number.";
      }
    }

    this.setState({
      errors: errors
    });

    return isValid;
  }
  render() {
    const { showing } = this.state;
    return (
      <div className="component-inscription">
        <div className="row page-register">
          <div className="col-md-6 banner-connexion-page d-flex justify-content-center itm-center py-5">
            <div className="col-xl-9 col-lg-10 col-md-11 col-sm-8 text-center">
              <h1 className="titre-banner-connexion">Bakeli</h1>
              <p className="slogan-banner-connexion">
                Training the best tech talent in Africa.
              </p>
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-6" id="section-right">
            <div className="marge-logo">
              <img className="logo-bakeli" src={logo} alt="" />
            </div>
            <div className="deplacement-bloc-2">
              <div className="pb-5">
                {this.state.show ? (
                  <h5 className="title-inscription">INSCRIVEZ-VOUS</h5>
                ) : (
                  <h5 className="title-inscription">INSCRIPTION RÉUSSIE</h5>
                )}
              </div>

              {showing ? (
                <form
                  className="form-inscription"
                  id="inscription-form"
                  onSubmit={(e) => this.onSubmitRegister(e)}
                >
                  <p className="mb-3">
                    Bienvenue dans l’aventure Bakeli. Cet espace personnel te
                    permet d’être informé sur tous nos événements afin d’être à
                    la pointe des nouvelles technologies
                  </p>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                      <input
                        type="text"
                        className="form-control control-2"
                        placeholder="Prénom"
                        aria-label="First name"
                        name="first_name"
                        onChange={(e) => this.handleChange(e)}
                        required
                        value={this.state.fields["first_name"]}
                      />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                      <input
                        onChange={(e) => this.handleChange(e)}
                        required
                        value={this.state.fields["last_name"]}
                        name="last_name"
                        type="text"
                        className="form-control control-2"
                        placeholder="Nom"
                        aria-label="First name"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                      <input
                        type="text"
                        className="form-control control-2"
                        placeholder="Adresse"
                        aria-label="address"
                        name="address"
                        onChange={(e) => this.handleChange(e)}
                        required
                        value={this.state.fields["address"]}
                      />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                      <input
                        name="phone"
                        type="text"
                        className="form-control control-2"
                        placeholder="Numero de Téléphone"
                        // aria-label="number"
                        onChange={this.handleChange}
                        required
                        value={this.state.fields["phone"] && this.state.input.phone}
                        
                      />
                      <div className="text-danger">{this.state.errors.phone}</div>
                    </div>
                  </div>
                  <div className="row">
                    {/* <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="row select-row">
                        <div className="col-xl-4 col-lg-4 col-md-4 pb-3">
                          <select
                            className="form-control control-2"
                            id="inputDay"
                          >
                            <option selected>1</option>
                            <option>2</option>
                            <option>3</option>
                          </select>
                        </div> */}
                    {/* <div className="col-xl-4 col-lg-4 col-md-4 pb-3">
                          <select
                            className="form-control control-2"
                            id="inputMonth"
                          >
                            <option>Janvier</option>
                            <option>Fevrier</option>
                            <option>Mars</option>
                          </select>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-lg-4 pb-3">
                          <select
                            className="form-control control-2"
                            id="inputYear"
                          >
                            <option value="2021">2021</option>
                            <option>2020</option>
                            <option>2019</option>
                          </select>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                      <input
                        onChange={(e) => this.handleChange(e)}
                        value={this.state.fields["date_naissance"]}
                        name="date_naissance"
                        type="text"
                        className="form-control control-2"
                        placeholder="Lieu de Naissance"
                        aria-label="First name"
                      />
                    </div> */}
                  </div>

                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                      <input
                        name="email"
                        type="email"
                        className="form-control control-2"
                        placeholder="Adresse e-mail"
                        aria-label="email"
                        onChange={(e) => this.handleChange(e)}
                        required
                        value={this.state.fields["email"]}
                      />
                      <div className="errorMsg">{this.state.errors.email}</div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 d-flex show-hide-wrapper">
                      <input
                        onChange={(e) => this.handleChange(e)}
                        required
                        value={this.state.fields["password"]}
                        name="password"
                        type="password"
                        className="form-control control-2"
                        placeholder="Creer un Mot de passe"
                        aria-label="password"
                        type={this.state.type}
                      />

                      <span
                        className="input-group-text input-group-text-connexion"
                        id="basic-addon1"
                        onClick={this.handleClick}
                      >
                        {this.state.type === "text" ? (
                           <FeatherIcon icon="eye-off" />
                        ) : (
                         
                          <FeatherIcon icon="eye" />
                        )}
                      </span>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                      <input
                        onChange={(e) => this.handleChange(e)}
                        required
                        value={this.state.fields["confirm_password"]}
                        name="confirm-password"
                        type="password"
                        className="form-control control-2"
                        placeholder="Confirmer Mot de passe"
                        aria-label="password"
                      />
                    </div>
                    <div className="errorMsg">{this.state.errors.password}</div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 offset-3 b-inscription py-3">
                      {this.state.isSendingRegiterInProgress ? (
                        <button
                          type="submit"
                          className="btn btn-inscription"
                          disabled
                        >
                          inscription en cours &nbsp;
                          <FeatherIcon icon="loader" aria-hidden="true" />
                        </button>
                      ) : (
                        <button
                          type="submit"
                          class="btn btn-connexion"
                          aria-pressed="true"
                        >
                          S'INSCRIRE
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              ) : (
                <p>
                  {" "}
                  Votre inscription a été prise en compte , nous vous enverons
                  un email de confirmation. Merci
                </p>
              )}
              {/* {this.state.showingLink ? (
                <div>
                  <div className="py-3">
                    <span className="ou">ou</span>
                  </div>

                  <div className="row d-flex">
                    <div className="col-xl-5 col-lg-5 col-md-5 pb-3">
                      <hr className="bordure-hr" />
                    </div>
                    <div className="col-xl-1 col-lg-1 col-md-1 col-sm-6 blok_image_logo pb-3">
                      <img className="logo-google" src={google} />
                    </div>
                    <div className="col-xl-1 col-lg-1 col-md-1 col-sm-6 blok_image_logo pb-3">
                      <img className="logo-facebook" src={facebook} />
                    </div>
                    <div className="col-xl-5 col-lg-5 col-md-5 pb-3">
                      <hr className="bordure-hr" />
                    </div>
                  </div> */}

              {/* <div className="py-5"> */}
              <p className="texte_text">
                Vous avez un compte ? Connectez-vous{" "}
                <Link to="/connexion">
                  <a className="texte-ici" href="#">
                    ici
                  </a>
                </Link>
              </p>
              {/* </div> */}
              {/* </div> */}
              {/* ) : (
                <p className="texte_text">
                  Connectez-vous{" "}
                  <Link to="/">
                    <a className="texte-ici" href="#">
                      ici
                    </a>
                  </Link>
                </p>
              )} */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ connexion }) => ({ ...connexion }),
//     dispatch => bindActionCreators({ ...connexionActions }, dispatch)
//   )( connexion );
