import React, { Component } from "react";
import "./changePassword.css";
import FeatherIcon from "feather-icons-react";
import { Link, NavLink } from "react-router-dom";
import API from "../../variablesGlobales";
import axios from "axios";
import Logo from "../../img/logo.png";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as changePasswordActions from "../../store/changePassword/actions";
export default class changePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      token: null,
      isPending: false,
      isValidUser: true,
      showForm: true,
      api: API.API,
      email_empty: false,
      password_empty: false,
    };
  }

  handleChange = (e) => {
    let fields = this.state.fields;

    fields[e.target.name] = e.target.value;

    this.setState({
      fields: fields,
    });
  };
  sendingEmailForResetPassword = (e) => {
    e.preventDefault();

    this.setState({
      isPending: true,
    });

    var formdata = new FormData();
    formdata.append("email", this.state.fields.email);
    let emailData = this.state.fields;
    console.log("email", emailData);
    axios
      .post(this.state.api + "send-visiteur-reset-password", emailData)
      .then((response) => {
        console.log(response);
        if (response.data.success === true) {
          this.setState({
            isPending: false,
            showForm: false,
          });
        } else {
          this.setState({
            isPending: false,
            requestError: true,
          });
          console.log(response);
        }
      });
  };
  render() {
    return (
      <div className="">
        <div className="row">
          <div className="col-md-6 banner-connexion-page dis-flex justify-content-center itm-center py-5">
            <div className="col-xl-9 col-lg-10 col-md-11 col-sm-8 text-center">
              <h1 className="titre-banner-connexion">Bakeli</h1>
              <p className="slogan-banner-connexion">
                Training the best tech talent in Africa.
              </p>
            </div>
          </div>
          <div className="col-md-6 bg-white dis-flex justify-content-center itm-center py-md-0 py-5">
            <div className="col-xl-7 col-lg-9 col-md-11 col-sm-8 dis-flex flex-col">
              <img className="logo-connexion m-l-r-auto" src={Logo} alt="" />
              {this.state.showForm ? (
                <form onSubmit={(e) => this.sendingEmailForResetPassword(e)}>
                  <p className="h-connexion-form">REINITIALISER MOT DE PASSE</p>
                  {/* {this.state.notUser && (
                  <div className="auth-error text-center">
                    Cet utilisateur n'existe pas dans la base de donnee.
                  </div>
                )} */}
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text input-group-text-connexion"
                        id="basic-addon1"
                      >
                        <FeatherIcon
                          className="icon-form-connexion"
                          icon="mail"
                        />
                      </span>
                    </div>
                    <input
                      id="email"
                      placeholder="Votre email"
                      onChange={this.handleChange}
                      type="email"
                      className="form-control input-form-connexion"
                      name="email"
                      required
                    />
                  </div>
                  {this.state.email_empty && (
                    <div className="errorMsg">{this.state.errors.email}</div>
                  )}

                  <NavLink className="mdp-forget-link" to="/">
                    Retour a la page d'accueil !
                  </NavLink>
                  <button className="btn-connexion mb-3">
                    REINITIALISER &nbsp;
                    {this.state.isPending && (
                      <FeatherIcon
                        icon="loader"
                        aria-hidden="true"
                        style={{ color: "#069A77" }}
                      />
                    )}
                  </button>
                  {/* {!this.state.isValidUser && (
                    <div className="auth-error text-center">
                      {this.state.errors.auth_error}
                    </div>
                  )} */}
                  {this.state.isRequestError && (
                    <div className="auth-error text-center">
                      La connexion a échoué. Veuillez réessayer plus tard.
                    </div>
                  )}

                  {/* Log an event when this view mounts */}
                </form>
              ) : (
                <div className="success-reset-form-container">
                  <div className="alert alert-success success-message">
                    <p>
                      Nous venons de vous envoyer un email dans lequel vous
                      trouverez le lien qui vous permettra de modifier votre mot
                      de passe.
                    </p>
                    <p>
                      Si vous ne voyez le message dans votre boîte de réception,
                      nous vous suggérons de consulter vos spams
                    </p>
                  </div>
                </div>
              )}
              <p>
                Vous n'avez pas de compte ? Inscrivez-vous{" "}
                <Link to="/inscription">
                  <span className="link-connexion">ici</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ changePassword }) => ({ ...changePassword }),
//     dispatch => bindActionCreators({ ...changePasswordActions }, dispatch)
//   )( changePassword );
