import React, { Component } from "react";
import { useState } from "react";

import Header from "../header/header";
import Content from "../content/content";
import Button from "../button/button";
import Adresse from "../adresse/adresse";
import HeaderDashboard from "../headerDashboard/index";
import NavVertical from "../navVertical/index";
import Formations from "../formations/Formations";

import ContentModal from "../contentModal/contentModal";
import Webinar from "../webinar/webinar";
import CardRentree from "../cardRentree/cardRentree";
import CardStage from "../cardStage/cardStage";
import ContentEvent from "../../components/contentEvent/index";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as viewAccueilActions from "../../store/viewAccueil/actions";

export default class viewFormations extends Component {
  // constructor(props) { 
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      <div className="component-view-accueil">
        {" "}
        <HeaderDashboard />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVertical />
            </div>
            <div className="col-lg-10 pt-3">
              {/* <Formations />{" "} */}
                <ContentEvent />{" "}
            
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ viewAccueil }) => ({ ...viewAccueil }),
//     dispatch => bindActionCreators({ ...viewAccueilActions }, dispatch)
//   )( viewAccueil );
