import React, {Component} from 'react';
import './mesEvenementsView.css';
import MesEvenenenments from  '../mesEvenements/index';
import NavVertical from "../navVertical/index";
import HeaderDashboard from "../headerDashboard/index";


// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as mesEvenementsViewActions from "../../store/mesEvenementsView/actions";
export default class mesEvenementsView extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return(
        <div className="component-view-evenement">
        {" "}
        <HeaderDashboard />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVertical />
            </div>
            <div className="col-lg-10 pt-3">
              <MesEvenenenments />{" "} 
            </div>
          </div>
        </div>
      </div>       
);    }
  }
// export default connect(
//     ({ mesEvenementsView }) => ({ ...mesEvenementsView }),
//     dispatch => bindActionCreators({ ...mesEvenementsViewActions }, dispatch)
//   )( mesEvenementsView );