import React, { Component } from "react";
import logo from "../../img/logo.png";
import "./header.css";
import { Link } from "react-router-dom";
import axios from 'axios';
import API from '../../variablesGlobales';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as headerActions from "../../store/header/actions";
export default class header extends Component {
  constructor(props) {
      super(props);
      this.state = {
        api: API.API,

        token: window.sessionStorage.getItem("token"),
  
      };
  }
  doLogout = () => {
    window.sessionStorage.removeItem("token");
    axios.get(this.state.api + "user-logout").then((response) => {
      const result = response.data;
      if (result.success === true) {
        window.location = "/";
      }
    });
  };

  render() {

  
  return (
    <div className="component-header">
      <nav className="navbar navbar-expand-lg bg-white fixed-top">
        <div className="nav_icon">
          <i className="fa fa-bars"></i>
        </div>
        <a className="navbar-brand" href="#">
          <img src={logo} alt="Logo" className="img" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="row ss">
            <div className="col-xl-6 col-lg-6">
              <span className="text-theme essaie">Bienvenue sur Bakeli SI</span>
            </div>
            <div className="col-xl-3 col-lg-3 input">
              <div className="input-group">
                <div className="input-group-prepend iconInput">
                  <input
                    type="search"
                    className="form-control form-control-costom"
                    id="inlineFormInputGroup"
                    placeholder="Rechercher "
                  />
                  <i className="fal fa-search d-flex align-items-center"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex my-2">
            <div className="d-flex px-5 mx-5 mr-5 my-lg-0">
              <a className="nav-link mx-3" href="#">
                {" "}
                <i className="fas fa-bell"></i>{" "}
              </a>
              <a className="nav-link mx-3" href="#">
                {" "}
                <i className="fas fa-envelope"></i>
              </a>
              <a className="nav-link mx-3" href="#">
                {" "}
                <i className="fas fa-history"></i>
              </a>
            </div>

            <div class="dropdown" id="dropdownn">
              <button
                className="btn dropdown-toggle btn-costum d-flex align-items-center pt-1 mr-4"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Aita Mbaye
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <Link to="/mon-profil">
                  <a className="dropdown-item" href="#">
                    Mon compte
                  </a>
                </Link>
                <Link to="/home">
                  <a className="dropdown-item" href="#">
                    Enregistrés
                  </a>
                </Link>
                <Link >
                  <a className="dropdown-item" onClick={this.doLogout}>
                    Déconnexion
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
}

// export default connect(
//     ({ header }) => ({ ...header }),
//     dispatch => bindActionCreators({ ...headerActions }, dispatch)
//   )( header );
