import React, { Component } from "react";
import * as Icon from "react-feather";
import "./gestionProjet.css";
import Button from "../button/button";
import Adresse from "../adresse/adresse";
import Webinar from "../webinar/webinar";
import CardRentree from "../cardRentree/cardRentree";
import CardStage from "../cardStage/cardStage";
import gestiondepojet from "../../img/gestiondepojet.png";
import { Link } from "react-router-dom";

export default class GestionProjet extends Component {
  // constructor(props) {
  //     super(props);

  // }
  goToGestion = () => {
    window.location = "/demande-candidature";
  }
  render() {
    return (
      <div className="p-b-200">
        <div className="row dev-page">
          <div className="col-lg-9">
            
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                  <li className="breadcrumb-item h-theme ff-inter fw-600">
                      <Link to="/home" className="lien_nav">
                        {" "}
                        Accueil 
                      </Link>
                    </li>
                    {/* <li className="breadcrumb-item h-theme ff-inter fw-600">
                      <Link to="/formations" className="lien_nav">
                        {" "}
                        Formations 
                      </Link>
                    </li> */}
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                    >
                      Gestion de Projet
                    </li>
                  </ol>
                </nav>
              
                <div class="card mb-3 formations-card-gestion-projet">
                  <img
                    src={gestiondepojet}
                    class="card-img-top t-img-gestion-Projet"
                    alt="Gestion de projet"
                  />
                  <div class="card-body">
                    <ul id="color-li">
                      <li className="dist-li">
                        <span className="txt-prog">Scrum master</span>
                      </li>
                      <li className="dist-li">
                        <span className="txt-prog">Product owner</span>
                      </li>
                      <li className="dist-li">
                        <span className="txt-prog">Tester logiciels</span>{" "}
                      </li>
                      <li className="dist-li">
                        <span className="txt-prog">Chef de projet</span>
                      </li>
                      <li className="dist-li">
                        <span className="txt-prog">
                          gestion de projet digital
                        </span>
                      </li>
                      <li className="dist-li">
                        <span className="txt-prog">
                          Accompagner les porteurs de projets à la mise en place
                          de methode de gestion agile
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <button
              className="col-lg-12 btn-theme-light1"
              type="button"
              onClick={() => this.goToGestion()}
            >
              Rejoindre l'aventure
                </button>
      
          </div>
          <div className="col-xl-3 col-lg-3 pr-5 my-5 py-4">
            <div className="row pr-5">
              <div className="col-sm-12 width_card">
                <Button />
              </div>
              <div className="col-sm-12 width_card">
                <Adresse />
              </div>
              {/* <div className="col-sm-12 width_card">
                <Webinar />
              </div>
              <div className="col-sm-12 width_card">
                <CardRentree />
              </div>
              <div className="col-sm-12 width_card">
                <CardStage /> 
              </div>*/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
