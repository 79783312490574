import React, { Component } from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import "./sendeal.css";
import sendeal from "../../img/sendeal.png";
import GroupAdobe from "../../img/GroupAdobe.png";
import Rectangle from "../../img/Rectangle.png";
import mapPin from "../../img/map-pin.svg";
import pen from "../../img/pen-tool.svg";
import heart from "../../img/heart.svg";
import calendar from "../../img/calendar.svg";
import facebook from "../../img/facebook.png";
import twitter from "../../img/twitter.png";
import whatsApp from "../../img/whatsApp.png";
import linkedin from "../../img/linkedin.png";
import instagram from "../../img/instagram.png";
import Header from "../header/header";
import Button from "../button/button";
import Adresse from "../adresse/adresse";
import Webinar from "../webinar/webinar";
import CardRentree from "../cardRentree/cardRentree";
import CardStage from "../cardStage/cardStage";
import ContentModal from "../contentModal/contentModal";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentActions from "../../store/content/actions";
export default class SenDeal extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      <div className="p-b-200">
        <div className="row">
          <div className="col-lg-9">
            <div className="row m-b-15">
              <div className="col m-b-15">
                {/* <h6 className="h-theme ff-inter fw-600">Annonces</h6>{" "} */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item h-theme ff-inter fw-600"><Link to="/formations" className="lien_nav"> Annonces /</Link></li>
                        <li className="breadcrumb-item active" aria-current="page" className="lien_nav_current">Postuler</li>
                    </ol>
                </nav>
              </div>
              <div className="col dis-flex m-b-15"></div>
            </div>
            <div className="card-dashboard m-b-15 p-b-60">
              <div className="component-offre-stage">
                <h3 className="article my-3 py-1">SENDEAL</h3>
                <div className="card my-1 mt-2 offre-stage">
                  <div className="card-body">
                    <div class="card mb-3">
                      <div class="row">
                        <div class="col-md-4 tof-pad-left">
                          <img src={sendeal} />
                        </div>
                        <div className="col-md-8">
                          <div className="row midle-center-card-left">
                            <div className="col">
                              <b>UI/XD Designer-Dakar, Senegal</b>
                            </div>
                          </div>

                          <div className="row mid-card-left">
                            <div className="col">
                              <div className="postuler-button-card-center">
                                <Link to="/postuler-sendeal">
                                  <button
                                    type="button"
                                    class="btn btn-primary btn-lg"
                                    id="Postuler-buton-sendeal"
                                  >
                                    Postuler
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="row mid-card-left">
                            <div className="col">
                              <pan>partager l'annonce </pan>
                              <img
                                src={linkedin}
                                className="dimunition-img-reseaux"
                              />
                              <img
                                src={instagram}
                                className="dimunition-img-reseaux"
                              />
                              <img
                                src={twitter}
                                className="dimunition-img-reseaux"
                              />
                              <img
                                src={facebook}
                                className="dimunition-img-reseaux"
                              />
                              <img
                                src={whatsApp}
                                className="dimunition-img-reseaux"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-sm-3">Détails de l'annonce</div>
                    <div className="card offre-stage-card-bordure-non">
                      <div className="card-body">
                        <div className="col">
                          <b>Identification du poste</b>
                        </div>
                        <div className="row">
                          <div className="col-6 col-sm-3">Intitulé :</div>
                          <div className="col-6 col-sm-3">UI/UX Design</div>
                        </div>
                        <div className="row">
                          <div className="col-6 col-sm-3">Nature :</div>
                          <div className="col-6 col-sm-3">Stage</div>
                        </div>

                        <div className="row">
                          <div className="col-6 col-sm-3">Lieu :</div>
                          <div className="col-6 col-sm-3">Dakar, Senegal</div>
                        </div>

                        <div className="row">
                          <div className="col-6 col-sm-3">Date de debut :</div>
                          <div className="col-6 col-sm-3">immediat</div>
                        </div>
                        <div className="row">
                          <div className="col-6 col-sm-3">Recruteur</div>
                          <div className="col-6 col-sm-3">Volkeno</div>
                        </div>
                      </div>

                      <div className="row">
                        <span>
                          <b>Missions</b>
                        </span>
                        <p>
                          taille, par exemple. la hauteur ci-dessus: 2em rendra
                          le bouton à deux fois la hauteur de la ligne. Cela
                          fonctionne également pour les cases à cocher entrée
                          type = case à cocher. Certains navigateurs sont plus
                          performants que d’autres. Définir la bordure à 0
                          semble permettre à l’utilisateur de changer la taille
                          du bouton et de le rendre dans cette taille, par
                          exemple. la hauteur ci-dessus: 2em rendra le bouton à
                          deux fois la hauteur de la ligne. Cela fonctionne
                          également pour les cases à cocher entrée type = case à
                          cocher. Certains navigateurs sont plus performants que
                          d’autres.
                        </p>
                      </div>

                      <div className="card-body">
                        <div className="col">
                          <b>Competences requises</b>
                        </div>

                        <div className="col">
                          ...Competences solides en UX design et humain-centred
                          design thinkthink
                        </div>
                        <div className="col">
                          ...Connaissances des langages de Programmation(React
                          js, React Native principalement) et conception
                          web(Front end)
                        </div>
                        <div className="col">
                          ...Connaissances des principes d'utilisabilité et
                          d'acceptibilité
                        </div>
                        <div className="col">
                          ...Connaissances professionnelle et approfondie des
                          outils UI/UX (suite adobe XD, Figma...)
                        </div>
                        <div className="col">
                          ...Connaissances des programmes de graphisme et
                          edition d'images
                        </div>
                        <div className="col">
                          ...Créativité et sans esthétique
                        </div>
                        <div className="col">
                          ...Excellentes capacités de communication
                        </div>
                        <div className="col">
                          ...Flexibilité et résolution de problemes
                        </div>

                        <div className="row">
                          <div className="col-6 col-sm-3">
                            <b>Métier</b>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            Informatique, nouvelles Technologies, Marketing
                            communication
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6 col-sm-3">
                            <b>Secteur</b>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">
                            Informatique, SSII, Marketing, communication, médias
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">
                            <b>Niveau d'experience</b>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">Débutant 2ans</div>
                        </div>

                        <div className="row">
                          <div className="col-6 col-sm-3">
                            <b>Niveau D'études</b>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">BAC BAC+2</div>
                        </div>
                        <div className="row">
                          <div className="col-6 col-sm-3">
                            <b>Langues exigées</b>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">Français courant</div>
                        </div>
                        <div className="row">
                          <div className="col-6 col-sm-3">
                            <b>Nombres de postes</b>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">20</div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body postuler-button-card-center">
                      <div className="row">
                        <div className="col">
                          <Link to="/postuler-sendeal">
                            <button
                              type="button"
                              class="btn btn-primary btn-lg"
                              id="Postuler-buton"
                            >
                              Postuler
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card mb-3">
                  <div class="row">
                    <div class="col-md-4 tof-pad-left">
                      <img src={Rectangle} />
                    </div>
                    <div className="col-md-8">
                      <div className="row mid-card-left">
                        <div className="col-lg-6" id="bom-space">
                          Emploie Dakar.com
                        </div>
                        <div className="col-lg-6" id="bom-space">
                          voir plus de details<i className="fas fa-eye"></i>
                        </div>
                      </div>

                      <div className="row mid-card-left">
                        <div className="col-lg-4" id="bom-space"></div>
                        <div className="col-lg-4" id="bom-space"></div>
                        <div className="col-lg-4" id="bom-space">
                          <img src={heart} />
                        </div>
                      </div>

                      <div className="row mid-card-left">
                        <div className="col-lg-4" id="bom-space">
                          <img src={pen} />
                          Stage
                        </div>
                        <div className="col-lg-4" id="bom-space">
                          <img src={mapPin} />
                          Dakar
                        </div>
                        <div className="col-lg-4" id="bom-space">
                          <img src={calendar} />
                          il y a 1 jour
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card mb-3">
                  <div class="row">
                    <div class="col-md-4 tof-pad-left">
                      <img src={Rectangle} />
                    </div>
                    <div className="col-md-8">
                      <div className="row mid-card-left">
                        <div className="col-lg-6" id="bom-space">
                          Emploie Dakar.com
                        </div>
                        <div className="col-lg-6" id="bom-space">
                          voir plus de details<i className="fas fa-eye"></i>
                        </div>
                      </div>

                      <div className="row mid-card-left">
                        <div className="col-lg-4" id="bom-space"></div>
                        <div className="col-lg-4" id="bom-space"></div>
                        <div className="col-lg-4" id="bom-space">
                          <img src={heart} />
                        </div>
                      </div>

                      <div className="row mid-card-left">
                        <div className="col-lg-4" id="bom-space">
                          <img src={pen} />
                          Stage
                        </div>
                        <div className="col-lg-4" id="bom-space">
                          <img src={mapPin} />
                          Dakar
                        </div>
                        <div className="col-lg-4" id="bom-space">
                          <img src={calendar} />
                          il y a 1 jour
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <button
                    class="btn btn-primary en-plus-large-buton"
                    type="button"
                  >
                    Voir plus
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <Button />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                <Adresse />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                <Webinar />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                <CardRentree />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <CardStage />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ demande }) => ({ ...demande }),
//     dispatch => bindActionCreators({ ...contentActions }, dispatch)
//   )( content );
