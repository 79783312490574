import React, { Component } from "react";
import "./contenuListeView.css";
import HeaderDashboard from "../headerDashboard/index";
import NavVertical from "../navVertical/index";
import DashboardBakeliste from "../dashboardBakeliste/index";
import ContenuListe from "../contenuListe/contenuListe";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contenuListeViewActions from "../../store/contenuListeView/actions";
export default class contenuListeView extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      <div className="component-contenu-liste-view">
        {/* <div className="body-theme"> */}
          <HeaderDashboard />
          <div className="container-fluid fixing-after-header">
            <div className="row">
              <div className="col-lg-2 p-0">
                <NavVertical />
              </div>
              <div className="col-lg-9 pt-3">
                <ContenuListe />
              </div>
            {/* </div> */}
          </div>
        </div>
        ;
      </div>
    );
  }
}
// export default connect(
//     ({ contenuListeView }) => ({ ...contenuListeView }),
//     dispatch => bindActionCreators({ ...contenuListeViewActions }, dispatch)
//   )( contenuListeView );
