import React, { Component } from "react";
import "./candidatureForm.css";
import "./candidatureFormMai.css";
import amplitude from "amplitude-js";
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";
import axios from "axios";
import API from "../../variablesGlobales";
import Logo from "../../img/logo.png";
import FeatherIcon from "feather-icons-react";
import ReactPlayer from "react-player";
import { NavLink } from "react-router-dom";
import rentree from "../../img/rentreeOctobre.png";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as candidatureFormActions from "../../store/candidatureForm/actions";
export default class candidatureFormAvril extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "", 
      token: null,
      isPending: false,
      isValidUser: true,
      userFullName: "",
      api: API.API,
      flashMessage: "",
      addModificationProgress: false,
      candidaturInProgress: false,
      domaine_empty: false,
      userPhone: "",
      inscriptionSent: false,
      domaines: [],
      remaining_char: 245,
      fields: {},
      input: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitCandidature = this.submitCandidature.bind(this);
  }

  componentDidMount() {
    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    amplitude.getInstance().logEvent("visitedBakeliWeekendInscriptionVisiteur");
  }
  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);
    amplitude.getInstance().init(api_key);
  };

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;

    let input = this.state.input;
    input[e.target.name] = e.target.value;

    var chars = e.target.value.length;
    var remaining_char = 245 - chars;
    this.setState({
      fields,
      remaining_char: remaining_char,
      input,
    });
  };
  onValidateFormData = () => {
    let fields = this.state.fields;
    // console.log(fields);
    let errors = {};
    let isValidForm = true;
    let input = this.state.input;

    if (!fields["domaine"]) {
      isValidForm = false;
      this.setState({
        domaine_empty: true,
      });
      errors["domaine_empty"] = "Ce champ est obligatoire";
    }
    if (!fields["objectifs"]) {
      isValidForm = false;
      this.setState({
        motivation_empty: true,
      });
      errors["motivation_empty"] = "Ce champ est obligatoire";
    }

    // if (!fields["phone"]) {
    //   isValidForm = false;
    //   this.setState({
    //     phone_whatsapp_empty: true,
    //   });
    //   errors["phone"] = "s'il vous plait entrez votre numéro de téléphone";
    // }
    // if (!input["phone"]) {
    //   isValidForm = false;
    //   errors["phone"] = "s'il vous plait entrez votre numéro de téléphone";
    // }
    // if (typeof input["phone"] !== "undefined") {
    //   var pattern = new RegExp(/^[0-9\b]+$/);
    //   if (!pattern.test(input["phone"])) {
    //     isValidForm = false;
    //     errors["phone"] = "Veuillez saisir un numéro.";
    //   } else if (input["phone"].length != 10) {
    //     isValidForm = false;
    //     errors["phone"] =
    //       "S'il vous plait entrez un numéro de téléphone valide.";
    //   }
    // }
    if (!fields["phone"]) {
      isValidForm = false;
      this.setState({
        phone_empty: true,
      });
      errors["phone_empty"] = "Ce champ est obligatoire";
    }
    if (!fields["canal_infos"]) {
      isValidForm = false;
      this.setState({
        phone_empty: true,
      });
      errors["canal_infos"] = "Ce champ est obligatoire";
    }
    // if (!fields["domaine_id"]) {
    //   isValidForm = false;
    //   this.setState({
    //     domaine_id_empty: true,
    //   });
    //   this.onSwitchAlertStatus("domaine_id_empty");
    //   errors["domaine_id_empty"] = "Ce champ est obligatoire";
    // }
    if (!fields["type_formation"]) {
      isValidForm = false;
      this.setState({
        type_formation_empty: true,
      });
      // this.onSwitchAlertStatus("type_formation_empty");
      errors["type_formation_empty"] = "Ce champ est obligatoire";
    }
    if (!fields["session"]) {
      isValidForm = false;
      this.setState({
        session_empty: true,
      });
      // this.onSwitchAlertStatus("session_empty");
      errors["session_empty"] = "Ce champ est obligatoire";
    }
    // if (!fields["duree_formation"]) {
    //   isValidForm = false;
    //   this.setState({
    //     duree_formation_empty: true,
    //   });
    //   this.onSwitchAlertStatus("duree_formation_empty");
    //   errors["duree_formation_empty"] = "Ce champ est obligatoire";
    // }
    // if (!fields["lieu_formation"]) {
    //   isValidForm = false;
    //   this.setState({
    //     lieu_formation_empty: true,
    //   });
    //   this.onSwitchAlertStatus("lieu_formation_empty");
    //   errors["lieu_formation_empty"] = "Ce champ est obligatoire";
    // }

    this.setState({
      errors: errors,
    });
    // console.log(isValidForm);

    return isValidForm;
  };

  submitCandidature = (e) => {
    e.preventDefault();
    if (this.onValidateFormData()) {
      let input = {};
      input["phone"] = "";

      this.setState({
        candidaturInProgress: true,
        input: input,
      });
      var data = this.state.fields;

      var fd = new FormData();
      // const week = "05 Avril 2022";

      fd.append("first_name", this.state.fields.first_name);
      fd.append("last_name", this.state.fields.last_name);
      fd.append("address", this.state.fields.address);
      fd.append("email", this.state.fields.email);
      fd.append("domaine", this.state.fields.domaine);
      fd.append("phone", this.state.fields.phone);
      fd.append("objectifs", this.state.fields.objectifs);
      fd.append("canal_infos", this.state.fields.canal_infos);
      // fd.append("is_pro", this.state.fields.is_pro);
      fd.append("session", this.state.fields.session);
      fd.append("type_formation", this.state.fields.type_formation);
      // fd.append("is_week", this.state.fields.is_week);
      // const weekend = " MasterClass Weekend";

      // if (this.state.fields.is_week === "1") {
      //   fd.append("session", week);
      // } else {
      //   fd.append("session", weekend);
      // }
      var eventProperties = {
        user_email: this.state.fields.email,
        // is_week: this.state.fields.is_week,
        userFullname:
          this.state.fields.first_name + " " + this.state.fields.last_name,
      };
      axios
        .post(this.state.api + "store-inscription-prospect", fd)
        .then((response) => {
          // console.log("ressss", response);
          if (response.data.success) {
            amplitude
              .getInstance()
              .logEvent("INSCRIPTION_BAKELI_SUCCESS", eventProperties);

            this.setState({
              candidaturInProgress: false,
              inscriptionSent: true,
            });
          }
        })
        .catch((error) => {
          amplitude
            .getInstance()
            .logEvent("INSCRIPTION_BAKELI_ERROR", eventProperties);

          this.setState({
            successfullUpdate: false,
            candidaturInProgress: false,
            inscriptionNotSent: true,
            successfullMessage:
              "Une erreur est survenue lors de l'inscription, veuillez reessayer.",
          });
        });
    }
  };
  render() {
    return (
      <Amplitude>
        <LogOnMount eventType="visite page d'inscription bakeli weekend" />

        <div className="">
          <div className="row page-register">
            <div
              className="col-md-6 banner-connexion-page-home d-flex
            "
            >
                   <div className="container-nav-vertical-homeII">
                     
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 d-flex justify-content-center itm-center bg-white">
              <div className="col-xl-10 col-lg-9 col-md-12 col-sm-8 d-flex flex-col">
                <img
                  className="logo-bakeli m-l-r-auto mt-5"
                  src={Logo}
                  alt=""
                />
                <p className="h-connexion-form">INSCRIPTION BAKELI POUR LE 16 Avril 2024</p>{" "}
                {/* <NavLink to="/">
                  <button className="btn-green">
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="book-open"
                    />
                    Explorer
                  </button>
                </NavLink> */}
                {this.state.inscriptionSent ? (
                  <p className="successMsg">
                    Votre inscription a été prise en compte , Un e-mail de
                    confirmation vous a été envoyé. Merci
                  </p>
                ) : (
                  <form
                    onSubmit={(e) => this.submitCandidature(e)}
                    encType="multipart/form-data"
                  >
                    <p className="p-3">
                      Les champs précédes de ce signe{" "}
                      <span style={{ color: "red" }}> * </span> sont
                      obligatoires
                    </p>
                    <div class="card btom-card-demande-candidature">
                      <div class="card-body">
                        <span className="experience-demande-candidature">
                          Informations personnelles{" "}
                          <span style={{ color: "red" }}> * </span>
                        </span>
                        <div className="row mt-3">
                          <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                            <input
                              type="text"
                              className="form-control control-3"
                              placeholder="Prénom"
                              aria-label="First name"
                              name="first_name"
                              onChange={(e) => this.handleChange(e)}
                              required
                              // value={this.state.fields["first_name"]}
                            />
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                            <input
                              type="text"
                              className="form-control control-3"
                              placeholder="Nom"
                              aria-label="Last name"
                              name="last_name"
                              onChange={(e) => this.handleChange(e)}
                              required
                              // value={this.state.fields["first_name"]}
                            />
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                            <input
                              type="text"
                              className="form-control control-3"
                              placeholder="Telephone"
                              aria-label="telephone"
                              name="phone"
                              onChange={(e) => this.handleChange(e)}
                              required
                              value={this.state.input.phone}
                              // value={this.state.fields["first_name"]}
                            />

                            {/* <div className="text-danger">
                              {this.state.errors.phone}
                            </div> */}
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                            <input
                              type="email"
                              className="form-control control-3"
                              placeholder="Email"
                              aria-label="email"
                              name="email"
                              onChange={(e) => this.handleChange(e)}
                              required
                              // value={this.state.fields["first_name"]}
                            />
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                            <input
                              type="text"
                              className="form-control control-3"
                              placeholder="Ville Residence"
                              aria-label="adresse"
                              name="address"
                              onChange={(e) => this.handleChange(e)}
                              required
                              // value={this.state.fields["first_name"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card btom-card-demande-candidature">
                      <div class="card-body">
                        <span className="experience-demande-candidature">
                          Quelle formation souhaitez-vous suvire à Bakeli?{" "}
                          <span style={{ color: "red" }}> * </span>
                        </span>
                        <br />
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="domaine"
                              value="Développement web"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Développement Web/Mobile
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="domaine"
                              value="Marketing Digital"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Marketing et communication digitale
                          </label>
                        </div>
                        {/* <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="domaine"
                              value="Design"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Design
                          </label>
                        </div> */}
                         {/* <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="domaine"
                              value="Business digital"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Business digital
                          </label>
                        </div> */}
                        {/*<div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="domaine"
                              value="gestion_de_projet"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Gestion de projet
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="domaine"
                              value="Bureautique"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Bureautique
                          </label>
                        </div> */}
                        {this.state.domaine_empty && (
                          <div className="errorMsg">
                            {this.state.errors.domaine_empty}
                          </div>
                        )}
                        <span className="experience-demande-candidature">
                          Je m'inscris pour{" "}
                          <span style={{ color: "red" }}> * </span>
                        </span>
                        <br />
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="type_formation"
                              value="ligne"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            La formation en ligne
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="type_formation"
                              value="presentiel"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            La formation en présentiel
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="type_formation"
                              value="weekend"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            La formation en weekend
                          </label>
                        </div>
                        {this.state.type_formation_empty && (
                          <div className="errorMsg">
                            {this.state.errors.type_formation_empty}
                          </div>
                        )}
                         <span className="experience-demande-candidature">
                          Dans quelle Bakeli voulez-vous former?{" "}
                          <span style={{ color: "red" }}> * </span>
                        </span>
                        <br />
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="session"
                              value="Dakar"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Dakar
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="session"
                              value="Thies"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Thiès
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="session"
                              value="Mbour"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Mbour
                          </label>
                        </div>
                        {this.state.session_empty && (
                          <div className="errorMsg">
                            {this.state.errors.session_empty}
                          </div>
                        )}

                        <span className="experience-demande-candidature">
                          Vous etes <span style={{ color: "red" }}> * </span>
                        </span>
                        <br />
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="is_pro"
                              value="1"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Professionnel
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="is_pro"
                              value="0"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Etudiant
                          </label>
                        </div>

                        {this.state.domaine_empty && (
                          <div className="errorMsg">
                            {this.state.errors.domaine_empty}
                          </div>
                        )}
                        </div>
                        <div class="card btom-card-demande-candidature">
                          <div class="card-body">

                            <span className="experience-demande-candidature">
                              Comment avez-vous connu Bakeli ?
                              <span style={{ color: "red" }}> * </span>
                            </span>
                            <br />
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  value="facebook"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via Facebook
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  value="whatsapp"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via Whatsapp
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  value="linkedin"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via Linkedin
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  value="instagram"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via Instagram
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  value="youtube"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via Youtube
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  value="ami"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via un ami/camarade/proche...
                              </label>
                            </div>

                            {this.state.canal_infos && (
                              <div className="errorMsg">
                                {this.state.errors.canal_infos}
                              </div>
                            )}
                          </div>
                        </div>
                    </div>
                    <div class="card btom-card-demande-candidature">
                      <div class="card-body">
                        <span className="experience-demande-candidature">
                          <span style={{ color: "red" }}> * </span>Quelles sont vos motivations pour cette formation
                        </span>
                        <p>
                          <textarea
                            placeholder="Mettez vos motivations ici"
                            onChange={(e) => this.handleChange(e)}
                            name="objectifs"
                            type="text"
                            className="motivation-candidature"
                            aria-label="motivation"
                          />
                          {this.state.remaining_char >= 31 && (
                            <span className="restant_container_sup_30">
                              {" "}
                              {this.state.remaining_char} caractéres restants
                            </span>
                          )}
                          {this.state.remaining_char <= 30 &&
                            this.state.remaining_char > 1 && (
                              <span className="restant_container_inf_30">
                                {" "}
                                {this.state.remaining_char} caractéres restants
                              </span>
                            )}
                          {this.state.remaining_char <= 1 && (
                            <span className="restant_container_inf_30">
                              {" "}
                              {this.state.remaining_char} caractére restant
                            </span>
                          )}
                          {this.state.motivation_empty && (
                            <div className="errorMsg">
                              {this.state.errors.motivation_empty}
                            </div>
                          )}{" "}
                        </p>
                      </div>
                    </div>

                    <div class="card btom-card-demande-candidature">
                      <div class="card-body">
                        <span className="experience-demande-candidature">
                          A Propos{" "}
                        </span>
                        <p>
                          {/* Bakeli school of technology vous informe de sa
                          prochaine rentrée scolaire prévu le 06 Juillet. Vous
                          étiez nombreux à nous demander les formations en
                          week-end, désormais Bakeli vous offre l’occasion de
                          vous former les samedis et les dimanches. Une
                          formation qui vous permet de vous initier, vous
                          perfectionner ou vous spécialiser rapidement… quel que
                          soit votre besoin de formation, nous avons la solution
                          ! */}

                          Bakeli school of technology vous informe de sa prochaine 
                          rentrée scolaire prévue le 16 Avril. Vous étiez nombreux 
                          à nous demander les formations en week-end et les formations 
                          en ligne. Désormais, Bakeli vous offre l’occasion de vous 
                          former les week-end ou bien de vous former entièrement en ligne.
                          Une formation qui vous permet de vous initier, vous perfectionner
                          où vous spécialisez rapidement… Quel que soit votre besoin de formation,
                          nous avons la solution !
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        {this.state.inscriptionNotSent && (
                          <p className="errorMsg">
                            Une erreur est survenue lors de l'inscription,
                            veuillez reessayer.
                          </p>
                        )}
                        {this.state.candidaturInProgress ? (
                          <button
                            type="submit"
                            className="btn-theme-candidature text-up m-t-30 w-full p-t-15 p-b-15"
                            disabled
                          >
                            En Cours &nbsp;
                            <FeatherIcon icon="loader" aria-hidden="true" />
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn-theme-candidature text-up m-t-30 w-full p-t-15 p-b-15 mb-5"
                          >
                            REJOINDRE L'AVENTURE
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </Amplitude>
    );
  }
}
// export default connect(
//     ({ candidatureForm }) => ({ ...candidatureForm }),
//     dispatch => bindActionCreators({ ...candidatureFormActions }, dispatch)
//   )( candidatureForm );
