import React, {Component} from 'react';
import './footer.css';
import FeatherIcon from 'feather-icons-react';
import logo2 from '../../img/logo2.png';
import { Link } from 'react-router-dom';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as footerActions from "../../store/footer/actions";
export default class footer extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return( 
      <div className="component-footer">
          <footer className="text-white text-lg-start">
           
            <div className="p-4">
              
              <div className="row">
               
                <div className="col-lg-3 col-md-12 mb-4 mb-md-0">
                  <h5 className="text pb-3">Bakeli</h5>

                 <p className="txt-des-bakeli">BAKELI est une école de formation
                      professionnelle dans les nouvelles 
                      technologies crée par Volkeno.</p>
                </div>
                
                <div className="col-lg-3 col-md-6 mb-4 mb-md-0 offset-1">
                  <h5 className="text pb-3">Adresse</h5>
                  <div className="row">
                    <div className="col-lg-1">
                    <FeatherIcon icon="map-pin"/>
                    </div>
                  <div className="col-lg-11">
                    <p className="txt-des-bakeli">        
                  Hlm Grand Yoff Villa n°241 près du terminus des 
                  bus Dakar Dem Dikk qui vont à l'AIBD
                    Dakar 11500
                  </p>
                  </div>
                  </div>
                </div>
               
                <div className="col-lg-3 col-md-12 mb-4 mb-md-0  offset-1">
                  <h5 className="resp-suivre text pb-3">Nous suivre</h5>
                      <ul className="list-unstyled">
                        <li className="text-white pb-3"><FeatherIcon icon="mail"/> contact@bakeli.tech</li>
                        <li className="text-white"><FeatherIcon icon="phone"/> (+221)78 546 15 51</li>
                      </ul>
                  <ul className="list-unstyled grid-reseaux p-2 mb-0">
                    <li>
                      <a href="#!" className="text-white"><FeatherIcon icon="facebook"/></a>
                    </li>
                    <li>
                      <a href="#!" className="text-white"><FeatherIcon icon="twitter"/></a>
                    </li>
                    <li>
                      <a href="#!" className="text-white"><FeatherIcon icon="instagram"/></a>
                    </li>
                    <li>
                      <a href="#!" className="text-white"><FeatherIcon icon="linkedin"/></a>
                    </li>
                  </ul>
                </div>
                
              </div>
              
            </div>
            <hr className="bg-white"/>
            <div className="p-2 offset-1">
            <p> <img src={logo2} className="taille-logo-bakeli-footer"/> 
            <span className="pl-5 forme-ecrit">&copy; 2021 - Tous droits réservés - By Bakeli</span></p>
             
            </div>
            
          </footer>
      </div>
      );
    }
  }
// export default connect(
//     ({ footer }) => ({ ...footer }),
//     dispatch => bindActionCreators({ ...footerActions }, dispatch)
//   )( footer );