import React, { Component } from "react";

import { Link } from "react-router-dom";
import image from "../../img/image.png";
import Logo from "../../img/logo.png";
import imagee from "../../img/imagee.png"; 
import frenchShortFormatDate from "../frenchFormatDate";

import "./contentEventAvenir.css";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentEventAvenirActions from "../../store/contentEventAvenir/actions";
export default class contentEventAvenir extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formatDate: frenchShortFormatDate.frenchShortFormatDate,

    };
  }
  render() {
    const id = this.props.id;
    return (
      <div className="component-content-event-avenir">
        <div>
          {/* <h3 className="article my-3 py-1">Evènements à venir</h3> */}
        </div>
        <div className="container-fluid py-4">
          <div className="row">
            {/* <div className="col-xl-2 col-lg-2 col-md-12 py-3">
              <img src={Logo} alt="event" className="im" />
            </div> */}
            <div className="col-xl-9 col-lg-9 col-md-9 py-3 bg-white">
              <h6 className="center">{this.props.name}</h6>
              <div className="row journee">
                <div className="col-xl-4 col-lg-4 journee">
                  Bakeli, HLM Grd yoff, 241
                </div>
                <div className="col-xl-4 col-lg-4 journee">
                   {this.state.formatDate(this.props.date)}
                </div>
                <div className="col-xl-4 col-lg-4 journee">Journée entière</div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 py-3">
              <Link
                className="btn-inscri"
                to={{
                  pathname: "/details-events",
                  state: { id: id }, // your data array of objects
                }}
              >
                S'inscrire à l'évenement
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ contentEventAvenir }) => ({ ...contentEventAvenir }),
//     dispatch => bindActionCreators({ ...contentEventAvenirActions }, dispatch)
//   )( contentEventAvenir );
