import React, { Component } from "react";
import "./sommesNous.css";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as sommesNousActions from "../../store/sommesNous/actions";
export default class sommesNous extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      <div className="component-sommes-nous">
        <div className="container-fluid pt-5 bg-white">
          <h3 className="txt-som-nous">QUI SOMMES NOUS ?</h3>
          <div className="row pt-5 pl-4 pr-4 dis-flex">
            <div className="col-md-6 col-xl-6">
              <span className="bakeli-sch">
                Bienvenue à Bakeli <br /> School of <br />
                Technology
              </span>
              <div>
                <p className="mots-bakeli pt-3">
                  Bakeli est une école de formation en technologie créée par
                  l’entreprise Volkeno dans 4 domaines : Développement Logiciels
                  (web, mobile, desktop, systèmes) Design (infographie,
                  multimédia, photographie, montage, ui/ux) Marketing Digital
                  (community management, seo, rédaction de contenus)
                  Entrepreneuriat dans les technologies (startups, tpe, pme,
                  gestion de projet)
                </p>
              </div>
            </div>
            <div className="col-md-6 col-xl-6 col-lg-6 col-sm-2">
              <div className="depl-images-animation"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ sommesNous }) => ({ ...sommesNous }),
//     dispatch => bindActionCreators({ ...sommesNousActions }, dispatch)
//   )( sommesNous );
