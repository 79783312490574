import React, { Component } from "react";
import { useState } from "react";
import HeaderDashboard from "../headerDashboard/index";
import NavVertical from "../navVertical/index";
import Content from "../content/index";
import Button from "../button/button";
import Adresse from "../adresse/adresse";
import "./viewAccueil.css";

import Webinar from "../webinar/webinar";
import CardRentree from "../cardRentree/cardRentree";
import CardStage from "../cardStage/cardStage";
import ContentModal from "../contentModal/contentModal";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as viewAccueilActions from "../../store/viewAccueil/actions";
export default class viewAccueil extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }

  render() {
    return (
      <div className="component-view-accueil">
        {" "}
        <HeaderDashboard />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVertical />
            </div>
            <div className="col-lg-10 pt-3">
              <Content />{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ viewAccueil }) => ({ ...viewAccueil }),
//     dispatch => bindActionCreators({ ...viewAccueilActions }, dispatch)
//   )( viewAccueil )
