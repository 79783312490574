// ************LOCAL

// const API = "http://127.0.0.1:8000/api/";
// const base_api_url = "http://127.0.0.1:8000";

// ************RECETTE

// const API = "https://bakeli-si-recette-api.bakeli.tech/api/";
// const base_api_url = "https://bakeli-si-recette-api.bakeli.tech";

// ************PROD
const API = "https://via-api.bakeli.tech/api/";
const base_api_url = "https://via-api.bakeli.tech";

module.exports = {
  API: API,
  base_api_url: base_api_url,
};
