import React, {Component} from 'react';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import imagee from '../../img/imagee.png';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ShareIcon from '@material-ui/icons/Share';

import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import PlaceIcon from '@material-ui/icons/Place';
import './contentEventJeemacoder.css'
import { Link } from 'react-router-dom';
import Dialog  from '../dialogBoite/index';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentEventJeemacoderActions from "../../store/contentEventJeemacoder/actions";
export default class contentEventJeemacoder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,            
        };
    }

    handleChange = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
      };

    render() {
      return (
      <div className="component-content-event-jeemacoder">
          <div className="article my-3 py-2">
          <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item h-theme ff-inter fw-600"><Link to="/home" className="lien_nav"> Evènements A venir /</Link></li>
                        <li className="breadcrumb-item active" aria-current="page" className="lien_nav_current">Kokùtana</li>
                    </ol>
                </nav>
                </div>
                <div className="container-fluid px-0 kokutana pb-3">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                            <a className="" href="#"><img src={imagee} alt="imagee" className="imlogo" /></a>
                        </div>
                    </div>
                    <div className="row" >
                        <div className="col-xl-3 col-lg-3 col-md-3"></div>
                        <div className="col-xl-9 col-lg-9 col-md-9 marge">
                            <div>
                            LUNDI, Journée entière
                            </div>
                            <div>
                            Jéema Coder
                            </div>
                            <div>
                            Bakeli, HLM GRAND YOFF 241
                            </div>
                            <div className="pr-5">
                                <hr className="ligne"></hr>
                            </div>
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3 textgreen">
                           

                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active textgreen" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Description</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link textgreen" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">À propos</a>
                                </li>
                                
                            </ul>


                        </div>
                        
                        <div className="col-xl-5 col-lg-5 col-md-5">
                            <button className="btn btn-checkk">
                                <StarBorderIcon />Ça m’intéresses
                            </button>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <button 
                            className="btn btn-checkk"
                            onClick={(e) => this.setState({ isOpen: true })}
                            >
                            <CheckCircleOutlineIcon />Je m’inscris

                            <Dialog 
                            isOpen={this.state.isOpen} 
                            onClose={(e) => this.setState({ isOpen: false })}>
                          
                            <div class="card btom-card-demande-candidature">
                              <div class="card-body">
                              <span className="experience-demande-candidature">
                                Comment avez-vous connu l'évènement ? 
                                <span style={{ color: "red" }}> * </span>
                              </span>
                              <br />
                              <div class="checkbox c-checkbox">
                                <label>
                                  <input
                                    type="radio"
                                    name="canal_infos"
                                    value="facebook"
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                  <span class="fa fa-bars"></span>
                                  Via Facebook
                                </label>
                              </div>
                              <div class="checkbox c-checkbox">
                                <label>
                                  <input
                                    type="radio"
                                    name="canal_infos"
                                    value="whatsapp"
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                  <span class="fa fa-bars"></span>
                                  Via Whatsapp
                                </label>
                              </div>
                              <div class="checkbox c-checkbox">
                                <label>
                                  <input
                                    type="radio"
                                    name="canal_infos"
                                    value="linkedin"
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                  <span class="fa fa-bars"></span>
                                  Via Linkedin
                                </label>
                              </div>
                              <div class="checkbox c-checkbox">
                                <label>
                                  <input
                                    type="radio"
                                    name="canal_infos"
                                    value="instagram"
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                  <span class="fa fa-bars"></span>
                                  Via Instagram
                                </label>
                              </div>
                              <div class="checkbox c-checkbox">
                                <label>
                                  <input
                                    type="radio"
                                    name="canal_infos"
                                    value="youtube"
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                  <span class="fa fa-bars"></span>
                                  Via Youtube
                                </label>
                              </div>
                              <div class="checkbox c-checkbox">
                                <label>
                                  <input
                                    type="radio"
                                    name="canal_infos"
                                    value="ami"
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                  <span class="fa fa-bars"></span>
                                  Via un ami/camarade/proche...
                                </label>
                              </div>

                            
                            </div>
                          </div>
                        </Dialog>
                        </button>
                           
                        </div>
                    </div>
                </div>
                                 
                <div className="container-fluid py-3 px-0">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="bloctext2">
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at dui massa amet facilisi lectus pulvinar ut nullam. Tellus turpis sed dignissim ullamcorper quis. Egestas augue porttitor orci, egestas commodo interdum enim eget faucibus. Pellentesque sit tempus pulvinar sit sit turpis faucibus sed risus. Neque, congue elit, aliquam felis velit eget integer. Hendrerit arcu lectus lorem amet ultricies. Rutrum fringilla nisl convallis in amet hac nunc. Aliquam sed ullamcorper feugiat ut erat et. Augue nunc, facilisi faucibus at. Dui justo, hac egestas malesuada turpis a ultrices duis lobortis. Ut consequat eu facilisis sodales vitae erat mi.
Augue mauris elit nulla mi. Sagittis augue elementum amet neque praesent neque adipiscing morbi gravida. Et sagittis nec vitae sed quis mi consectetur phasellus. Suspendisse suscipit malesuada congue eu. Lorem in felis adipiscing a orci. Et iaculis in tempor cras.
                                </div>
                                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                   <div className="texte_tab_pan py-3 px-5">
                                    <h6 className="textgreen2 py-4">Détails</h6>
                                        <div>
                                            <SupervisorAccountIcon className="icon_tab_pan" />
                                            <span className="texte_icon">98 personnes ont répondu</span>
                                        </div>
                                        <div>
                                            <CalendarTodayIcon className="icon_tab_pan"  />
                                            <span className="texte_icon">19 Octobre 2020</span>
                                        </div>
                                        <div>
                                            <AccessAlarmIcon className="icon_tab_pan"  />
                                            <span className="texte_icon">Journée entière</span>
                                        </div>
                                        <div>
                                            <PlaceIcon className="icon_tab_pan"  />
                                            <span className="texte_icon">Bakeli, Hlm grand yoff 241</span>
                                        </div>
                                        <div>
                                            <span className="texte_icon_titre">Kokùtana</span>
                                        </div>
                                        <div className="btn_btn">
                                            <button className="btn btn-education py-1">Éducation</button>
                                        </div>
                                   </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="bloctext2">
                                <div className="textgreen2 py-4">
                                Participants
                                </div>
                                <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-md-3">
                                        <button className="btn btn-chiffre">49</button>
                                        <div className="textgreen tailletext">
                                        Interessés
                                        </div>
                                    </div>
                                    <div className="col-xl-6"></div>
                                    <div className="col-xl-3 col-lg-6 col-md-6 pr-3">
                                        <button className="btn btn-chiffre">49</button>
                                        <div className="textgreen tailletext">
                                        Inscris
                                        </div>
                                    </div>
                                </div>
                                <div className="px-3">
                                    <hr className="ligne"></hr>
                                </div>
                                <div className="py-4 px-2">
                                    <button className="btn-chiffre2 taillebtn">
                                        <ShareIcon />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    
      </div>
      );
    }
  }
// export default connect(
//     ({ contentEventJeemacoder }) => ({ ...contentEventJeemacoder }),
//     dispatch => bindActionCreators({ ...contentEventJeemacoderActions }, dispatch)
//   )( contentEventJeemacoder );