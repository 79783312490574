import React, { Component } from "react";
import "./temoignageLeading.css";
import ImgSlide from "../../img/imgSlide.png";
import Mariama from "../../img/mariama.jpeg";
import Aichetou from "../../img/aichetou.png";
import Daouda from "../../img/daouda.jpeg";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as temoignageLeadingActions from "../../store/temoignageLeading/actions";
export default class temoignageLeading extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      <div className="component-temoignage-leading ">
        <div className="carousel-slider-comp bg-white pt-5">
          <h3 className="txt-som-nous mb-5">Temoignages</h3>
          <div
            id="carouselExampleDark"
            className="carousel carousel-dark slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide-to="0"
                className="active taille-but-slid"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide-to="1"
                className="taille-but-slid"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide-to="2"
                className="taille-but-slid"
                aria-label="Slide 3"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active" data-bs-interval="10000">
                <div className="row">
                  <div className="col-xl-5 col-lg-5 col-md-5 la-marge-de-la-photo">
                    <img
                      src={Mariama}
                      alt="Mariama Mbaye"
                      className="dimunition-de-la-taille-img"
                    />
                  </div>
                  <div className="col-xl-7 col-lg-7 col-md-7">
                    <p className="comportement-de-notre-text">
                      A Bakeli on note la rigueur, la discipline, le travail
                      d'équipe, la compétence, la pratique surtout... J'ai eu à
                      réaliser mon reve d'etre Digital marketer. la formation
                      m'a permis d'avoir des connaissances dans le domaine
                      digital et monté mon projet personnel. ce qui me plait le
                      plus à Bakeli tu te sens que tu es en entreprise
                    </p>
                    <p className="deplacement-title-responsable">
                      {" "}
                      <b>Mariama Mbaye</b>
                      <br />{" "}
                      <span className="title-responsable">
                        Marketing Digital
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="carousel-item" data-bs-interval="2000">
                <div className="row">
                  <div className="col-xl-5 col-lg-5 col-md-5 la-marge-de-la-photo">
                    <img
                      src={Daouda}
                      alt="Daouda Senghor"
                      className="dimunition-de-la-taille-img"
                    />
                  </div>
                  <div className="col-xl-7 col-lg-7 col-md-7">
                    <p className="comportement-de-notre-text">
                      Ma formation en Developpement Web chez Bakeli School of
                      Technology a révellée mes talents et m'a aidé à développer
                      mes compétences techniques. Au dela de la formation qui
                      est 100% pratique, j'ai pu bénéficier d'un accompagnement
                      en développement personnel qui m'a permis d'oser prendre
                      les devants pour m'inserer professionnellement.
                    </p>
                    <p className="deplacement-title-responsable">
                      {" "}
                      <b>Daouda Senghor</b>
                      <br />{" "}
                      <span className="title-responsable">
                        Développement Web
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-xl-5 col-lg-5 col-md-5 la-marge-de-la-photo">
                    <img
                      src={Aichetou}
                      alt="Aichetou Bilal"
                      className="dimunition-de-la-taille-img"
                    />
                  </div>
                  <div className="col-xl-7 col-lg-7 col-md-7">
                    <p className="comportement-de-notre-text">
                      J'ai toujours été à la recherche d'un metier dont je serai
                      passionné. A cette quette je suis tombé sur Bakeli là ou
                      j'ai la rencontre du design, qui est devenu ma passion, un
                      metier que j'aimerai exercer toute ma vie. Bakeli m'a
                      permis d'avoir des objectifs à long terme.
                    </p>
                    <p className="deplacement-title-responsable">
                      {" "}
                      <b>Aichetou Bilal</b>
                      <br /> <span className="title-responsable">Design</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ temoignageLeading }) => ({ ...temoignageLeading }),
//     dispatch => bindActionCreators({ ...temoignageLeadingActions }, dispatch)
//   )( temoignageLeading );
