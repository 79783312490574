import React, { Component } from "react";
import "./connexion.css";
import { Link, NavLink } from "react-router-dom";
// import google from "../../img/google.png";
// import facebook from "../../img/facebook.png";
import amplitude from "amplitude-js";
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";
import axios from "axios";
import API from "../../variablesGlobales";
import Logo from "../../img/logo.png";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import FeatherIcon from "feather-icons-react";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as connexionActions from "../../store/connexion/actions";

// const clientId = 'AIzaSyBkasAG5pnbKEskjtVbOHsjfQbtoUlDk20.apps.googleusercontent.com'

export default class connexion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      token: null,
      isPending: false,
      isValidUser: true,
      userFullName: "",
      api: API.API,
      email_empty: false,
      password_empty: false,
      type: "password",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleClick = () =>
    this.setState(({ type }) => ({
      type: type === "text" ? "password" : "text",
    }));

  signup(res) {
    const googleresponse = {
      password: res.profileObj.password,
      email: res.profileObj.email,
      token: res.googleId,
      Image: res.profileObj.imageUrl,
      ProviderId: "Google",
    };

    debugger;
    axios.post(this.state.api + "user/login", googleresponse).then((result) => {
      let responseJson = result;
      sessionStorage.setItem("userData", JSON.stringify(result));
      this.props.history.push("/home");
    });
  }

  componentDidMount() {
    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    amplitude.getInstance().logEvent("visitedHomePageVisiteur");
  }
  validateLoginForm() {
    let email = this.state.email;
    let password = this.state.password;
    let isValidForm = true;
    let errors = {};
    if (!email) {
      this.setState({
        isPending: false,
      });
      isValidForm = false;
      this.setState({
        email_empty: true,
      });
      this.handleShowAndHideAlert("email");
      errors["email"] = "Le champ email est obligatoire";
    }
    if (!password) {
      this.setState({
        isPending: false,
      });
      isValidForm = false;
      this.setState({
        password_empty: true,
      });
      this.handleShowAndHideAlert("password");
      errors["password"] = "Le champ mot de passe est obligatoire";
    }
    this.setState({
      errors: errors,
    });
    return isValidForm;
  }

  doLogin = (e) => {
    e.preventDefault();
    this.setState({
      isPending: true,
    });
    if (this.validateLoginForm()) {
      const fields = {
        email: this.state.email,
        password: this.state.password,
      };

      axios
        .post(this.state.api + "user/login", fields, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          //  console.log(response);
          let errors = {};
          const user = response.data.data;
          const userStatus = response.data.data.status;
          const token = response.data.data.auth_token;
          const id = response.data.data.id;
          var eventProperties = {
            user_email: this.state.email,
            user_status: userStatus,
          };
          this.setState({
            token: token,
          });
          if (token) {
            amplitude
              .getInstance()
              .logEvent("VISITEUR_CONNEXION", eventProperties);

            window.sessionStorage.setItem("token", token);
            window.sessionStorage.setItem("user_status", userStatus);
            window.sessionStorage.setItem("user_id", id);

            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("app_data", JSON.stringify(token));
            // if (userStatus === "visiteur") {
            this.setState({
              isPending: false,
            });
            window.location = "/home";
            // }
          } else if (!token) {
            errors["auth_error"] = "Vos identifiants ne sont pas corrects.";
            amplitude
              .getInstance()
              .logEvent("VISITEUR_IDENTIFIANTS_INCORRECTS", eventProperties);

            this.setState({
              errors: errors,
              isValidUser: false,
              isPending: false,
            });
            this.handleShowAndHideAlert("credential_error");
          }
        })
        .catch((error) => {
          this.setState({
            isPending: false,
            isRequestError: true,
          });

          this.handleShowAndHideAlert("requestError");
          if (process.env.NODE_ENV !== "production") {
            return;
          }
        });
    }
  };
  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);
    amplitude.getInstance().init(api_key);
  };
  handleShowAndHideAlert(input) {
    setTimeout(() => {
      if (input === "credential_error") {
        this.setState({
          isValidUser: true,
        });
      }
      if (input === "email") {
        this.setState({
          email_empty: false,
        });
      }
      if (input === "password") {
        this.setState({
          password_empty: false,
        });
      }
      if (input === "requestError") {
        this.setState({
          isRequestError: false,
        });
      }
    }, 5000);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  responseFacebook = (response) => {
    const fields = {
      email: response.email,
      provider: response.graphDomain,
      provider_id: response.id,
      token: response.accessToken,
    };
    var eventProperties = {
      user_email: response.email,
      provider: response.graphDomain,
    };
    // console.log("facebook,", response);
    axios
      .post(this.state.api + "user/social-login", fields, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        if (response.status === 205) {
          this.setState({ notUser: true });
        }
        let errors = {};
        const user = response.data.data;
        const userStatus = response.data.data.status;
        const token = response.data.data.auth_token;

        this.setState({
          token: token,
        });
        if (token) {
          amplitude
            .getInstance()
            .logEvent("VISITEUR_CONNEXION_FACEBOOK", eventProperties);

          window.sessionStorage.setItem("token", token);
          window.sessionStorage.setItem("user_status", userStatus);

          // localStorage.setItem("user", user);
          localStorage.setItem("user", JSON.stringify(user));
          // window.sessionStorage.setItem("user", user);
          localStorage.setItem("app_data", JSON.stringify(token));
          // if (userStatus === "visiteur") {
          this.setState({
            isPending: false,
          });
          window.location = "/home";
          // }
        } else if (!token) {
          errors["auth_error"] = "Vos identifiants ne sont pas corrects.";
          amplitude
            .getInstance()
            .logEvent("VISITEUR_IDENTIFIANTS_INCORRECTS", eventProperties);

          this.setState({
            errors: errors,
            isValidUser: false,
            isPending: false,
          });
          this.handleShowAndHideAlert("credential_error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // responseGoogle = (response) => {
  //   // console.log("responseee", response.ht.St, response.ht.Re);
  //   const fields = {
  //     email: response.ht.St,
  //     provider: "google",
  //     provider_id: response.googleId,
  //     token: response.accessToken,
  //   };
  //   var eventProperties = {
  //     user_email: response.ht.St,
  //     provider: "google",
  //   };
  //   axios
  //     .post(this.state.api + "user/social-login", fields, {
  //       headers: { "Content-Type": "application/json" },
  //     })

  //     .then((res) => {
  //       console.log("ress");
  //       if (res.status === 205) {
  //         this.setState({ notUser: true });
  //       }
  //       let errors = {};
  //       const user = res.data.data;
  //       console.log("res", res);
  //       const userStatus = res.data.data.status;
  //       const token = res.data.data.auth_token;
  //       const id = res.data.data.id;

  //       this.setState({
  //         token: token,
  //       });
  //       if (token) {
  //         amplitude
  //           .getInstance()
  //           .logEvent("VISITEUR_CONNEXION_FACEBOOK", eventProperties);

  //         window.sessionStorage.setItem("token", token);
  //         window.sessionStorage.setItem("user_status", userStatus);
  //         window.sessionStorage.setItem("user_id", id);

  //         // localStorage.setItem("user", user);
  //         localStorage.setItem("user", JSON.stringify(user));
  //         // window.sessionStorage.setItem("user", user);
  //         localStorage.setItem("app_data", JSON.stringify(token));
  //         // if (userStatus === "visiteur") {
  //         this.setState({
  //           isPending: false,
  //         });
  //         window.location = "/home";
  //         // }
  //       } else if (!token) {
  //         errors["auth_error"] = "Vos identifiants ne sont pas corrects.";
  //         amplitude
  //           .getInstance()
  //           .logEvent("VISITEUR_IDENTIFIANTS_INCORRECTS", eventProperties);

  //         this.setState({
  //           errors: errors,
  //           isValidUser: false,
  //           isPending: false,
  //         });
  //         this.handleShowAndHideAlert("credential_error");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };


  render() {
    const { label } = this.props;
    // const responseGoogle = (response) => {
    //   console.log(response);
    //   var res = response.profileObj;
    //   console.log(res);
    //   debugger;
    //   this.signup(response);
    // };

    return (
      <Amplitude>
        <LogOnMount eventType="visite page d'accueil visiteur" />

        <div className="">
          <div className="row page-register">
            <div className="col-md-6 banner-connexion-page d-flex justify-content-center itm-center py-5">
              <div className="col-xl-9 col-lg-10 col-md-11 col-sm-8 text-center">
                <h1 className="titre-banner-connexion">Bakeli</h1>
                <p className="slogan-banner-connexion">
                  Training the best tech talent in Africa.
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 d-flex justify-content-center itm-center bg-white py-md-0 py-5">
              <div className="col-xl-7 col-lg-9 col-md-11 col-sm-8 d-flex flex-col">
                <img className="logo-connexion m-l-r-auto" src={Logo} alt="" />
                <form onSubmit={(e) => this.doLogin(e)}>
                  <p className="h-connexion-form">CONNECTEZ-VOUS</p>
                  {this.state.notUser && (
                    <div className="auth-error text-center">
                      Cet utilisateur n'existe pas dans la base de donnee.
                    </div>
                  )}
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text input-group-text-connexion"
                        id="basic-addon1"
                      >
                        <FeatherIcon
                          className="icon-form-connexion"
                          icon="mail"
                        />
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control input-form-connexion"
                      placeholder="Adresse Mail"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </div>
                  {this.state.email_empty && (
                    <div className="errorMsg">{this.state.errors.email}</div>
                  )}
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text input-group-text-connexion"
                        id="basic-addon1"
                      >
                        <FeatherIcon
                          className="icon-form-connexion"
                          icon="lock"
                        />
                      </span>
                    </div>
                    <input
                      type="password"
                      className="form-control input-form-connexion"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      placeholder="Mot de passe"
                      type={this.state.type}
                    />
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text input-group-text-connexion"
                        id="basic-addon1"
                        onClick={this.handleClick}
                      >
                        {this.state.type !== "text" ? (
                          <FeatherIcon icon="eye-off" />
                        ) : (
                          <FeatherIcon icon="eye" />
                        )}
                      </span>
                    </div>
                  </div>

                  {this.state.password_empty && (
                    <div className="errorMsg">{this.state.errors.password}</div>
                  )}
                  <NavLink className="mdp-forget-link" to="/reinitialiser-mdp">
                    Mot de passe oublié ?
                  </NavLink>
                  <button className="btn-connexion marge-bottom">
                    CONNEXION &nbsp;
                    {this.state.isPending && (
                      <FeatherIcon
                        icon="loader"
                        aria-hidden="true"
                        style={{ color: "#069A77" }}
                      />
                    )}
                  </button>
                  {!this.state.isValidUser && (
                    <div className="auth-error text-center">
                      {this.state.errors.auth_error}
                    </div>
                  )}
                  {this.state.isRequestError && (
                    <div className="auth-error text-center">
                      La connexion a échoué. Veuillez réessayer plus tard.
                    </div>
                  )}

                  {/* Log an event when this view mounts */}
                </form>

                {/* <div className="row d-flex pr-3">
                <div className="col-xl-5 col-lg-5 col-md-5 pb-3">
                  <hr className="bordure-hr" />
                </div>
                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-6 blok_image_logo_login pb-3">
                  <img className="logo-google-login" src={google} />
                </div>
                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-6 blok_image_logo_login pb-3">
                  <img className="logo-facebook-login" src={facebook} />
                </div>
                <div className="col-xl-5 col-lg-5 col-md-5 pl-4 pb-3">
                  <hr className="bordure-hr" />
                </div>
              </div> */}
                <p className="texte_text">
                  Vous n'avez pas de compte ? Inscrivez-vous{" "}
                  <Link to="/inscription">
                    <span className="link-connexion">ici</span>
                  </Link>
                </p>
                <br />

                {/* <p className="">
                  <span className="">
                    <GoogleLogin
                      clientId="267686537578-1dci9mr6dhni456m0haebr0a6h63e6dp.apps.googleusercontent.com"
                      buttonText="Continue avec Google"
                      className="but-goog"
                      onSuccess={this.responseGoogle}
                      onFailure={this.responseGoogle}
                      // isSignedIn={true}
                      cookiePolicy={"single_host_origin"}
                    />
                  </span>
                </p>
                <br /> */}
                {/* <p className="alignemant-icon-fb-block">
                  <span className="">
                    <FacebookLogin
                      appId="228359102285829"
                      textButton="Continue avec Facebook"
                      className="but-facb"
                      fields="name,email,picture"
                      callback={this.responseFacebook}
                      icon="fa-facebook"
                    />
                  </span>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </Amplitude>
    );
  }
}
// export default connect(
//     ({ connexion }) => ({ ...connexion }),
//     dispatch => bindActionCreators({ ...connexionActions }, dispatch)
//   )( connexion );
