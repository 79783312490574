import React, { Component } from "react";
import "./inscriptionRencontre.css";
import amplitude from "amplitude-js";
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";
import axios from "axios";
import API from "../../variablesGlobales";
import base_api_url from "../../variablesGlobales";
import Logo from "../../img/logo.png";
import JeemaLogo from "../../img/icons/jeema-logo.jpeg";
import Wmd from "../../img/wmd.jpeg";
import FeatherIcon from "feather-icons-react";
import ReactPlayer from "react-player";
import frenchShortFormatDate from "../frenchFormatDate";

// import Dialog  from '../dialogBoite/index';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as inscriptionRencontreActions from "../../store/inscriptionRencontre/actions";
export default class inscriptionRencontre extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      rencontre_id: 80,
      token: null,
      isPending: false,
      isValidUser: true,
      userFullName: "",
      api: API.API,
      base_api_url: base_api_url.base_api_url,
      flashMessage: "",
      addModificationProgress: false,
      candidaturInProgress: false,
      session_empty: false,
      canal_infos_empty: false,
      regime_empty: false,
      domaine_empty: false,
      userPhone: "",
      inscriptionSent: false,
      domaines: [],
      regime:"",
      fields: {},
      isAcceptAccount: true,
      rencontre: {},
      formatDate: frenchShortFormatDate.frenchShortFormatDate,

      // isOpen: false
    };
  }

  componentDidMount() {
    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    amplitude.getInstance().logEvent("visitedInscriptionEvenementNetwork");
    this.getDetailRencontre();
  }

  getDetailRencontre = () => {
    const event_id = this.props.match.params.id;

    axios
      .get(this.state.api + "rencontres/" + event_id)
      .then((response) => {
        this.setState({
          rencontre: response.data.data,
        });
        // console.log("event", this.state.rencontre);
      })
      .catch((error) => {
        console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
      });
  };
  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);
    amplitude.getInstance().init(api_key);
  };

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  };
  handleCreateAccount = (e) => {
    this.setState({
      isAcceptAccount: !this.state.isAcceptAccount,
    });
  };
  onValidateFormData = () => {
    let fields = this.state.fields;
    let errors = {};
    let isValidForm = true;

    if (!fields["canal_infos"]) {
      isValidForm = false;
      this.setState({
        canal_infos_empty: true,
      });
      errors["canal_infos"] = "Ce champ est obligatoire";
    }
if(this.state.rencontre.regime === "en_ligne_presentiel"){
    if (!fields["regime"]) {
      isValidForm = false;
      this.setState({
        regime_empty: true,
      });
      errors["regime"] = "Ce champ est obligatoire";
    }
  }
    this.setState({
      errors: errors,
    });

    return isValidForm;
  };

  submitCandidature = (e) => {
    e.preventDefault();
    const event_id = this.props.match.params.id;

    // console.log("zkjd",this.state.fields.regime);
    if (this.onValidateFormData()) {

      this.setState({
        candidaturInProgress: true,
      });
      var data = this.state.fields;

      var fd = new FormData();
    if(this.state.rencontre.regime === "en_ligne_presentiel"){
      fd.append("first_name", this.state.fields.first_name);
      fd.append("last_name", this.state.fields.last_name);
      fd.append("address", this.state.fields.address);
      fd.append("email", this.state.fields.email);
      fd.append("isAcceptAccount", this.state.isAcceptAccount);
      fd.append("regime", this.state.fields.regime);
      fd.append("domaine", this.state.fields.domaine);
      fd.append("phone", this.state.fields.phone);
      fd.append("canal_infos", this.state.fields.canal_infos);
      fd.append("rencontre_id", event_id);
    } else{
      fd.append("first_name", this.state.fields.first_name);
      fd.append("last_name", this.state.fields.last_name);
      fd.append("address", this.state.fields.address);
      fd.append("email", this.state.fields.email);
      fd.append("isAcceptAccount", this.state.isAcceptAccount);
      // fd.append("domaine", this.state.fields.domaine);
      fd.append("phone", this.state.fields.phone);
      fd.append("canal_infos", this.state.fields.canal_infos);
      fd.append("rencontre_id", event_id);
    }
  
      var eventProperties = {
        user_email: this.state.fields.email,
        rencontre: event_id,
        userFullname:
          this.state.fields.first_name + " " + this.state.fields.last_name,
      };
      if (this.state.isAcceptAccount === true) {
        amplitude
          .getInstance()
          .logEvent("Accept_accountCreation_network", eventProperties);
      }

      axios
        .post(this.state.api + "participants", fd)
        .then((response) => {
          if (response.data.success) {
            amplitude
              .getInstance()
              .logEvent("INSCRIPTION_EVENEMENT_SUCCESS", eventProperties);

            this.setState({
              candidaturInProgress: false,
              inscriptionSent: true,
            });
          }
        })
        .catch((error) => {
          amplitude
            .getInstance()
            .logEvent("INSCRIPTION_EVENEMENT_ERROR", eventProperties);

          this.setState({
            successfullUpdate: false,
            candidaturInProgress: false,
            inscriptionNotSent: true,
            successfullMessage:
              "Une erreur est survenue lors de l'inscription, veuillez reessayer.",
          });
        });
    }
    

  };


  submitCandidatureJeemaCoder = (e) => {
    e.preventDefault();
    const event_id = this.props.match.params.id;
    // console.log(event_id);
    if (this.onValidateFormData()) {
      this.setState({
        candidaturInProgress: true,
      });
      var data = this.state.fields;

      var fd = new FormData();

      fd.append("first_name", this.state.fields.first_name);
      fd.append("last_name", this.state.fields.last_name);
      fd.append("address", this.state.fields.address);
      fd.append("email", this.state.fields.email);
      fd.append("isAcceptAccount", this.state.isAcceptAccount);
      fd.append("domaine", this.state.fields.domaine);
      fd.append("phone", this.state.fields.phone);
      fd.append("canal_infos", this.state.fields.canal_infos);
      fd.append("rencontre_online", this.state.fields.rencontre_online);
      fd.append("rencontre_place", this.state.fields.rencontre_place);
      fd.append("rencontre_user_tools", this.state.fields.rencontre_user_tools);
      fd.append("rencontre_user_disponibility", this.state.fields.rencontre_user_disponibility);
      fd.append("rencontres_id", event_id);
      var eventProperties = {
        user_email: this.state.fields.email,
        rencontre: this.state.fields.event_id,
        userFullname:
          this.state.fields.first_name + " " + this.state.fields.last_name,
      };
      if (this.state.isAcceptAccount === true) {
        amplitude
          .getInstance()
          .logEvent("Accept_accountCreation_network", eventProperties);
      }

      axios
        .post(this.state.api + "joinRencontreForm", fd)
        .then((response) => {
          if (response.data.success) {
            amplitude
              .getInstance()
              .logEvent("INSCRIPTION_EVENEMENT_SUCCESS", eventProperties);

            this.setState({
              candidaturInProgress: false,
              inscriptionSent: true,
            });
          }
        })
        .catch((error) => {
          amplitude
            .getInstance()
            .logEvent("INSCRIPTION_EVENEMENT_ERROR", eventProperties);

          this.setState({
            successfullUpdate: false,
            candidaturInProgress: false,
            inscriptionNotSent: true,
            successfullMessage:
              "Une erreur est survenue lors de l'inscription, veuillez reessayer.",
          });
        });
    }
  };

  // submitCandidatureHackaton = (e) => {
  //   e.preventDefault();
  //   const event_id = this.props.match.params.id;
  //   // console.log(event_id);
  //   if (this.onValidateFormData()) {
  //     this.setState({
  //       candidaturInProgress: true,
  //     });
  //     var data = this.state.fields;

  //     var fd = new FormData();

  //     fd.append("first_name", this.state.fields.first_name);
  //     fd.append("last_name", this.state.fields.last_name);
  //     fd.append("address", this.state.fields.address);
  //     fd.append("email", this.state.fields.email);
  //     fd.append("isAcceptAccount", this.state.isAcceptAccount);
  //     fd.append("domaine", this.state.fields.domaine);
  //     fd.append("phone", this.state.fields.phone);
  //     fd.append("canal_infos", this.state.fields.canal_infos);
  //     // fd.append("rencontre_online", " ");
  //     // fd.append("rencontre_place", " ");
  //     // fd.append("rencontre_user_tools", this.state.fields.rencontre_user_tools);
  //     fd.append("rencontre_user_disponibility", this.state.fields.rencontre_user_disponibility);
  //     fd.append("rencontres_id", event_id);
  //     var eventProperties = {
  //       user_email: this.state.fields.email,
  //       rencontre: this.state.fields.event_id,
  //       userFullname:
  //         this.state.fields.first_name + " " + this.state.fields.last_name,
  //     };
  //     if (this.state.isAcceptAccount === true) {
  //       amplitude
  //         .getInstance()
  //         .logEvent("Accept_accountCreation_network", eventProperties);
  //     }
  //     axios
  //       .post(this.state.api + "joinRencontreForm", fd)
  //       .then((response) => {
  //         if (response.data.success) {
  //           amplitude
  //             .getInstance()
  //             .logEvent("INSCRIPTION_EVENEMENT_SUCCESS", eventProperties);

  //           this.setState({
  //             candidaturInProgress: false,
  //             inscriptionSent: true,
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         amplitude
  //           .getInstance()
  //           .logEvent("INSCRIPTION_EVENEMENT_ERROR", eventProperties);

  //         this.setState({
  //           successfullUpdate: false,
  //           candidaturInProgress: false,
  //           inscriptionNotSent: true,
  //           successfullMessage:
  //             "Une erreur est survenue lors de l'inscription, veuillez reessayer.",
  //         });
  //       });
  //   }
  // };
 
  submitCandidatureHackaton = (e) => {
    e.preventDefault();
    const event_id = this.props.match.params.id;

    // console.log("zkjd",this.state.fields.regime);
    if (this.onValidateFormData()) {

      this.setState({
        candidaturInProgress: true,
      });
      var data = this.state.fields;

      var fd = new FormData();
  
      fd.append("first_name", this.state.fields.first_name);
      fd.append("last_name", this.state.fields.last_name);
      fd.append("address", this.state.fields.address);
      fd.append("email", this.state.fields.email);
      fd.append("isAcceptAccount", this.state.isAcceptAccount);
      fd.append("domaine", this.state.fields.domaine);
      fd.append("phone", this.state.fields.phone);
      fd.append("canal_infos", this.state.fields.canal_infos);
      fd.append("rencontre_id", event_id);
      fd.append("rencontre_user_disponibility", this.state.fields.rencontre_user_disponibility);
    
  
      var eventProperties = {
        user_email: this.state.fields.email,
        rencontre: event_id,
        userFullname:
          this.state.fields.first_name + " " + this.state.fields.last_name,
      };
      if (this.state.isAcceptAccount === true) {
        amplitude
          .getInstance()
          .logEvent("Accept_accountCreation_network", eventProperties);
      }

      axios
        .post(this.state.api + "participants", fd)
        .then((response) => {
          if (response.data.success) {
            amplitude
              .getInstance()
              .logEvent("INSCRIPTION_EVENEMENT_SUCCESS", eventProperties);

            this.setState({
              candidaturInProgress: false,
              inscriptionSent: true,
            });
          }
        })
        .catch((error) => {
          amplitude
            .getInstance()
            .logEvent("INSCRIPTION_EVENEMENT_ERROR", eventProperties);

          this.setState({
            successfullUpdate: false,
            candidaturInProgress: false,
            inscriptionNotSent: true,
            successfullMessage:
              "Une erreur est survenue lors de l'inscription, veuillez reessayer.",
          });
        });
    }
    

  };
 
  render() {
    const rencontreDetail = this.state.rencontre;
    return (
      <Amplitude>
        <LogOnMount eventType="visite page d'inscription bakeli weekend" />

        <div className="">
          <div className="row page-register">
          <div
              className="col-md-7 banner-connexion-page-home d-flex
            "
            >
              
              <div className="col-xl-12 container-nav-vertical-home-jeema col-lg-10 col-md-12 col-sm-8">

                     <img  className="imgA p-l-2"
                src={`${this.state.base_api_url}/uploads/rencontre_files/${rencontreDetail?.rencontre_file}`}
                    alt="" 
                  />
                  </div>
            </div>
            <div className="col-xl-5 col-lg-6 col-md-5 d-flex justify-content-center itm-center bg-white">
              <div className="col-xl-10 col-lg-9 col-md-11 col-sm-8 d-flex flex-col">
               

                {rencontreDetail.rencontre_type === "workshop" ? (
                  <>
                    <p className="h-connexion-form mt-5">
                      Inscription au workshop sur{" "}
                      {rencontreDetail.rencontre_name}
                    </p>

                    {this.state.inscriptionSent ? (
                      <p className="successMsg">
                        Votre inscription a été prise en compte , Un e-mail de
                        confirmation vous a été envoyé. Merci
                      </p>
                    ) : (
                      <form
                        onSubmit={(e) => this.submitCandidature(e)}
                        encType="multipart/form-data"
                      >
                        <p className="p-3">
                          Les champs précédes de ce signe{" "}
                          <span style={{ color: "red" }}> * </span> sont
                          obligatoires
                        </p>
                        <div class="card btom-card-demande-candidature">
                          <div class="card-body">
                            <span className="experience-demande-candidature">
                              Informations personnelles{" "}
                              <span style={{ color: "red" }}> * </span>
                            </span>
                            <div className="row mt-3">
                              <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                                <input
                                  type="text"
                                  className="form-control control-3"
                                  placeholder="Prénom"
                                  aria-label="First name"
                                  name="first_name"
                                  onChange={(e) => this.handleChange(e)}
                                  required
                                  // value={this.state.fields["first_name"]}
                                />
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                                <input
                                  type="text"
                                  className="form-control control-3"
                                  placeholder="Nom"
                                  aria-label="Last name"
                                  name="last_name"
                                  onChange={(e) => this.handleChange(e)}
                                  required
                                  // value={this.state.fields["first_name"]}
                                />
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                                <input
                                  type="number"
                                  className="form-control control-3"
                                  placeholder="Telephone Whatsapp"
                                  aria-label="telephone"
                                  name="phone"
                                  onChange={(e) => this.handleChange(e)}
                                  required
                                  // value={this.state.fields["first_name"]}
                                />
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                                <input
                                  type="email"
                                  className="form-control control-3"
                                  placeholder="Email"
                                  aria-label="email"
                                  name="email"
                                  onChange={(e) => this.handleChange(e)}
                                  required
                                  // value={this.state.fields["first_name"]}
                                />
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                                <input
                                  type="text"
                                  className="form-control control-3"
                                  placeholder="Ville Residence"
                                  aria-label="adresse"
                                  name="address"
                                  onChange={(e) => this.handleChange(e)}
                                  required
                                  // value={this.state.fields["first_name"]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      {rencontreDetail.regime === "en_ligne_presentiel" ? (                        
                        <div class="card btom-card-demande-candidature">
                          <div class="card-body">
                            
                            <span className="experience-demande-candidature">
                              Comment voulez-vous particper à l'évènement ?
                              <span style={{ color: "red" }}> * </span>
                            </span>
                            <br />
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="regime"
                                  value="en_ligne"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                               En ligne
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="regime"
                                  value="en_presentiel"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                En présentiel
                              </label>
                            </div>
                            {/* <div class="checkbox c-checkbox">
                              <label>
                                <input 
                                  type="radio"
                                  name="regime"
                                  value="en_ligne_presentiel"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                En ligne et en présentiel
                              </label>
                            </div> */}
                            {this.state.regime_empty && (
                              <div className="errorMsg">
                                {this.state.errors.regime}
                              </div>
                            )}
                          </div>
                        </div>
                          
                      ):(
                        <> 
                         </>
                      )}
                        <div class="card btom-card-demande-candidature">
                          <div class="card-body">
                            
                            <span className="experience-demande-candidature">
                              Comment avez-vous connu l'évènement ?
                              <span style={{ color: "red" }}> * </span>
                            </span>
                            <br />
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  value="facebook"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via Facebook
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  value="whatsapp"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via Whatsapp
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  value="linkedin"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via Linkedin
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  value="instagram"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via Instagram
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  value="youtube"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via Youtube
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  value="ami"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via un ami/camarade/proche...
                              </label>
                            </div>

                            {this.state.canal_infos_empty && (
                              <div className="errorMsg">
                                {this.state.errors.canal_infos}
                              </div>
                            )}
                          </div>
                        </div>

                        <div class="card btom-card-demande-candidature">
                          <div class="card-body">
                            <span className="experience-demande-candidature">
                              A Propos{" "}
                            </span>
                            {/* <div class="checkbox c-checkbox"> */}
                            <label>
                              <input
                                type="checkbox"
                                name="isAcceptAccount"
                                value="isAcceptAccount"
                                checked={this.state.isAcceptAccount}
                                onClick={(e) => this.handleCreateAccount(e)}
                              />
                              <span class="mr-2"></span>
                              Coche cette case pour disposer officiellement d’un
                              compte dans notre réseau social éducatif, où tu
                              auras accès à l’ensemble de nos évènements et à
                              des milliers de contenus
                            </label>
                            {/* </div> */}
                            {/* <p>
                          Remplis ce formulaire si tu veux participer à cette
                          journée d'apprentissages et d'échanges avec nos
                          meilleurs coachs en développement web et mobile,
                          marketing digital, design graphique et
                          entrepreneuriat. !
                        </p> */}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            {this.state.inscriptionNotSent && (
                              <p className="errorMsg">
                                Une erreur est survenue lors de l'inscription,
                                veuillez reessayer.
                              </p>
                            )}
                            {this.state.candidaturInProgress ? (
                              <button
                                type="submit"
                                className="btn-theme-candidature text-up m-t-30 w-full p-t-15 p-b-15"
                                disabled
                              >
                                En Cours &nbsp;
                                <FeatherIcon icon="loader" aria-hidden="true" />
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn-theme-candidature text-up m-t-30 w-full p-t-15 p-b-15 mb-5"
                              >
                                S'INSCRIRE
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {rencontreDetail.rencontre_type === "jeemaCoder" ? (
                  <>
                    <p className="h-connexion-form mt-5">
                      Inscription pour le {" "}
                      {rencontreDetail.rencontre_name}
                    </p>
                    {/* {this.state.formatDate(rencontreDetail.rencontre_start_date)} */}

                    {this.state.inscriptionSent ? (
                      <p className="successMsg">
                        Votre inscription a été prise en compte , Un e-mail de
                        confirmation vous a été envoyé. Merci
                      </p>
                    ) : (
                      <form
                         onSubmit={(e) => this.submitCandidatureJeemaCoder(e)}
                        encType="multipart/form-data"
                      >
                        <p className="p-3">
                          Les champs précédes de ce signe{" "}
                          <span style={{ color: "red" }}> * </span> sont
                          obligatoires
                        </p>
                        <div class="card btom-card-demande-candidature">
                          <div class="card-body">
                            <span className="experience-demande-candidature">
                              Informations personnelles{" "}
                              <span style={{ color: "red" }}> * </span>
                            </span>
                            <div className="row mt-3">
                              <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                                <input
                                  type="text"
                                  className="form-control control-3"
                                  placeholder="Prénom"
                                  aria-label="First name"
                                  name="first_name"
                                  onChange={(e) => this.handleChange(e)}
                                  required
                                  // value={this.state.fields["first_name"]}
                                />
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                                <input
                                  type="text"
                                  className="form-control control-3"
                                  placeholder="Nom"
                                  aria-label="Last name"
                                  name="last_name"
                                  onChange={(e) => this.handleChange(e)}
                                  required
                                  // value={this.state.fields["first_name"]}
                                />
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                                <input
                                  type="number"
                                  className="form-control control-3"
                                  placeholder="Telephone Whatsapp"
                                  aria-label="telephone"
                                  name="phone"
                                  onChange={(e) => this.handleChange(e)}
                                  required
                                  // value={this.state.fields["first_name"]}
                                />
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                                <input
                                  type="email"
                                  className="form-control control-3"
                                  placeholder="Email"
                                  aria-label="email"
                                  name="email"
                                  onChange={(e) => this.handleChange(e)}
                                  required
                                  // value={this.state.fields["first_name"]}
                                />
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                                <input
                                  type="text"
                                  className="form-control control-3"
                                  placeholder="Ville Residence"
                                  aria-label="adresse"
                                  name="address"
                                  onChange={(e) => this.handleChange(e)}
                                  required
                                  // value={this.state.fields["first_name"]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="card btom-card-demande-candidature">
                          <div class="card-body">
                             <span className="experience-demande-candidature">
                              Je suis interessé(e) par la formation en{" "}
                              <span style={{ color: "red" }}> * </span>
                            </span>
                            <br />
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="domaine"
                                  value="Developpement Web"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars"> </span>
                                Developpement Web
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="domaine"
                                  value="Design"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars"></span>
                                Design
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="domaine"
                                  value="Marketing Digital"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars"></span>
                                Marketing digital
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="domaine"
                                  value="Gestion de projet"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars"></span>
                                Gestion de projet
                              </label>
                            </div> 
                            <span className="experience-demande-candidature">
                              Voulez-vous vous formez en ligne ou en présentiel?{" "}
                              <span style={{ color: "red" }}> * </span>
                            </span>
                            <br />
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="rencontre_online"
                                  id="online"
                                  value="En ligne"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars"> </span>
                                En ligne
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="rencontre_online"
                                  id="presentiel"
                                  value="En presentiel"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars"></span>
                                En présentiel
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  id="presentiel_enLigne"
                                  name="rencontre_online"
                                  value="En ligne et en présentiel"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars"></span>
                                En ligne et en pprésentiel
                              </label>
                            </div>
                            <span className="experience-demande-candidature">
                              Dans quelle ville voulez-vous participer au JeemaCoder?{" "}
                              <span style={{ color: "red" }}> * </span>
                            </span>
                            <br />
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="rencontre_place"
                                  id="place_dakar"
                                  value="Dakar"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars"> </span>
                                Dakar
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="rencontre_place"
                                  id="place_thies"
                                  value="Thies"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars"></span>
                                Thiès
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  id="place_mbour"
                                  name="rencontre_place"
                                  value="Mbour"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars"></span>
                                Mbour
                              </label>
                            </div>
                            <span className="experience-demande-candidature">
                              Qu'avez-vous à votre disposition?(Merci de prévoir de quoi noter){" "}
                              <span style={{ color: "red" }}> * </span>
                            </span>
                            <br />
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="rencontre_user_tools"
                                  id="Ordinateur"
                                  value="Ordinateur"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars"> </span>
                                Ordinateur
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="rencontre_user_tools"
                                  id="Smartphone"
                                  value="Smartphone"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars"></span>
                                Smartphone
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  id="Tablette"
                                  name="rencontre_user_tools"
                                  value="Tablette"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars"></span>
                                Tablette
                              </label>
                            </div>
                            <span className="experience-demande-candidature">
                              Seriez-vous disponible du {this.state.formatDate(rencontreDetail.rencontre_start_date)} au {this.state.formatDate(rencontreDetail.rencontre_end_date)} {" "}
                              <span style={{ color: "red" }}> * </span>
                            </span>
                            <br />
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="rencontre_user_disponibility"
                                  id="Oui"
                                  value="Oui"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars"> </span>
                                Oui
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="rencontre_user_disponibility"
                                  id="Non"
                                  value="Non"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars"></span>
                                Non
                              </label>
                            </div>
                          
                            <span className="experience-demande-candidature">
                              Comment avez-vous connu l'évènement ?
                              <span style={{ color: "red" }}> * </span>
                            </span>
                            <br />
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  value="facebook"
                                  id="facebook"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via Facebook
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  value="whatsapp"
                                  id="whatsapp"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via Whatsapp
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  value="linkedin"
                                  id="linkedin"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via Linkedin
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  id="instagram"
                                  value="instagram"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via Instagram
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  id="youtube"
                                  value="youtube"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via Youtube
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  value="ami"
                                  id="ami"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via un ami/camarade/proche...
                              </label>
                            </div>

                            {this.state.canal_infos_empty && (
                              <div className="errorMsg">
                                {this.state.errors.canal_infos}
                              </div>
                            )}
                          </div>
                      
                        </div>

                        <div class="card btom-card-demande-candidature">
                          <div class="card-body">
                            <span className="experience-demande-candidature">
                              A Propos{" "}
                            </span>
                            {/* <div class="checkbox c-checkbox"> */}
                            <label>
                              <input
                                type="checkbox"
                                name="isAcceptAccount"
                                value="isAcceptAccount"
                                checked={this.state.isAcceptAccount}
                                onClick={(e) => this.handleCreateAccount(e)}
                              />
                              <span class="mr-2"></span>
                              Coche cette case pour disposer officiellement d’un
                              compte dans notre réseau social éducatif, où tu
                              auras accès à l’ensemble de nos évènements et à
                              des milliers de contenus
                            </label>
                            {/* </div> */}
                            {/* <p>
                          Remplis ce formulaire si tu veux participer à cette
                          journée d'apprentissages et d'échanges avec nos
                          meilleurs coachs en développement web et mobile,
                          marketing digital, design graphique et
                          entrepreneuriat. !
                        </p> */}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            {this.state.inscriptionNotSent && (
                              <p className="errorMsg">
                                Une erreur est survenue lors de l'inscription,
                                veuillez reessayer.
                              </p>
                            )}
                            {this.state.candidaturInProgress ? (
                              <button
                                type="submit"
                                className="btn-theme-candidature text-up m-t-30 w-full p-t-15 p-b-15"
                                disabled
                              >
                                En Cours &nbsp;
                                <FeatherIcon icon="loader" aria-hidden="true" />
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn-theme-candidature text-up m-t-30 w-full p-t-15 p-b-15 mb-5"
                              >
                                S'INSCRIRE
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    )}
                  </>
                ) : (
                  <></>
                )}
                 {rencontreDetail.rencontre_type === "hackaton" ? (
                  <>
                    <p className="h-connexion-form mt-5">
                      Inscription pour le {" "}
                      {rencontreDetail.rencontre_name}
                    </p>
                    {/* {this.state.formatDate(rencontreDetail.rencontre_start_date)} */}

                    {this.state.inscriptionSent ? (
                      <p className="successMsg">
                        Votre inscription a été prise en compte , Un e-mail de
                        confirmation vous a été envoyé. Merci
                      </p>
                    ) : (
                      <form
                         onSubmit={(e) => this.submitCandidatureHackaton(e)}
                        encType="multipart/form-data"
                      >
                        <p className="p-3">
                          Les champs précédes de ce signe{" "}
                          <span style={{ color: "red" }}> * </span> sont
                          obligatoires
                        </p>
                        <div class="card btom-card-demande-candidature">
                          <div class="card-body">
                            <span className="experience-demande-candidature">
                              Informations personnelles{" "}
                              <span style={{ color: "red" }}> * </span>
                            </span>
                            <div className="row mt-3">
                              <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                                <input
                                  type="text"
                                  className="form-control control-3"
                                  placeholder="Prénom"
                                  aria-label="First name"
                                  name="first_name"
                                  onChange={(e) => this.handleChange(e)}
                                  required
                                  // value={this.state.fields["first_name"]}
                                />
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                                <input
                                  type="text"
                                  className="form-control control-3"
                                  placeholder="Nom"
                                  aria-label="Last name"
                                  name="last_name"
                                  onChange={(e) => this.handleChange(e)}
                                  required
                                  // value={this.state.fields["first_name"]}
                                />
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                                <input
                                  type="number"
                                  className="form-control control-3"
                                  placeholder="Telephone Whatsapp"
                                  aria-label="telephone"
                                  name="phone"
                                  onChange={(e) => this.handleChange(e)}
                                  required
                                  // value={this.state.fields["first_name"]}
                                />
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                                <input
                                  type="email"
                                  className="form-control control-3"
                                  placeholder="Email"
                                  aria-label="email"
                                  name="email"
                                  onChange={(e) => this.handleChange(e)}
                                  required
                                  // value={this.state.fields["first_name"]}
                                />
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                                <input
                                  type="text"
                                  className="form-control control-3"
                                  placeholder="Ville Residence"
                                  aria-label="adresse"
                                  name="address"
                                  onChange={(e) => this.handleChange(e)}
                                  required
                                  // value={this.state.fields["first_name"]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="card btom-card-demande-candidature">
                          <div class="card-body">
                             <span className="experience-demande-candidature">
                             Je candidate pour le poste de {" "}
                              <span style={{ color: "red" }}> * </span>
                            </span>
                            <br />
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="domaine"
                                  value="Community Manager "
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars"> </span>
                                Community Manager 
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="domaine"
                                  value="Community Manager  en TikTok"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars"></span>
                                Community Manager  en TikTok
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="domaine"
                                  value="Design Graphique"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars"></span>
                                Design Graphique
                              </label>
                            </div>
                         
                            <span className="experience-demande-candidature">
                              Seriez-vous disponible du {this.state.formatDate(rencontreDetail.rencontre_start_date)} au {this.state.formatDate(rencontreDetail.rencontre_end_date)} {" "}
                              <span style={{ color: "red" }}> * </span>
                            </span>
                            <br />
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="rencontre_user_disponibility"
                                  id="Oui"
                                  value="Oui"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars"> </span>
                                Oui
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="rencontre_user_disponibility"
                                  id="Non"
                                  value="Non"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars"></span>
                                Non
                              </label>
                            </div>
                          
                            <span className="experience-demande-candidature">
                              Comment avez-vous connu l'évènement ?
                              <span style={{ color: "red" }}> * </span>
                            </span>
                            <br />
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  value="facebook"
                                  id="facebook"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via Facebook
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  value="whatsapp"
                                  id="whatsapp"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via Whatsapp
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  value="linkedin"
                                  id="linkedin"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via Linkedin
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  id="instagram"
                                  value="instagram"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via Instagram
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  id="youtube"
                                  value="youtube"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via Youtube
                              </label>
                            </div>
                            <div class="checkbox c-checkbox">
                              <label>
                                <input
                                  type="radio"
                                  name="canal_infos"
                                  value="ami"
                                  id="ami"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                <span class="fa fa-bars mr-2"></span>
                                Via un ami/camarade/proche...
                              </label>
                            </div>

                            {this.state.canal_infos_empty && (
                              <div className="errorMsg">
                                {this.state.errors.canal_infos}
                              </div>
                            )}
                          </div>
                      
                        </div>

                        <div class="card btom-card-demande-candidature">
                          <div class="card-body">
                            <span className="experience-demande-candidature">
                              A Propos{" "}
                            </span>
                            <label>
                              <input
                                type="checkbox"
                                name="isAcceptAccount"
                                value="isAcceptAccount"
                                checked={this.state.isAcceptAccount}
                                onClick={(e) => this.handleCreateAccount(e)}
                              />
                              <span class="mr-2"></span>
                              Dans le but de faciliter l’insertion professionnelle des jeunes dans les nouvelles technologies, Bakeli lance un nouveau défi : BAKELI CHALLENGE. Cet événement est l’opportunité pour les jeunes de montrer leurs talents en travaillant sur des projets concrets et en utilisant les dernières tendances et technologies afin de trouver facilement de l’emploi.Coche cette case pour disposer officiellement d’un
                              compte dans notre réseau social éducatif, où tu
                              auras accès à l’ensemble de nos évènements et à
                              des milliers de contenus.
                            </label>
                            
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            {this.state.inscriptionNotSent && (
                              <p className="errorMsg">
                                Une erreur est survenue lors de l'inscription,
                                veuillez reessayer.
                              </p>
                            )}
                            {this.state.candidaturInProgress ? (
                              <button
                                type="submit"
                                className="btn-theme-candidature text-up m-t-30 w-full p-t-15 p-b-15"
                                disabled
                              >
                                En Cours &nbsp;
                                <FeatherIcon icon="loader" aria-hidden="true" />
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn-theme-candidature text-up m-t-30 w-full p-t-15 p-b-15 mb-5"
                              >
                                S'INSCRIRE
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </Amplitude>
    );
  }
}
// export default connect(
//     ({ inscriptionRencontre }) => ({ ...inscriptionRencontre }),
//     dispatch => bindActionCreators({ ...inscriptionRencontreActions }, dispatch)
//   )( inscriptionRencontre );
