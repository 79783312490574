import React, { Component } from "react";
import banner1 from "../../img/banner1.jpg";
import volkeno from "../../img/volkeno.jpg";
import volkeno1 from "../../img/volkeno1.jpg";
import Gallery from "react-grid-gallery";
import Button from "../button/button";
import Adresse from "../adresse/adresse";
import Webinar from "../webinar/webinar";
import CardStage from "../cardStage/cardStage";
import CardRentree from "../cardRentree/cardRentree";
import ContentModal from "../contentModal/contentModal";
import "./contentPhoto.css";
import { Link } from "react-router-dom";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentPhotoActions from "../../store/contentPhoto/actions";
export default class contentPhoto extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      <div className="p-b-200">
        <div className="row page-event">
          <div className="col-xl-9 col-lg-9 page-annonce">
            <div className="row m-b-15">
              <div className="col m-b-15">
                {/* <h6 className="h-theme ff-inter fw-600">Photo</h6>{" "} */}
              
                <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item h-theme ff-inter fw-600">
                  <Link to="/home" className="lien_nav">
                    {" "}
                    Accueil
                  </Link>
                </li>
                <li className="breadcrumb-item h-theme ff-inter fw-600">
                  <Link to="/mes-evenements" className="lien_nav">
                    {" "}
                    Liste des mes évènements
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                Photos
                </li>
              </ol>
            </nav>
              </div>
              <div className="col dis-flex m-b-15"></div>
            </div>

            <div className="component-content-photo">
              <Gallery
                images={IMAGES}
                // enableLightbox={true}
                maxRows={3}
                backdropClosesModal
                currentImage={3}
                // isOpen={ true}
                className="image-responsive"
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 py-4">
            <div className="row py-5 pr-5">
              <div className="col-sm-12 width_card">
                <Button />
              </div>
              <div className="col-sm-12 width_card">
                <Adresse />
              </div>
              {/* <div className="col-sm-12 width_card">
                <Webinar />
              </div>
              <div className="col-sm-12 width_card">
                <CardRentree />
              </div>
              <div className="col-sm-12 width_card">
                <CardStage />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const IMAGES = [
  {
    src: `${banner1}`,
    thumbnail: `${banner1}`,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: "volkeno SARL",
    tags: [
      { value: "volkeno SARL", title: "volkeno" },
      { value: "BAKELI School", title: "volkeno" },
    ],
  },
  {
    src: `${volkeno}`,
    thumbnail: `${volkeno}`,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: "Bakeli School of Technology",
    tags: [
      { value: "Bakeli School", title: "Bakeli" },
      { value: "Learn to Create", title: "Bakeli" },
    ],
  },

  {
    src: `${volkeno1}`,
    thumbnail: `${volkeno1}`,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: "Abdou Khadre Diallo",
    tags: [
      { value: "Abdou Khadre Diallo", title: "Bakeli" },
      { value: "CEO at Volkeno", title: "Volkeno" },

    ],
  },
  {
    src: `${volkeno1}`,
    thumbnail: `${volkeno1}`,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: "Abdou Khadre Diallo",
    tags: [
      { value: "Abdou Khadre Diallo", title: "Bakeli" },
      { value: "CEO at Volkeno", title: "Volkeno" },
    ],
  },
  {
    src: `${banner1}`,
    thumbnail: `${banner1}`,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: "volkeno SARL",
    tags: [
      { value: "JeemaCoder", title: "Bakeli School" },
    ],
  },
  {
    src: `${volkeno}`,
    thumbnail: `${volkeno}`,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: "Bakeli School of Technology",
    tags: [
      { value: "Learn to Create", title: "Bakeli" },
      { value: "Bakeli School", title: "Bakeli" },
    ],
  },
  {
    src: `${volkeno}`,
    thumbnail: `${volkeno}`,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: "volkeno SARL",
    tags: [
      { value: "Bakeli School", title: "Bakeli" },
      { value: "Learn to Create", title: "Bakeli" },

    ],
  },
  {
    src: `${volkeno1}`,
    thumbnail: `${volkeno1}`,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: "Abdou Khadre Diallo",
    tags: [
      { value: "Abdou Khadre Diallo", title: "Bakeli" },
      { value: "CEO at Volkeno", title: "Bakeli" },
    ],
  },
  {
    src: `${banner1}`,
    thumbnail: `${banner1}`,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: "volkeno SARL",
    tags: [
      { value: "JeemaCoder", title: "Bakeli" },
    ],
  },
];

// export default connect(
//     ({ contentPhoto }) => ({ ...contentPhoto }),
//     dispatch => bindActionCreators({ ...contentPhotoActions }, dispatch)
//   )( contentPhoto );
