import React, { Component } from "react";
import "./dashboardBakeliste.css";
import ReglementInterieur from "../ri/index";
import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
// import StatisticsBakeliste from "../statisticsBakeliste/index";
import ModalContactUs from "../modalContactUs/index";

export default class dashboardBakeliste extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="p-b-200">
        <div className="row">
          <div className="col-lg-9">
            <div className="row m-b-15">
              <div className="col m-b-15">
                <h6 className="h-theme ff-inter fw-600">Bakeli SI Dashboard</h6>
              </div>
              <div className="col dis-flex m-b-15">
                <ModalContactUs />
              </div>
            </div>
            {/* <StatisticsBakeliste /> */}
            <ReglementInterieur />
          </div>
          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
                <CoachItemDashboardBakeliste />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                {/* <BakelisteGroupItem /> */}
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-2 dis-flex"></div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
                {/* <SavedReunionListItem /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ dashboardBakeliste }) => ({ ...dashboardBakeliste }),
//     dispatch => bindActionCreators({ ...dashboardBakelisteActions }, dispatch)
//   )( dashboardBakeliste );
