import React, {Component} from 'react';
import './mesEvenements.css';
import * as Icon from "react-feather";
import rectFolder from "../../img/rectFolder.png";
import { Link } from "react-router-dom";
import ContentModal from "../contentModal/contentModal";
import Webinar from "../webinar/webinar";
import CardRentree from "../cardRentree/cardRentree";
import CardStage from "../cardStage/cardStage";
import Content from "../content/content";
import ModalContactUs from "../modalContactUs/index";
import API from "../../variablesGlobales";
import axios from "axios";
import Button from "../button/button";
import Adresse from "../adresse/adresse";
// import { connect } from "react-redux";
import ContentEventRecent from "../contentEventRecent/contentEventRecent";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
// import { bindActionCreators } from "redux";
// import * as mesEvenementsActions from "../../store/mesEvenements/actions";
export default class mesEvenements extends Component {
    constructor(props) {
      var retrievedObject = localStorage.getItem("user");
         super(props);
        this.state = {
          api: API.API,
          loading: true,
          user: JSON.parse(retrievedObject),
          myEvents: [],
          result: [],
          
        };
        // console.log(this.state.myEvents, "muuuuu")
    }
    componentDidMount() { 
      this.getEvents()
    }
    getEvents = () => {
     const  user_id = this.state.user.id
      axios
        .get(this.state.api + "participants_events/" + user_id)
        .then((response) => {
          // console.log("response",response)
        // console.log("mes evenement",response.data.rencontres)

          
        this.setState({
          // result: response.data,
          myEvents:response.data.rencontres,
          loading: false
        });
        // console.log("mes evenement",this.state.result.rencontres)
      })
      .catch((error) => {
        console.log(error);
      });
    };
  
    render() {

// this.state.myEvents = this.state.result.rencontres;
// console.log(this.state.myEvents,"eeeee")
      return(
    
  <div className="p-b-200">
  <div className="row">
    <div className="col-lg-9">
      <div className="row m-b-15">
        <div className="col m-b-15">
      
          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item h-theme ff-inter fw-600">
                  <Link to="/home" className="lien_nav">
                    {" "}
                    Accueil
                  </Link>
                </li>
             
                <li className="breadcrumb-item active" aria-current="page">
                Liste de mes évènements
                </li>
              </ol>
            </nav>
          {/* <h5 className="h-theme ff-inter fw-600">Candidature</h5> */}
          
        </div>
        <div className="col dis-flex m-b-15">
          <ModalContactUs />
        </div>
     

     </div>
     
     <div className=" m-b-15 p-b-60">
     {this.state.myEvents.length > 0 ? (
            this.state.myEvents.map((event, index) => (
              event === null ?(
                <></>
                ):(

                
              <ContentEventRecent
              name={event.rencontre_name}
              description={event.rencontre_description}
              date={event.rencontre_start_date}
              year={event.posted_since_year}
              month={event.posted_since_month}
              day={event.posted_since_day}
              hour={event.posted_since_hour}
            />
             ) ))
          ) : (
            <p>Vous n'avez pas encore d'événements</p>
     )} 
          
            {this.state.loading && 
            <>             
              <Skeleton animation="wave" height={220} width="70%" variant="rect"  />
            </>
          }
         {this.state.loading && 
            <>             
              <Skeleton animation="wave" height={50} width="30%" variant="rect"  
              style={{ paddingLeft: 10 }}/>
              
            </>
           
          }
        </div>
     

    </div>
    <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
      <div className="row">
        <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
          <Button />
        </div>
        <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
          <Adresse />
        </div>
        {/* <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-2 dis-flex">
          <Webinar />
        </div>
        <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
          <CardRentree />
        </div>
        <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
          <CardStage />
        </div> */}
      </div>
    </div>
  </div>
</div>

);
    }
  }
// export default connect(
//     ({ mesEvenements }) => ({ ...mesEvenements }),
//     dispatch => bindActionCreators({ ...mesEvenementsActions }, dispatch)
//   )( mesEvenements );