import React, { Component } from "react";
import "./inscriptionMasterclass.css";
import amplitude from "amplitude-js";
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";
import axios from "axios";
import API from "../../variablesGlobales";
import Logo from "../../img/logo.png";
import FeatherIcon from "feather-icons-react";
import ReactPlayer from "react-player";
import { NavLink } from "react-router-dom";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as candidatureFormActions from "../../store/candidatureForm/actions";
export default class inscriptionMasterclass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      token: null,
      isPending: false,
      isValidUser: true,
      userFullName: "",
      api: API.API,
      flashMessage: "",
      addModificationProgress: false,
      candidaturInProgress: false,
      domaine_empty: false,
      userPhone: "",
      inscriptionSent: false,
      domaines: [],
      remaining_char: 245,
      fields: {},
      input: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitCandidature = this.submitCandidature.bind(this);
  }

  componentDidMount() {
    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    amplitude.getInstance().logEvent("visitedMasterClassInscriptionVisiteur");
  }
  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);
    amplitude.getInstance().init(api_key);
  };

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;

    let input = this.state.input;
    input[e.target.name] = e.target.value;

    var chars = e.target.value.length;
    var remaining_char = 245 - chars;
    this.setState({
      fields,
      remaining_char: remaining_char,
      input,
    });
  };
  onValidateFormData = () => {
    let fields = this.state.fields;
    // console.log(fields);
    let errors = {};
    let isValidForm = true;
    let input = this.state.input;

    // if (!fields["domaine"]) {
    //   isValidForm = false;
    //   this.setState({
    //     domaine_empty: true,
    //   });
    //   errors["domaine_empty"] = "Ce champ est obligatoire";
    // }
    if (!fields["objectifs"]) {
      isValidForm = false;
      this.setState({
        motivation_empty: true,
      });
      errors["motivation_empty"] = "Ce champ est obligatoire";
    }

    // if (!fields["phone"]) {
    //   isValidForm = false;
    //   this.setState({
    //     phone_whatsapp_empty: true,
    //   });
    //   errors["phone"] = "s'il vous plait entrez votre numéro de téléphone";
    // }
    // if (!input["phone"]) {
    //   isValidForm = false;
    //   errors["phone"] = "s'il vous plait entrez votre numéro de téléphone";
    // }
    // if (typeof input["phone"] !== "undefined") {
    //   var pattern = new RegExp(/^[0-9\b]+$/);
    //   if (!pattern.test(input["phone"])) {
    //     isValidForm = false;
    //     errors["phone"] = "Veuillez saisir un numéro.";
    //   } else if (input["phone"].length != 10) {
    //     isValidForm = false;
    //     errors["phone"] =
    //       "S'il vous plait entrez un numéro de téléphone valide.";
    //   }
    // }
    if (!fields["phone"]) {
      isValidForm = false;
      this.setState({
        phone_empty: true,
      });
      errors["phone_empty"] = "Ce champ est obligatoire";
    }

    this.setState({
      errors: errors,
    });
    console.log(isValidForm);

    return isValidForm;
  };

  submitCandidature = (e) => {
    e.preventDefault();
    if (this.onValidateFormData()) {
      let input = {};
      input["phone"] = "";

      this.setState({
        candidaturInProgress: true,
        input: input,
      });
      var data = this.state.fields;

      var fd = new FormData();
      const week = "03 AOut 2021";

      fd.append("first_name", this.state.fields.first_name);
      fd.append("last_name", this.state.fields.last_name);
      fd.append("address", this.state.fields.address);
      fd.append("email", this.state.fields.email);
      fd.append("domaine", "Marketing Digital");
      fd.append("phone", this.state.fields.phone);
      fd.append("objectifs", this.state.fields.objectifs);
      fd.append("is_pro", this.state.fields.is_pro);
      fd.append("is_week", 1);
      fd.append("session", "MasterClass Marketing Digital");

      // const weekend = " MasterClass Weekend";

      // if (this.state.fields.is_week === "1") {
      //   fd.append("session", week);
      // } else {
      //   fd.append("session", weekend);
      // }
      var eventProperties = {
        user_email: this.state.fields.email,
        is_week: this.state.fields.is_week,
        userFullname:
          this.state.fields.first_name + " " + this.state.fields.last_name,
      };
      axios
        .post(this.state.api + "store-inscription-prospect", fd)
        .then((response) => {
          console.log("ressss", response);
          if (response.data.success) {
            amplitude
              .getInstance()
              .logEvent("INSCRIPTION_BAKELI_SUCCESS", eventProperties);

            this.setState({
              candidaturInProgress: false,
              inscriptionSent: true,
            });
          }
        })
        .catch((error) => {
          amplitude
            .getInstance()
            .logEvent("INSCRIPTION_BAKELI_ERROR", eventProperties);

          this.setState({
            successfullUpdate: false,
            candidaturInProgress: false,
            inscriptionNotSent: true,
            successfullMessage:
              "Une erreur est survenue lors de l'inscription, veuillez reessayer.",
          });
        });
    }
  };
  render() {
    return (
      <Amplitude>
        <LogOnMount eventType="visite page d'inscription bakeli weekend" />

        <div className="">
          <div className="row page-register">
            <div
              className="col-md-6 banner-connexion-page-home  d-flex justify-content-center
            "
            >
              <div className="col-xl-12 container-nav-vertical-home masterclass col-lg-10 col-md-11 col-sm-8 text-center pt-5">
                {/* <h1 className="titre-banner-connexion">Bakeli</h1>
                <p className="slogan-banner-connexion">
                  Training the best tech talent in Africa.
                </p> */}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 d-flex justify-content-center itm-center bg-white">
              <div className="col-xl-10 col-lg-9 col-md-11 col-sm-8 d-flex flex-col">
                <img
                  className="logo-bakeli m-l-r-auto mt-5"
                  src={Logo}
                  alt=""
                />
                <p className="h-connexion-form">
                  INSCRIPTION MASTERCLASS MARKETING DIGITAL
                </p>{" "}
                {/* <NavLink to="/">
                  <button className="btn-green">
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="book-open"
                    />
                    Explorer
                  </button>
                </NavLink> */}
                {this.state.inscriptionSent ? (
                  <p className="successMsg">
                    Votre inscription a été prise en compte , Un e-mail de
                    confirmation vous a été envoyé. Merci
                  </p>
                ) : (
                  <form
                    onSubmit={(e) => this.submitCandidature(e)}
                    encType="multipart/form-data"
                  >
                    <p className="p-3">
                      Les champs précédes de ce signe{" "}
                      <span style={{ color: "red" }}> * </span> sont
                      obligatoires
                    </p>
                    <div class="card btom-card-demande-candidature">
                      <div class="card-body">
                        <span className="experience-demande-candidature">
                          Informations personnelles{" "}
                          <span style={{ color: "red" }}> * </span>
                        </span>
                        <div className="row mt-3">
                          <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                            <input
                              type="text"
                              className="form-control control-3"
                              placeholder="Prénom"
                              aria-label="First name"
                              name="first_name"
                              onChange={(e) => this.handleChange(e)}
                              required
                              // value={this.state.fields["first_name"]}
                            />
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                            <input
                              type="text"
                              className="form-control control-3"
                              placeholder="Nom"
                              aria-label="Last name"
                              name="last_name"
                              onChange={(e) => this.handleChange(e)}
                              required
                              // value={this.state.fields["first_name"]}
                            />
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                            <input
                              type="text"
                              className="form-control control-3"
                              placeholder="Telephone"
                              aria-label="telephone"
                              name="phone"
                              onChange={(e) => this.handleChange(e)}
                              required
                              value={this.state.input.phone}
                              // value={this.state.fields["first_name"]}
                            />

                            {/* <div className="text-danger">
                              {this.state.errors.phone}
                            </div> */}
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                            <input
                              type="email"
                              className="form-control control-3"
                              placeholder="Email"
                              aria-label="email"
                              name="email"
                              onChange={(e) => this.handleChange(e)}
                              required
                              // value={this.state.fields["first_name"]}
                            />
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                            <input
                              type="text"
                              className="form-control control-3"
                              placeholder="Ville Residence"
                              aria-label="adresse"
                              name="address"
                              onChange={(e) => this.handleChange(e)}
                              required
                              // value={this.state.fields["first_name"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card btom-card-demande-candidature">
                      <div class="card-body">
                        {/* <span className="experience-demande-candidature">
                          Formations à Bakeli{" "}
                          <span style={{ color: "red" }}> * </span>
                        </span>
                        <br />
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="domaine"
                              value="Développement web"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Developpement Web/Mobile
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="domaine"
                              value="Marketing Digital"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Marketing digital
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="domaine"
                              value="Design"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Design
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="domaine"
                              value="Entrepreneuriat"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Entrepreneuriat
                          </label>
                        </div>
                        {this.state.domaine_empty && (
                          <div className="errorMsg">
                            {this.state.errors.domaine_empty}
                          </div>
                        )}

                        <span className="experience-demande-candidature">
                          Je m'inscris pour{" "}
                          <span style={{ color: "red" }}> * </span>
                        </span>
                        <br />
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="is_week"
                              value="1"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            La rentree du 03 Aout
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="is_week"
                              value="0"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            MasterClass Weekend
                          </label>
                        </div>
*/}
                        <span className="experience-demande-candidature">
                          Vous etes <span style={{ color: "red" }}> * </span>
                        </span>
                        <br />
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="is_pro"
                              value="1"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Professionnel
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="is_pro"
                              value="0"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Etudiant
                          </label>
                        </div>

                        {this.state.domaine_empty && (
                          <div className="errorMsg">
                            {this.state.errors.domaine_empty}
                          </div>
                        )}
                      </div>
                    </div>
                    <div class="card btom-card-demande-candidature">
                      <div class="card-body">
                        <span className="experience-demande-candidature">
                          <span style={{ color: "red" }}> * </span>Motivations
                        </span>
                        <p>
                          <textarea
                            placeholder="Mettez vos motivations ici"
                            onChange={(e) => this.handleChange(e)}
                            name="objectifs"
                            type="text"
                            className="motivation-candidature"
                            aria-label="motivation"
                          />
                          {this.state.remaining_char >= 31 && (
                            <span className="restant_container_sup_30">
                              {" "}
                              {this.state.remaining_char} caractéres restants
                            </span>
                          )}
                          {this.state.remaining_char <= 30 &&
                            this.state.remaining_char > 1 && (
                              <span className="restant_container_inf_30">
                                {" "}
                                {this.state.remaining_char} caractéres restants
                              </span>
                            )}
                          {this.state.remaining_char <= 1 && (
                            <span className="restant_container_inf_30">
                              {" "}
                              {this.state.remaining_char} caractére restant
                            </span>
                          )}
                          {this.state.motivation_empty && (
                            <div className="errorMsg">
                              {this.state.errors.motivation_empty}
                            </div>
                          )}{" "}
                        </p>
                      </div>
                    </div>

                    <div class="card btom-card-demande-candidature">
                      <div class="card-body">
                        <span className="experience-demande-candidature">
                          A Propos{" "}
                        </span>
                        <p>
                          Bakeli school of technology vous informe de sa
                          prochaine MasterClass . Vous étiez nombreux à nous
                          demander les MasterClass, désormais Bakeli vous offre
                          l’occasion de vous former en un temps record. Une
                          formation qui vous permet de vous initier, vous
                          perfectionner ou vous spécialiser rapidement… quel que
                          soit votre besoin de formation, nous avons la solution
                          !
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        {this.state.inscriptionNotSent && (
                          <p className="errorMsg">
                            Une erreur est survenue lors de l'inscription,
                            veuillez reessayer.
                          </p>
                        )}
                        {this.state.candidaturInProgress ? (
                          <button
                            type="submit"
                            className="btn-theme-candidature text-up m-t-30 w-full p-t-15 p-b-15"
                            disabled
                          >
                            En Cours &nbsp;
                            <FeatherIcon icon="loader" aria-hidden="true" />
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn-theme-candidature text-up m-t-30 w-full p-t-15 p-b-15 mb-5"
                          >
                            REJOINDRE L'AVENTURE
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </Amplitude>
    );
  }
}
// export default connect(
//     ({ candidatureForm }) => ({ ...candidatureForm }),
//     dispatch => bindActionCreators({ ...candidatureFormActions }, dispatch)
//   )( candidatureForm );
