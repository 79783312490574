import React, { Component } from "react";
import * as Icon from "react-feather";
import Ellipse from "../../img/Ellipse.png";
import User from "../../img/nouser.png";
import bureautique from "../../img/bureautique.png";
import "./demandeCandidature.css";
import { Link } from "react-router-dom";
import Button from "../button/button";
import Adresse from "../adresse/adresse";
import FeatherIcon from "feather-icons-react";
import ContentModal from "../contentModal/contentModal";
import Webinar from "../webinar/webinar";
import CardRentree from "../cardRentree/cardRentree";
import CardStage from "../cardStage/cardStage";
import API from "../../variablesGlobales";
import axios from "axios";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentActions from "../../store/content/actions";
export default class Demande extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = localStorage.getItem("user");
    this.state = {
      user: JSON.parse(retrievedObject),
      flashMessage: "",
      addModificationProgress: false,
      candidaturInProgress: false,
      api: API.API,
      userPhone: "",
      domaines: [],
      remaining_char: 245,
      // domaine_id: "",
      // phone_whatsapp:"",
      // motivation:"",
      // date_naissance:"",
      // type_formation:"",
      // lieu_formation:"",
      fields: {},
    };
  }

  componentDidMount() {
    // console.log(this.state.user.id)
    this.setState({
      userPhone: this.state.user.phone,
    });
    this.getAllDomaines();
  }
  onSwitchAlertStatus = (label) => {
    setTimeout(() => {
      if (label === "domaine_id_empty") {
        this.setState({
          domaine_id_empty: false,
        });
      }
      if (label === "date_naissance_empty") {
        this.setState({
          date_naissance_empty: false,
        });
      }
      if (label === "motivation_empty") {
        this.setState({
          motivation_empty: false,
        });
      }
      if (label === "slevel_empty") {
        this.setState({
          slevel_empty: false,
        });
      }
      if (label === "phone_whatsapp_empty") {
        this.setState({
          phone_whatsapp_empty: false,
        });
      }
      if (label === "type_formation_empty") {
        this.setState({
          type_formation_empty: false,
        });
      }
      if (label === "duree_formation_empty") {
        this.setState({
          duree_formation_empty: false,
        });
      }
      if (label === "lieu_formation_empty") {
        this.setState({
          lieu_formation_empty: false,
        });
      }
    }, 5000);
  };

  updateProfile = (e) => {
    e.preventDefault();
    this.setState({
      addModificationProgress: true,
    });
    var fd = new FormData();

    fd.append("first_name", this.state.user.first_name);
    fd.append("last_name", this.state.user.last_name);
    fd.append("phone", this.state.userPhone);
    fd.append("address", this.state.user.address);
    fd.append("email", this.state.user.email);
    const user_id = this.state.user.id;
    axios
      .put(this.state.api + "editer-mon-profil/" + user_id, fd)
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          this.setState({
            user: response.data.data,
            addModificationProgress: false,
          });
          console.log("user1", this.state.user);
          this.updateFormData(response.data.data);
        }
        alert("modification réussi");
      })
      .catch((error) => {
        this.setState({
          successfullUpdate: false,
          successfullMessage:
            "Une erreur est survenue lors de la mise à jour de votre profil.",
        });
      });
  };

  onValidateFormData = () => {
    let fields = this.state.fields;
    // console.log(fields);
    let errors = {};
    let isValidForm = true;

    if (!fields["date_naissance"]) {
      isValidForm = false;
      this.setState({
        date_naissance_empty: true,
      });
      this.onSwitchAlertStatus("date_naissance_empty");
      errors["date_naissance_empty"] = "Ce champ est obligatoire";
    }
    if (!fields["motivation"]) {
      isValidForm = false;
      this.setState({
        motivation_empty: true,
      });
      this.onSwitchAlertStatus("motivation_empty");
      errors["motivation_empty"] = "Ce champ est obligatoire";
    }
    if (!fields["phone_whatsapp"]) {
      isValidForm = false;
      this.setState({
        phone_whatsapp_empty: true,
      });
      this.onSwitchAlertStatus("phone_whatsapp_empty");
      errors["phone_whatsapp_empty"] = "Ce champ est obligatoire";
    }
    if (!fields["domaine_id"]) {
      isValidForm = false;
      this.setState({
        domaine_id_empty: true,
      });
      this.onSwitchAlertStatus("domaine_id_empty");
      errors["domaine_id_empty"] = "Ce champ est obligatoire";
    }
    if (!fields["type_formation"]) {
      isValidForm = false;
      this.setState({
        type_formation_empty: true,
      });
      this.onSwitchAlertStatus("type_formation_empty");
      errors["type_formation_empty"] = "Ce champ est obligatoire";
    }
    if (!fields["duree_formation"]) {
      isValidForm = false;
      this.setState({
        duree_formation_empty: true,
      });
      this.onSwitchAlertStatus("duree_formation_empty");
      errors["duree_formation_empty"] = "Ce champ est obligatoire";
    }
    if (!fields["lieu_formation"]) {
      isValidForm = false;
      this.setState({
        lieu_formation_empty: true,
      });
      this.onSwitchAlertStatus("lieu_formation_empty");
      errors["lieu_formation_empty"] = "Ce champ est obligatoire";
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  };

  submitCandidature = (e) => {
    e.preventDefault();
    if (this.onValidateFormData()) {
      this.setState({
        candidaturInProgress: true,
      });
      this.state.fields["user_id"] = this.state.user.id;
      var data = this.state.fields;

      // var files = this.state.files;
      var fd = new FormData();
      console.log("fields", this.state.fields);
      fd.append("first_name", this.state.user.first_name);
      fd.append("last_name", this.state.user.last_name);
      fd.append("address", this.state.user.address);
      fd.append("email", this.state.user.email);
      fd.append("user_id", this.state.user.id);
      axios
        .post(this.state.api + "candidatures", data)
        .then((response) => {
          console.log("res", response);
          if (response.data.success) {
            this.setState({
              candidaturInProgress: false,
            });
            this.updateFormData(response.data.data);
          }
          window.location = "/candidature";
        })
        .catch((error) => {
          this.setState({
            successfullUpdate: false,
            successfullMessage:
              "Une erreur est survenue lors de la mise à jour de votre profil.",
          });
        });
    }
  };

  getAllDomaines = () => {
    axios
      .get(this.state.api + "domaines")
      .then((res) => {
        const domains = res.data;
        this.setState({
          domaines: domains.data,
        });
      })
      .catch((error) => {
        console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
      });
  };

  updateFormData = (data) => {
    this.setState({ user: data });
  };

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    var chars = e.target.value.length;
    var remaining_char = 245 - chars;
    this.setState({
      fields,
      remaining_char: remaining_char,
    });
  };

  handlefileChange = (e) => {
    if (this.fileValidation("avatar_file")) {
      this.setState({
        //  userAvatar: e.target.files[0]
      });
    } else {
      this.setState({
        isInvalidFileExtension: true,
      });
      setTimeout(() => {
        this.setState({
          isInvalidFileExtension: false,
        });
      }, 6000);
    }
  };

  fileValidation = (elementID) => {
    var fileInput = document.getElementById(elementID);
    var filePath = fileInput.value;

    // Allowing file type
    var allowedExtensions = /(\.png|\.jpg|\.jpeg|)$/i;

    if (!allowedExtensions.exec(filePath)) {
      fileInput.value = "";
      return false;
    } else {
      return true;
    }
  };

  render() {
    return (
      <div className="p-b-200">
        <div className="row">
          <div className="col-lg-9">
            <div className="row m-b-15">
              <div className="col m-b-15">
                <h6 className="h-theme ff-inter fw-600">Demande d'admission</h6>{" "}
              </div>
              <div className="col dis-flex m-b-15"></div>
            </div>
            <div className="m-b-15 p-b-60">
              <div className="component-Demande-Candidature">
                {/* <h3 className="article my-3 py-1">Demande d'admission</h3> */}
                <div className="card my-1 mt-2 demande-candidature">
                  <div className="card-body">
                    <div class="card btom-card-demande-candidature">
                      <div class="card-body images-aita-card-center">
                        <img src={User} />
                        <h2>
                          {this.state.user.first_name}{" "}
                          {this.state.user.last_name}
                        </h2>
                        <span>Etudiante</span>
                      </div>
                    </div>
                    <form
                      onSubmit={(e) => this.submitCandidature(e)}
                      encType="multipart/form-data"
                    >
                      <h6>Les champs précédés de ce signe <span style={{ color: "red", fontSize: "15px", fontWeight: "bold" }}> * </span> sont obligatoires</h6>
                      <div class="card btom-card-demande-candidature">
                        <div class="card-body">
                          <span className="experience-demande-candidature">
                            Informations personnelles
                          </span>

                          <div className="row experience">
                            <div className="col-md-4">
                              <div>
                                <label className="label">
                                  Date de naissance
                                </label>
                                <input
                                  onChange={(e) => this.handleChange(e)}
                                  value={this.state.fields["date_naissance"]}
                                  name="date_naissance"
                                  type="date"
                                  className="form"
                                  aria-label="date_naissance"
                                />
                              </div>
                              <div>
                                <label className="label">WhatsApp</label>
                                <input
                                  name="phone_whatsapp"
                                  type="text"
                                  className="form"
                                  aria-label="phone_whatsapp"
                                  placeholder="Numéro whatsapp"
                                  // value={this.state.phone_whatsapp}
                                  value={this.state.fields["phone_whatsapp"]}
                                  onChange={(e) => this.handleChange(e)}
                                />
                                {this.state.phone_whatsapp_empty && (
                                  <div className="errorMsg">
                                    {this.state.errors.phone_whatsapp_empty}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div>
                                <label className="label">Téléphone</label>{" "}
                                <br />
                                {/* <input 
                                onChange={(e)=>
                                  this.handleChange(e)
                                }
                                value={this.state.userPhone} 
                                name="phone" 
                                type="number" 
                                className="form" aria-label="phone" /> */}
                                <label className="form">
                                  {this.state.userPhone}{" "}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div>
                                <label className="label">Email</label> <br />
                                <label className="form">
                                  {this.state.user.email}
                                </label>
                              </div>
                              <div>
                                <label className="label">Adresse</label>
                                <br />
                                <label className="form">
                                  {this.state.user.address}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div class="card btom-card-demande-candidature">
                      <div class="card-body">
                        <span className="experience-demande-candidature">
                          Expériences professionnelles
                        </span>
                        <table class="table table-borderless">
                          <thead>
                            <tr>
                              <td scope="col">Date de naissance</td>
                              <td scope="col">Telephone</td>
                              <td scope="col">Adresse mail</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>19/03/1995</th>
                              <th>770000000</th>
                              <th>{this.state.user.email}</th>
                            </tr>
                            <tr>
                              <td>Adresse</td>
                              <td>WhatsApp</td>
                              <td>N° carte d'identité</td>
                            </tr>
                            <tr>
                              <th>{this.state.user.address}</th>
                              <th>77000000</th>
                              <th>24588456874613</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div> */}
                      {/* 
                    <div class="card btom-card-demande-candidature">
                      <div class="card-body">
                        <span className="experience-demande-candidature">
                          Dernier formation suivie
                        </span>
                         
                        <div className="row experience">
                          <div className="col-md-4">
                            <div>
                              <label className="label">Status</label>
                              <input onChange={(e) => this.handleChange(e)}  value={this.state.user.email} name="email" type="text" className="form" aria-label="email" />
                            </div>
                            <div>
                              <label className="label">Niveau</label>
                              <input  
                                name="phone_whatsapp" 
                                type="text" 
                                className="form" 
                                aria-label="phone_whatsapp"
                                placeholder="Numéro whatsapp" />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div>
                              <label className="label">Diplôme obtenu</label>
                              <input  
                                name="diplome" 
                                type="Date" 
                                className="form" 
                                aria-label="diplome" />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div>
                              <label className="label">Année</label> <br/>
                              <input 
                                onChange={(e) => this.handleChange(e)}  
                                value={this.state.user.address} 
                                name="annee" 
                                type="text" 
                                className="form" 
                                aria-label="annee"
                                placeholder="annee de l'obtention" />                            </div>
                            <div >
                              <label className="label">Ecole</label>
                              <input 
                                onChange={(e) => this.handleChange(e)}  
                                value={this.state.user.ecole} 
                                name="ecole" 
                                type="text" 
                                className="form" 
                                aria-label="address"
                                placeholder="entrer le nom de l'école" />
                            </div>
                          </div>
                        </div>
                     
                         <table class="table table-borderless">
                          <thead>
                            <tr>
                              <td scope="col">Statut</td>
                              <td scope="col">Niveau</td>
                              <td scope="col">Diplome obtenue</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>Etudiante</th>
                              <th>Master</th>
                              <th>Diplome obtenus</th>
                            </tr>
                          </tbody>
                          <tr>
                            <td>uvs</td>
                          </tr>
                          <tr>
                            <th>école</th>
                          </tr>
                        </table> 
                         <div class="row">
                          <div
                            class="card"
                            style={{ width: "25rem", height: "5rem" }}
                          >
                            <button
                              type="button"
                              class="btn btn-ajouter-formation btn-primary"
                              data-bs-toggle="button"
                              autocomplete="off"
                              aria-pressed="true"
                            >
                              Ajouter une autre formation
                            </button>
                          </div>
                        </div> 
                      </div>
                    </div>
                    
                     */}
                      {/* <div class="card btom-card-demande-candidature">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="card">
                              <div class="card-body">
                                <div class="row">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="intitulé du poste"
                                    aria-label="First name"
                                  />
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="nom de l'entreprise"
                                    aria-label="Last name"
                                  />
                                </div>
                                <div className="row">
                                  <div className="col select-option-demande-candidature">
                                    <select
                                      type="day"
                                      class="form-select select-day-demande-candidature"
                                      id="autoSizingSelect"
                                    >
                                      <option value="1">Mois</option>
                                      <option value="2">Janvier</option>
                                      <option value="3">Fevrier</option>
                                    </select>
                                  </div>
                                  <div className="col select-option-demande-candidature">
                                    <select
                                      type="day"
                                      class="form-select select-day-demande-candidature"
                                      id="autoSizingSelect"
                                    >
                                      <option value="1">Année</option>
                                      <option value="2">2021</option>
                                      <option value="3">2020</option>
                                    </select>
                                  </div>

                                  <div className="col select-option-demande-candidature">
                                    <select
                                      className="form-select select-day-demande-candidature"
                                      id="autoSizingSelect"
                                    >
                                      <option value="1">Mois</option>
                                      <option value="2">Fevrier</option>
                                      <option value="3">Mars</option>
                                    </select>
                                  </div>
                                  <div className="col select-option-demande-candidature">
                                    <select
                                      className="form-select select-day-demande-candidature"
                                      id="autoSizingSelect"
                                    >
                                      <option value="1">Année</option>
                                      <option value="2">2021</option>
                                      <option value="3">2020</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="text-et-buton-check">
                                  <span>à aujourd'hui</span>
                                  <input type="radio" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="card">
                              <div class="card-body">
                                <div class="row">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="intitulé du poste"
                                    aria-label="First name"
                                  />
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="nom de l'entreprise"
                                    aria-label="Last name"
                                  />
                                </div>
                                <div className="row">
                                  <div className="col select-option-demande-candidature">
                                    <select
                                      type="day"
                                      class="form-select select-day-demande-candidature"
                                      id="autoSizingSelect"
                                    >
                                      <option value="1">Mois</option>
                                      <option value="2">Janvier</option>
                                      <option value="3">Fevrier</option>
                                    </select>
                                  </div>
                                  <div className="col select-option-demande-candidature">
                                    <select
                                      type="day"
                                      class="form-select select-day-demande-candidature"
                                      id="autoSizingSelect"
                                    >
                                      <option value="1">Année</option>
                                      <option value="2">2021</option>
                                      <option value="3">2020</option>
                                    </select>
                                  </div>

                                  <div className="col select-option-demande-candidature">
                                    <select
                                      className="form-select select-day-demande-candidature"
                                      id="autoSizingSelect"
                                    >
                                      <option value="1">Mois</option>
                                      <option value="2">Fevrier</option>
                                      <option value="3">Mars</option>
                                    </select>
                                  </div>
                                  <div className="col select-option-demande-candidature">
                                    <select
                                      className="form-select select-day-demande-candidature"
                                      id="autoSizingSelect"
                                    >
                                      <option value="1">Année</option>
                                      <option value="2">2021</option>
                                      <option value="3">2020</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="text-et-buton-check">
                                  <span>
                                    à aujourd'hui <input type="radio" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="card"
                            style={{ width: "23rem", height: "5rem" }}
                          >
                            <button
                              type="button"
                              class="btn btn-ajouter-formation btn-primary"
                              data-bs-toggle="button"
                              autocomplete="off"
                              aria-pressed="true"
                            >
                              Ajouter une autre experience
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}

                      <div class="card btom-card-demande-candidature">
                        <div class="card-body">
                          <span className="experience-demande-candidature">
                            <span style={{ color: "red" }}> * </span>Motivations
                          </span>
                          <p>
                            {/* Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book */}
                            <textarea
                              placeholder="Mettez vos motivations ici"
                              onChange={(e) => this.handleChange(e)}
                              value={this.state.fields["motivation"]}
                              name="motivation"
                              type="text"
                              className="motivation"
                              aria-label="motivation"
                            />
                            {this.state.remaining_char >= 31 && (
                              <span className="restant_container_sup_30">
                                {" "}
                                {this.state.remaining_char} caractéres restants
                              </span>
                            )}
                            {this.state.remaining_char <= 30 &&
                              this.state.remaining_char > 1 && (
                                <span className="restant_container_inf_30">
                                  {" "}
                                  {this.state.remaining_char} caractéres
                                  restants
                                </span>
                              )}
                            {this.state.remaining_char <= 1 && (
                              <span className="restant_container_inf_30">
                                {" "}
                                {this.state.remaining_char} caractére restant
                              </span>
                            )}
                            {this.state.motivation_empty && (
                              <div className="errorMsg">
                                {this.state.errors.motivation_empty}
                              </div>
                            )}{" "}
                          </p>
                        </div>
                      </div>
                      <div class="card btom-card-demande-candidature">
                        <div class="card-body">
                          <span className="experience-demande-candidature">
                            Formations à Bakeli
                          </span>
                          <br />
                          <div className="col">
                            <span className="spanStyle" style={{ fontWeight: "bold" }}>
                              <span style={{ color: "red" }}> * </span>
                              Veuillez Cocher la formation que vous souhaitez
                              suivre
                            </span>
                          </div>
                          <div class="row">
                            <select
                              name="domaine_id"
                              className="form-control input_item"
                              id="domaine_id"
                              onChange={(e) => this.handleChange(e)}
                              value={this.state.fields["domaine_id"]}
                            >
                              <option> --Choisir un domaine-- </option>
                              {this.state.domaines.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {" "}
                                  {item.name}{" "}
                                </option>
                              ))}
                            </select>
                            {this.state.domaine_id_empty && (
                              <div className="errorMsg">
                                {this.state.errors.domaine_id_empty}
                              </div>
                            )}
                            {/* <div class="col">
                            Programmation
                            <input type="checkbox" />
                          </div>
                          <div class="col">
                            Marketing <input type="checkbox" />
                          </div>
                          <div class="col">
                            Design <input type="checkbox" />
                          </div> */}
                          </div>{" "}
                          <br />
                          <div className="col">
                            <span className="spanStyle" style={{ fontWeight: "bold"}}>
                              <span style={{ color: "red" }}> * </span>Choisir
                              le type de formation souhaité
                            </span>
                          </div>
                          <div class="row">
                            <div class="col choix"> 
                              <input
                                  type="radio"
                                  value="longue"
                                  name="type_formation"
                                  className="radio"                                 
                                  onChange={(e) => this.handleChange(e)}
                                />
                                Courte{" "}
                              </div>
                            </div>
                            <div class="row">
                              <div class="col choix">
                                <input
                                  type="radio"
                                  className="radio"
                                  name="type_formation"
                                  value="longue"
                                  onChange={(e) => this.handleChange(e)}
                                />
                                Longue{" "}
                              </div>
                            </div>
                            {this.state.type_formation_empty && (
                              <div className="errorMsg">
                                {this.state.errors.type_formation_empty}
                              </div>
                            )}
                          <div className="col">
                            <span className="spanStyle" style={{ fontWeight: "bold"}}>
                              {" "}
                              <span style={{ color: "red" }}> * </span> Choisir
                              la durée de formation souhaité
                            </span>
                          </div>
                          <div class="row">
                            <div class="col choix">                             
                              <input
                                type="radio"
                                className="radio"
                                name="duree_formation"
                                value="6 mois"
                                onChange={(e) => this.handleChange(e)}
                              />
                              6 mois{" "}
                              </div>
                            </div>
                            <div class="row">
                            <div class="col  choix">                             
                              <input
                                type="radio"
                                className="radio"
                                name="duree_formation"
                                value="1 an"
                                onChange={(e) => this.handleChange(e)}
                              />
                              1 an{" "}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col choix">                             
                              <input
                                type="radio"
                                className="radio"
                                name="duree_formation"
                                value="2 ans"
                                onChange={(e) => this.handleChange(e)}
                              />
                              2 ans{" "}
                            </div>
                          </div>
                          {this.state.duree_formation_empty && (
                            <div className="errorMsg">
                              {this.state.errors.duree_formation_empty}
                            </div>
                          )}
                          <div className="col">
                            <span className="spanStyle" style={{ fontWeight: "bold"}}>
                              <span style={{ color: "red" }}> * </span>Choisir
                              le lieu de votre formation
                            </span>
                          </div>
                          <div class="row">
                            <div class="col choix">                            
                              <input
                                type="radio"
                                className="radio"
                                name="lieu_formation"
                                value="dakar"
                                onChange={(e) => this.handleChange(e)}
                              />
                              Dakar{" "}
                              </div>
                            </div>
                            <div class="row">
                            <div class="col choix">                             
                              <input
                                type="radio"
                                className="radio"
                                name="lieu_formation"
                                value="thies"
                                onChange={(e) => this.handleChange(e)}
                              />
                              Thiès{" "}
                            </div>
                          </div>
                          {this.state.lieu_formation_empty && (
                            <div className="errorMsg">
                              {this.state.errors.lieu_formation_empty}
                            </div>
                          )}
                        </div>
                      </div>
                      {/* <div class="card btom-card-demande-candidature">
                      <div class="card-body">
                        <span className="experience-demande-candidature">
                          Ajouter le cv
                        </span>
                        <div>
                          <img
                            src={bureautique}
                            className="img-cv-demande-candidature"
                            
                          />
                        </div>
                        <input
                          id="avatar_file"
                          onChange={(e) => this.handlefileChange(e)}
                          name="avatar_file"
                          type="file"
                          className="form-control select-modal-content-bakeliste"
                        /> 
                      </div>
                    </div> */}
                      <div class="card btom-card-demande-candidature">
                        <div class="card-body">
                          <span className="experience-demande-candidature">
                            Nos conditions de travail
                          </span>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-5 offset-1">
                          <button
                            type="submit"
                            className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                          >
                            ANNULER
                          </button>
                        </div>
                        <div className="col-md-5">
                          {/* <button
                          type="submit"
                          className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                          
                        >
                          ENVOYER
                        </button> */}
                          {this.state.candidaturInProgress ? (
                            <button
                              type="submit"
                              className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                              disabled
                            >
                              Envoie en cours &nbsp;
                              <FeatherIcon icon="loader" aria-hidden="true" />
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                            >
                              ENVOYER
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 py-4">
            <div className="row pr-5">
              <div className="col-sm-12 width_card">
                <Button />
              </div>
              <div className="col-sm-12 width_card">
                <Adresse />
              </div>
              <div className="col-sm-12 width_card">
                <Webinar />
              </div>
              <div className="col-sm-12 width_card">
                <CardRentree />
              </div>
              <div className="col-sm-12 width_card">
                <CardStage />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ demande }) => ({ ...demande }),
//     dispatch => bindActionCreators({ ...contentActions }, dispatch)
//   )( content );
