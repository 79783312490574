import React, { Component } from 'react';
import "./dialogBoite.css";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";


class Dialog extends Component {
    render() {
        let dialog = (
            <div className="dialogStyles">
                {/* <button className="dialogCloseButtonStyles">x</button> */}

                <div>{this.props.children}</div>
                    <button
                        className="dialogCloseButtonStyles btn-inscrit-event"
                        onClick={this.props.onClose}
                    >
                    <CheckCircleOutlineIcon  />
                    Je m’inscris               
                </button>
            </div>
        );

        if (! this.props.isOpen) {
            dialog = null;
        }
        return (
            <div>
                {dialog}
            </div>
        );
    }
}

export default Dialog;
