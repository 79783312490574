import React, { Component } from "react";
import "./inscriptionRencontreGuinee.css";
import amplitude from "amplitude-js";
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";
import axios from "axios";
import API from "../../variablesGlobales";
import Logo from "../../img/logo.png";
import JeemaLogo from "../../img/icons/jeema-logo.jpeg";
import FeatherIcon from "feather-icons-react";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// import Dialog  from '../dialogBoite/index';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as inscriptionRencontreActions from "../../store/inscriptionRencontre/actions";
export default class inscriptionRencontreGuinee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      rencontre_id: 60,
      token: null,
      isPending: false,
      isValidUser: true,
      userFullName: "",
      api: API.API,
      flashMessage: "",
      addModificationProgress: false,
      candidaturInProgress: false,
      session_empty: false,
      canal_infos_empty: false,
      domaine_empty: false,
      userPhone: "",
      inscriptionSent: false,
      domaines: [],
      fields: {},
      isAcceptAccount: true,
      rencontre: {}
      // isOpen: false
    }; 
  }

  componentDidMount() {
    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    amplitude.getInstance().logEvent("visitedInscriptionEvenementNetwork");
  }
  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);
    amplitude.getInstance().init(api_key);
  };

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  };
  handleCreateAccount = (e) => {
    this.setState({
      isAcceptAccount: !this.state.isAcceptAccount,
    });
  };
  onValidateFormData = () => {
    let fields = this.state.fields;
    let errors = {};
    let isValidForm = true;

    // if (!fields["domaine"]) {
    //   isValidForm = false;
    //   this.setState({
    //     domaine_empty: true,
    //   });
    //   errors["domaine_empty"] = "Ce champ est obligatoire";
    // }
    if (!fields["canal_infos"]) {
      isValidForm = false;
      this.setState({
        canal_infos_empty: true,
      });
      errors["canal_infos"] = "Ce champ est obligatoire";
    }
    // if (!fields["session"]) {
    //   isValidForm = false;
    //   this.setState({
    //     session_empty: true,
    //   });
    //   errors["session"] = "Ce champ est obligatoire";
    // }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  };
  // console.log("ddd", ),



  
  submitCandidature = (e) => {
    e.preventDefault();
    if (this.onValidateFormData()) {
      this.setState({
        candidaturInProgress: true,
      });
      var data = this.state.fields;

      var fd = new FormData();

      fd.append("first_name", this.state.fields.first_name);
      fd.append("last_name", this.state.fields.last_name);
      fd.append("address", this.state.fields.address);
      fd.append("email", this.state.fields.email);
      fd.append("isAcceptAccount", this.state.isAcceptAccount);
      // fd.append("domaine", this.state.fields.domaine);
      fd.append("phone", this.state.fields.phone);
      fd.append("canal_infos", this.state.fields.canal_infos);
      // fd.append("session", this.state.fields.session);
      fd.append("rencontre_id", this.state.rencontre_id);
      var eventProperties = {
        user_email: this.state.fields.email,
        rencontre: this.state.rencontre_id,
        userFullname:
          this.state.fields.first_name + " " + this.state.fields.last_name,
      };
      if (this.state.isAcceptAccount === true) {
        amplitude
          .getInstance()
          .logEvent("Accept_accountCreation_network", eventProperties);
      }

      axios
        .post(this.state.api + "participants", fd)
        .then((response) => {
          if (response.data.success) {
            amplitude
              .getInstance()
              .logEvent("INSCRIPTION_GUINEE_WORKSHOP_SUCCESS", eventProperties);

            this.setState({
              candidaturInProgress: false,
              inscriptionSent: true,
            });
          }
        })
        .catch((error) => {
          amplitude
            .getInstance()
            .logEvent("INSCRIPTION_GUINEE_WORKSHOP_ERROR", eventProperties);

          this.setState({
            successfullUpdate: false,
            candidaturInProgress: false,
            inscriptionNotSent: true,
            successfullMessage:
              "Une erreur est survenue lors de l'inscription, veuillez reessayer.",
          });
        });
    }
  };
  render() {
    return (
      <Amplitude>
        <LogOnMount eventType="visite page d'inscription bakeli weekend" />

        <div className="">
          <div className="row page-register">
            <div
              className="col-md-6 banner-connexion-page-home d-flex justify-content-center
          "
            >
              <div className="col-xl-12 container-nav-vertical-home col-lg-10 col-md-11 col-sm-8 text-center pt-5">
                {/* <h1 className="titre-banner-connexion">Bakeli</h1>
                <p className="slogan-banner-connexion">
                  Training the best tech talent in Africa.
                </p> */}
                {/* <div className="m-5">
                  <ReactPlayer
                    url="https://youtu.be/C9mZynlem1w"
                    width="100%"
                    controls={true}
                    playing={true}
                  />
                </div> */}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 d-flex justify-content-center itm-center bg-white">
              <div className="col-xl-10 col-lg-9 col-md-11 col-sm-8 d-flex flex-col">
                {/* <div className="row">
                  <img
                    className="logo-bakeli m-l-r-auto mt-5"
                    src={JeemaLogo}
                    alt=""
                  />
                  <img
                    className="logo-bakeli m-l-r-auto mt-5"
                    src={Logo}
                    alt=""
                  />
                </div> */}
                <p className="h-connexion-form mt-5">
                  "WORKSHOP BAKELI GUINEE"
                </p>{" "}
                {this.state.inscriptionSent ? (
                  <p className="successMsg">
                    Votre inscription a été prise en compte , Un e-mail de
                    confirmation vous a été envoyé. Merci
                  </p>
                ) : (
                  <form
                    onSubmit={(e) => this.submitCandidature(e)}
                    encType="multipart/form-data"
                  >
                    <p className="p-3">
                      Les champs précédes de ce signe{" "}
                      <span style={{ color: "red" }}> * </span> sont
                      obligatoires
                    </p>
                    <div class="card btom-card-demande-candidature">
                      <div class="card-body">
                        <span className="experience-demande-candidature">
                          Informations personnelles{" "}
                          <span style={{ color: "red" }}> * </span>
                        </span>
                        <div className="row mt-3">
                          <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                            <input
                              type="text"
                              className="form-control control-3"
                              placeholder="Prénom"
                              aria-label="First name"
                              name="first_name"
                              onChange={(e) => this.handleChange(e)}
                              required
                              // value={this.state.fields["first_name"]}
                            />
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                            <input
                              type="text"
                              className="form-control control-3"
                              placeholder="Nom"
                              aria-label="Last name"
                              name="last_name"
                              onChange={(e) => this.handleChange(e)}
                              required
                              // value={this.state.fields["first_name"]}
                            />
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                            <input
                              type="number"
                              className="form-control control-3"
                              placeholder="Telephone Whatsapp"
                              aria-label="telephone"
                              name="phone"
                              onChange={(e) => this.handleChange(e)}
                              required
                              // value={this.state.fields["first_name"]}
                            />
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                            <input
                              type="email"
                              className="form-control control-3"
                              placeholder="Email"
                              aria-label="email"
                              name="email"
                              onChange={(e) => this.handleChange(e)}
                              required
                              // value={this.state.fields["first_name"]}
                            />
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 d-flex">
                            <input
                              type="text"
                              className="form-control control-3"
                              placeholder="Ville Residence"
                              aria-label="adresse"
                              name="address"
                              onChange={(e) => this.handleChange(e)}
                              required
                              // value={this.state.fields["first_name"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card btom-card-demande-candidature">
                      <div class="card-body">
                        {/* <span className="experience-demande-candidature">
                          Je m'inscris pour la ville de{" "}
                          <span style={{ color: "red" }}> * </span>
                        </span>
                        <br />
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="session"
                              value="Dakar(Mermoz)"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Dakar (Mermoz)
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="session"
                              value="Guediawaye"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Guediawaye
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="session"
                              value="Keur Massar"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Keur Massar
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="session"
                              value="Sebikotane"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Sebikotane
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="session"
                              value="Kaolack"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Kaolack
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="session"
                              value="Diourbel"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Diourbel
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="session"
                              value="Thies"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Thies
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="session"
                              value="Saint-Louis"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Saint-Louis
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="session"
                              value="Bignona"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Bignona
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="session"
                              value="Ziguinchor"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Ziguinchor
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="session"
                              value="Podor"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Podor
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="session"
                              value="Mbour"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Mbour
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="session"
                              value="Kolda"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Kolda
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="session"
                              value="Pikine"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Pikine
                          </label>
                        </div>
                        {this.state.session_empty && (
                          <div className="errorMsg">
                            {this.state.errors.session}
                          </div>
                        )}
                        <span className="experience-demande-candidature">
                          Domaine de participation{" "}
                          <span style={{ color: "red" }}> * </span>
                        </span>
                        <br />
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="domaine"
                              value="Développement web"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Developpement Web ou Mobile
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="domaine"
                              value="Marketing Digital"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Marketing digital
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="domaine"
                              value="Design"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Design Graphique
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="domaine"
                              value="Entrepreneuriat"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars"></span>
                            Entrepreneuriat
                          </label>
                        </div>
                        {this.state.domaine_empty && (
                          <div className="errorMsg">
                            {this.state.errors.domaine}
                          </div>
                        )} */}

                        <span className="experience-demande-candidature">
                          Comment avez-vous connu l'évènement ?
                          <span style={{ color: "red" }}> * </span>
                        </span>
                        <br />
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="canal_infos"
                              value="facebook"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars mr-2"></span>
                            Via Facebook
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="canal_infos"
                              value="whatsapp"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars mr-2"></span>
                            Via Whatsapp
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="canal_infos"
                              value="linkedin"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars mr-2"></span>
                            Via Linkedin
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="canal_infos"
                              value="instagram"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars mr-2"></span>
                            Via Instagram
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="canal_infos"
                              value="youtube"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars mr-2"></span>
                            Via Youtube
                          </label>
                        </div>
                        <div class="checkbox c-checkbox">
                          <label>
                            <input
                              type="radio"
                              name="canal_infos"
                              value="ami"
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span class="fa fa-bars mr-2"></span>
                            Via un ami/camarade/proche...
                          </label>
                        </div>

                        {this.state.canal_infos_empty && (
                          <div className="errorMsg">
                            {this.state.errors.canal_infos}
                          </div>
                        )}
                      </div>
                    </div>

                    <div class="card btom-card-demande-candidature">
                      <div class="card-body">
                        <span className="experience-demande-candidature">
                          A Propos{" "}
                        </span>
                        {/* <div class="checkbox c-checkbox"> */}
                        <label>
                          <input
                            type="checkbox"
                            name="isAcceptAccount"
                            value="isAcceptAccount"
                            checked={this.state.isAcceptAccount}
                            onClick={(e) => this.handleCreateAccount(e)}
                          />
                          <span class="mr-2"></span>
                          Coche cette case pour disposer officiellement d’un
                          compte dans notre réseau social éducatif, où tu auras
                          accès à l’ensemble de nos évènements et à des milliers
                          de contenus
                        </label>
                        {/* </div> */}
                        {/* <p>
                          Remplis ce formulaire si tu veux participer à cette
                          journée d'apprentissages et d'échanges avec nos
                          meilleurs coachs en développement web et mobile,
                          marketing digital, design graphique et
                          entrepreneuriat. !
                        </p> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        {this.state.inscriptionNotSent && (
                          <p className="errorMsg">
                            Une erreur est survenue lors de l'inscription,
                            veuillez reessayer.
                          </p>
                        )}
                        {this.state.candidaturInProgress ? (
                          <button
                            type="submit"
                            className="btn-theme-candidature text-up m-t-30 w-full p-t-15 p-b-15"
                            disabled
                          >
                            En Cours &nbsp;
                            <FeatherIcon icon="loader" aria-hidden="true" />
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn-theme-candidature text-up m-t-30 w-full p-t-15 p-b-15 mb-5"
                          >
                            S'INSCRIRE
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </Amplitude>
    );
  }
}
// export default connect(
//     ({ inscriptionRencontre }) => ({ ...inscriptionRencontre }),
//     dispatch => bindActionCreators({ ...inscriptionRencontreActions }, dispatch)
//   )( inscriptionRencontre );
