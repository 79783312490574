import React, { Component } from 'react';
import * as Icon from 'react-feather';
import FeatherIcon from 'feather-icons-react';
import './profil.css';
import Ellipse from '../../img/Ellipse.png';
import user from '../../img/user.svg';
import home from '../../img/home.svg';
import phone from '../../img/phone.svg';
import edit from '../../img/edit-2.svg';


export default class PostuleCandidature extends Component {
    // constructor(props) {
    //     super(props);

    // }

    render() {
        return (
            <div className="component-profil">
                 <h3 className="article my-3 py-1">Profil</h3>
                 <div className="card my-1 mt-2 profil-demandeur">
                    <div className="card-body">
                     <div class="card postuler-stagiaire">
                            <div class="card-body images-aita-card-center">
                            <img src={Ellipse} /> 
                            
                         </div>
                         
                        </div>
                        <form>

                           <div className="row">
                            <div className="col-10">
                            <div></div><FeatherIcon className="couleur-icons-profil" icon="user" /><label className="born-day">Nom</label>
                            <input
                                type="text"
                                name="name"
                                className="form-control txt-input"
                                // value={}
                                placeholder="Aita mbaye"
                                // onChange={}
                                />
                           
                            </div>
                            <div className="col-2 largeur-edit-icon"><img src={edit} /></div>
                            </div>

                            <div className="row">
                            <div className="col-10">
                            
                            <div></div><FeatherIcon className="couleur-icons-profil" icon="phone" /><label className="born-day">Telephone</label>
                           
                            <input
                                type="text"
                                name="name"
                                className="form-control txt-input"
                                // value={}
                                placeholder="77 000 00 00"
                                // onChange={}
                                />
                            </div>
                            <div className="col-2 largeur-edit-icon"><img src={edit} /></div>
                            </div>

                            <div className="row">
                            <div className="col-10">
                            
                            <div></div><FeatherIcon className="couleur-icons-profil" icon="home" /> <label className="born-day">Adresse</label>
                            <input
                                type="text"
                                name="liberte6"
                                className="form-control txt-input"
                                // value={}
                                placeholder="Liberté 6"
                                // onChange={}
                                />
                                
                            </div>
                            <div className="col-2 largeur-edit-icon"><img src={edit} /></div>
                            </div>
                            <div className="row">
                            <div className="col-10">
                           
                                <div></div><FeatherIcon className="couleur-icons-profil" icon="sun" /><label className="born-day"> Date de naissance</label>
                            <input
                                type="text"
                                name="dateNaissance"
                                className="form-control txt-input"
                                // value={}
                                placeholder="23/03/2000"
                                // onChange={}
                                />
                            </div> 
                            <div className="col-2 largeur-edit-icon"><img src={edit} /></div>
                            </div>
                            <div className="row">
                            <div className="col-10">
                            <div></div><FeatherIcon className="couleur-icons-profil" icon="activity" /><label className="born-day">Statut</label>
                            <input
                                type="text"
                                name="statut"
                                className="form-control txt-input"
                                // value={}
                                placeholder="Etudiate"
                                // onChange={}
                                />
                            </div>
                            <div className="col-2 largeur-edit-icon"><img src={edit} /></div>
                            </div>
                            <div className="row">
                            <div className="col-10">
                            <div></div><FeatherIcon className="couleur-icons-profil" icon="minus-square" /><label className="born-day">Specialité</label>
                            <input
                                type="text"
                                name="specialite"
                                className="form-control txt-input"
                                // value={}
                                placeholder="Multimédia et informatique"
                                // onChange={}
                                /> 
                            </div>
                            <div className="col-2 largeur-edit-icon"><img src={edit} /></div>
                            </div>
                        </form>
                    </div>
                 </div>
            </div>
         );
    }
}