import React, { useState } from 'react';
import ItemsCarousel from 'react-items-carousel';
import image32 from '../../img/image32.png';
import image33 from '../../img/image33.png';
import image34 from '../../img/image34.png';
import './carousel.css'
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as carouselActions from "../../store/carousel/actions";
export default () => {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }

    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 40;
    
      return (
      <div className="component-carousel py-5">
          <div style={{ padding: `0 ${chevronWidth}px` }}>
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={3}
        gutter={20}
        leftChevron={<button>{'<'}</button>}
        rightChevron={<button>{'>'}</button>}
        outsideChevron
        chevronWidth={chevronWidth}
      >
        <div style={{ height: 300 }} className="">
            <img src={image32} alt="image32" className="sliderimg" />
            <div className="offrestage py-4">Stage en UI/UX design </div>
        </div>
        <div style={{ height: 300 }} className="">
            <img src={image33} alt="image33" className="" />
            <div className="offrestage py-4">Stage en UI/UX design</div>
        </div>
        <div style={{ height: 300 }} className="">
            <img src={image34} alt="image34" className="" />
            <div className="offrestage py-4">Stage en UI/UX design</div>
        </div>
        <div style={{ height: 300 }} className="">
            <img src={image32} alt="image32" className="" />
            <div className="offrestage py-4">Stage en UI/UX design</div>
        </div>
        <div style={{ height: 300 }} className="">
            <img src={image32} alt="image32" className="" />
            <div className="offrestage py-4">Stage en UI/UX design</div>
        </div>
        <div style={{ height: 300 }} className="">
            <img src={image33} alt="image33" className="" />
            <div className="offrestage py-4">Stage en UI/UX design</div>
        </div>
        <div style={{ height: 300 }} className="">
            <img src={image34} alt="image34" className="" />
            <div className="offrestage py-4">Stage en UI/UX design</div>
        </div>
        <div style={{ height: 300 }} className="">
            <img src={image32} alt="image32" className="" />
            <div className="offrestage py-4">Stage en UI/UX design</div>
        </div>
        
      </ItemsCarousel>
    </div>
      </div>
      );
    
  }
// export default connect(
//     ({ carousel }) => ({ ...carousel }),
//     dispatch => bindActionCreators({ ...carouselActions }, dispatch)
//   )( carousel );