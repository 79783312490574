import React, { Component } from "react";
import "./videoTemoignages.css";
import axios from "axios";
import ReactPlayer from "react-player";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as videoTemoignagesActions from "../../store/videoTemoignages/actions";
export default class videoTemoignages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video: [],
    };
  }
  componentWillMount() {
    this.VideoList();
  }
  VideoList() {
    axios
      .get(
        "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PLOUCG3NjHUwuvAiIcIW2_DNBcDWLl_Chq&key=AIzaSyB0L-UNk8-0qwtaZLkSlKjfdGrhONqPdCw"
      )
      .then((response) => {
        console.log("video", response.data.items);
        this.setState({ video: response.data.items });
        // console.log("test", this.state.video);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  render() {
    const items = this.state.video.map((tab) => {
      return (
        <div className="row m-3">
          {/* <h3>{tab.snippet.title}</h3> */}
          <ReactPlayer
            url={`https://www.youtube.com/watch?v=${tab.snippet.resourceId.videoId}`}
          />
          ;
        </div>
      );
    });
    return (
      <div className="component-video-temoignages">
        <div className="container">
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {items}
          </Carousel>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ videoTemoignages }) => ({ ...videoTemoignages }),
//     dispatch => bindActionCreators({ ...videoTemoignagesActions }, dispatch)
//   )( videoTemoignages );
