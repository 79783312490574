import React, { Component } from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import image from "../../img/image.png";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ShareIcon from "@material-ui/icons/Share";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import PlaceIcon from "@material-ui/icons/Place";
import Button from "../button/button";
import Adresse from "../adresse/adresse";
import Webinar from "../webinar/webinar";
import CardRentree from "../cardRentree/cardRentree";
import CardStage from "../cardStage/cardStage";
import amplitude from "amplitude-js";
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";
import frenchShortFormatDate from "../frenchFormatDate"; 

import ModalContactUs from "../modalContactUs/index";
import axios from "axios";
import API from "../../variablesGlobales";

import "./contentEventKokutana.css";
import { Link } from "react-router-dom"; 
import Dialog  from '../dialogBoite/index';

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentEventKokutanaActions from "../../store/contentEventKokutana/actions";
export default class contentEventKokutana extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = localStorage.getItem("user");
    this.state = {
      api: API.API,
      event: {},
      user: JSON.parse(retrievedObject),
      participants: 0,
      interests: 0,
      formatDate: frenchShortFormatDate.frenchShortFormatDate,
      isOpen: false, 
      fields: {},
      canal_infos_null: false,
    };
  }
  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  };

  onClick = (e) => {
    let isChecked = true;
    let fields = this.state.fields;
    let errors = {};
    this.joinEvent();
    this.setState({
      isOpen: true
    }
    )
    if (!fields["canal_infos"]) {
      isChecked = false;
      this.setState({
        canal_infos_null: true,
      });
      errors["canal_infos"] = "Merci de Choisir une option";
    }

    this.setState({
      errors: errors,
    });

    return isChecked;
 }

  componentDidMount = () => {
    this.getEventbyId();
    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
  };
  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);
    amplitude.getInstance().init(api_key);
  };
  getEventbyId = () => {
    const api = this.state.api;
    const id = this.props.event_id;
    axios
      .get(api + "rencontres/" + id)
      .then((response) => {
        console.log(response.data);
        const { data } = response.data;
        this.setState({
          event: data,
          participants: data.participants.length,
          interests: data.interests.length,
        });
        console.log("event", data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  interestedInEvent = () => {
    const api = this.state.api;
    const event_id = this.props.event_id;
    const user_id = this.state.user.id;
    const username =
      this.state.user.first_name + " " + this.state.user.last_name;
    const fd = new FormData();
    fd.append("user_id", user_id);
    fd.append("rencontre_id", event_id);
    fd.append("username", username);
    var eventProperties = {
      user_id: user_id,
      event_id: event_id,
      username: username,
    };
    axios
      .post(api + "rencontre-interest", fd)
      .then((response) => {
        console.log(response.data);
        const { data } = response.data;
        this.getEventbyId();
        amplitude
          .getInstance()
          .logEvent("INTERESTED_IN_EVENT", eventProperties);
        // this.setState({ event: data });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  joinEvent = () => {
    const api = this.state.api;
    const event_id = this.props.event_id;
    const user_id = this.state.user.id;
    const username =
      this.state.user.first_name + " " + this.state.user.last_name;

    const fd = new FormData();
    fd.append("users_id", user_id);
    fd.append("rencontres_id", event_id);
    var eventProperties = {
      user_id: user_id,
      event_id: event_id,
      username: username,
    };
    axios
      .post(api + "join-rencontre", fd)
      .then((response) => {
        console.log(response.data);
        const { data } = response.data;
        this.getEventbyId();
        amplitude.getInstance().logEvent("JOINED__EVENT", eventProperties);

        // this.setState({ event: data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const event = this.state.event;

    return (
      <div className="p-b-200">
        <div className="row">
          <div className="col-lg-9">
            <div className="row m-b-15">
              <div className="col m-b-15">
                {/* <h5 className="h-theme ff-inter fw-600">Evènements récents</h5> */}
                {/* <h3 className="article my-3 py-1">Evènements récents</h3> */}
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item h-theme ff-inter fw-600">
                      <Link to="/home" className="lien_nav">
                        {" "}
                        Evènements A venir /
                      </Link>
                    </li>
                    <li
                      className="lien_nav_current breadcrumb-item active"
                      aria-current="page"
                    >
                     {event.rencontre_name}
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="col dis-flex m-b-15">
                <ModalContactUs />
              </div>
            </div>

            <div className="container-fluid px-0 kokutana pb-3">
              <div className="row">
                <div className="col-xl-6 col-lg-3 col-md-3 offset-4">
                  {/* <img src={image} alt="fichier evenement" className="" /> */}
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-3"></div>
                <div className="col-xl-9 col-lg-9 col-md-9 marge">
                  {/* <div>LUNDI, Journée entière</div> */}
                  <div>{event.rencontre_name}</div>
                  {/* <div>Bakeli, HLM GRAND YOFF 241</div> */}
                  <div className="pr-5">
                    <hr className="ligne"></hr>
                  </div>
                </div>
              </div>
              <div className="row p-1">
                <div className="col-xl-5 col-lg-5 col-md-5">

                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link active textgreen"
                        id="home-tab"
                        data-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        Description
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link textgreen"
                        id="profile-tab"
                        data-toggle="tab"
                        href="#profile"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        À propos
                      </a>
                    </li>
                  </ul>
                
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3">
                  <button
                    className="btn btn-checkk"
                    onClick={this.interestedInEvent}
                  >
                    <StarBorderIcon />
                    Ça m’intéresse
                  </button>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-">
                  <button
                   className="btn btn-checkk"
                    onClick={this.onClick}
                    >
                    <CheckCircleOutlineIcon />
                    Je m’inscris

                         
                  </button>
                  <Dialog 
                          isOpen={this.state.isOpen} onClose={(e) => this.setState({ isOpen: false })}>
                          
                            <div class="card enlever-bord btom-card-demande-candidature">
                              <div class="card-body">
                              <span className="experience-demande-candidature">
                                Comment avez-vous connu l'évènement ? 
                                <span style={{ color: "red" }}> * </span>
                              </span>
                              <br />
                              <div class="checkbox c-checkbox">
                                <label>
                                  <input
                                    type="radio"
                                    name="canal_infos"
                                    value="facebook"
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                  <span class="fa fa-bars"></span>
                                  Via Facebook
                                </label>
                              </div>
                              <div class="checkbox c-checkbox">
                                <label>
                                  <input
                                    type="radio"
                                    name="canal_infos"
                                    value="whatsapp"
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                  <span class="fa fa-bars"></span>
                                  Via Whatsapp
                                </label>
                              </div>
                              <div class="checkbox c-checkbox">
                                <label>
                                  <input
                                    type="radio"
                                    name="canal_infos"
                                    value="linkedin"
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                  <span class="fa fa-bars"></span>
                                  Via Linkedin
                                </label>
                              </div>
                              <div class="checkbox c-checkbox">
                                <label>
                                  <input
                                    type="radio"
                                    name="canal_infos"
                                    value="instagram"
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                  <span class="fa fa-bars"></span>
                                  Via Instagram
                                </label>
                              </div>
                              <div class="checkbox c-checkbox">
                                <label>
                                  <input
                                    type="radio"
                                    name="canal_infos"
                                    value="youtube"
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                  <span class="fa fa-bars"></span>
                                  Via Youtube
                                </label>
                              </div>
                              <div class="checkbox c-checkbox">
                                <label>
                                  <input
                                    type="radio"
                                    name="canal_infos"
                                    value="ami"
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                  <span class="fa fa-bars"></span>
                                  Via un ami/camarade/proche...
                                </label>
                              </div>
                              {this.state.canal_infos_null && (
                                <div className="errorMsg">
                                  {this.state.errors.canal_infos}
                                </div>
                              )}
                            
                            </div>
                          
                          </div>

                        </Dialog>
                </div>
              </div>
            </div>

            <div className="container-fluid py-3 px-0">
              <div className="row p-3">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="bloctext2 p-3">
                    <div class="tab-content" id="myTabContent">
                      <div
                        class="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        {event.rencontre_description}
                      </div>
                      <div
                        class="tab-pane fade"
                        id="profile"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                      >
                        <div className="texte_tab_pan py-3 px-5">
                          <h6 className="textgreen2 py-4">Détails</h6>
                          <div className="p-2">
                            <SupervisorAccountIcon className="icon_tab_pan" />
                            <span className="texte_icon">
                              {this.state.participants} personnes ont répondu
                            </span>
                          </div>
                          <div className="p-2">
                            <CalendarTodayIcon className="icon_tab_pan" />
                            <span className="texte_icon">
                              
                   {this.state.formatDate(event.rencontre_start_date)}
                              
                            </span>
                          </div>
                          <div className="p-2">
                            <AccessAlarmIcon className="icon_tab_pan" />
                            <span className="texte_icon">Journée entière</span>
                          </div>
                          <div className="p-2">
                            <PlaceIcon className="icon_tab_pan" />
                            <span className="texte_icon">
                              Bakeli, Hlm grand yoff 241
                            </span>
                          </div>
                          <div className="p-2">
                            <span className="texte_icon_titre">Kokùtana</span>
                          </div>
                          <div className="btn_btn">
                            <div className=" btn-education py-1">
                              {event.rencontre_type}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="bloctext2">
                    <div className="textgreen2 py-4">Participants</div>
                    <div className="row">
                      <div className="col-xl-3 col-lg-12 col-md-6 offset-3">
                        <button className="btn btn-chiffre">
                          {this.state.interests}
                        </button>
                        <div className="textgreen tailletext">Interessés</div>
                      </div>
                      {/* <div className="col-xl-6"></div> */}
                      <div className="col-xl-3 col-lg-12 col-md-6">
                        <button className="btn btn-chiffre">
                          {this.state.participants}
                        </button>
                        <div className="textgreen tailletext">Inscris</div>
                      </div>
                    </div>
                    <div className="px-3">
                      <hr className="ligne"></hr>
                    </div>
                    <div className="py-4 px-2">
                      <button className="btn-chiffre2 taillebtn">
                        <ShareIcon />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
          <div className="col-xl-3 col-lg-3 py-4">
            <div className="row pr-5">
              <div className="col-sm-12 width_card">
                <Button />
              </div>
              <div className="col-sm-12 width_card">
                <Adresse />
              </div>
              {/* <div className="col-sm-12 width_card">
                <Webinar />
              </div>
              <div className="col-sm-12 width_card">
                <CardRentree />
              </div>
              <div className="col-sm-12 width_card">
                <CardStage />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ contentEventKokutana }) => ({ ...contentEventKokutana }),
//     dispatch => bindActionCreators({ ...contentEventKokutanaActions }, dispatch)
//   )( contentEventKokutana );
