import React, { Component } from "react";
import * as Icon from "react-feather";
import "./design.css";
import design from "../../img/design.png";
import { Link } from "react-router-dom";
import Button from "../button/button";
import Adresse from "../adresse/adresse";
import Webinar from "../webinar/webinar";
import CardRentree from "../cardRentree/cardRentree";
import CardStage from "../cardStage/cardStage";

export default class Design extends Component {
  // constructor(props) {
  //     super(props);

  // }
  goToDesign = () => {
    window.location = "/demande-candidature";
  }
  render() {
    return (
      <div className="p-b-200">
        <div className="row">
          <div className="col-lg-9">
          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
              <li className="breadcrumb-item h-theme ff-inter fw-600">
                      <Link to="/home" className="lien_nav">
                        {" "}
                        Accueil 
                      </Link>
                    </li>
                {/* <li className="breadcrumb-item h-theme ff-inter fw-600">
                  <Link to="/formations" className="lien_nav">
                    {" "}
                 Formations 
               </Link>
                </li> */}
                <li
                  className="breadcrumb-item active"
                  aria-current="page"
                >
                  Design
             </li>
              </ol>
            </nav>
        
              <div className="card mb-3 formations-card-programmation">
                <img
                  src={design}
                  className="card-img-top t-img-programmation"
                  alt="Design"
                  id=""
                />
                <div className="card-body">
                  <ul id="color-li">
                    <li className="dist-li">
                      <span className="txt-prog">Conception de logo</span>
                    </li>
                    <li className="dist-li">
                      <span className="txt-prog">Conception d'affiche</span>
                    </li>
                    <li className="dist-li">
                      <span className="txt-prog">Conception de flyers</span>{" "}
                    </li>
                    <li className="dist-li">
                      <span className="txt-prog">Conception brochure</span>
                    </li>
                    <li className="dist-li">
                      <span className="txt-prog">
                        Conception de carte de visite
                      </span>
                    </li>
                    <li className="dist-li">
                      <span className="txt-prog">
                        Conception d'application web/mobile
                      </span>
                    </li>
                    <li className="dist-li">
                      <span className="txt-prog">Montage vidéo</span>
                    </li>
                    <li className="dist-li">
                      <span className="txt-prog">Motion design</span>
                    </li>
                  </ul>
                </div>
         

             
            </div>
            <button
              className="col-lg-12 btn-theme-light1"
              type="button"
              onClick={() => this.goToDesign()}
            >
              Rejoindre l'aventure
                </button>
          </div>

          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <Button />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                <Adresse />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                <Webinar />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                <CardRentree />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <CardStage />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
