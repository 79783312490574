import React, { Component } from "react";
import * as Icon from "react-feather";
import sendeal from "../../img/sendeal.png";
import GroupAdobe from "../../img/GroupAdobe.png";
import Rectangle from "../../img/Rectangle.png";
import mapPin from "../../img/map-pin.svg";
import pen from "../../img/pen-tool.svg";
import heart from "../../img/heart.svg";
import calendar from "../../img/calendar.svg";
import { Link } from "react-router-dom";
import Button from "../button/button";
import Adresse from "../adresse/adresse";
import Webinar from "../webinar/webinar";
import CardRentree from "../cardRentree/cardRentree";
import CardStage from "../cardStage/cardStage";
import ContentModal from "../contentModal/contentModal";
import "./annonces.css";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentActions from "../../store/content/actions";
export default class Annonces extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      //   <div className="component-annonces">
      //       <h3 className="article my-3 py-1">Annonces</h3>

      //       <div class="card mb-3">
      //         <div class="row">
      //             <div class="col-md-4 tof-pad-left">
      //                 <img src={sendeal} />
      //                 </div>
      //                 <div className="col-md-8">

      //                     <div className="row mid-card-left">
      //                         <div className="col-lg-6" id="bom-space">SenDeal</div>
      //                         <div className="col-lg-6" id="bom-space">voir plus de details
      //                         <Link to="/sendeal">
      //                         <i className="fas fa-eye"></i></Link></div>

      //                 </div>

      //                 <div className="row mid-card-left">
      //                         <div className="col-lg-4" id="bom-space">offre de stage/UI/XD design</div>
      //                         <div className="col-lg-4" id="bom-space"></div>
      //                         <div className="col-lg-4" id="bom-space"><img src={heart} /></div>

      //                 </div>

      //                 <div className="row mid-card-left">
      //                         <div className="col-lg-4" id="bom-space"><img src={pen} />Stage</div>
      //                         <div className="col-lg-4" id="bom-space"><img src={mapPin} />Dakar</div>
      //                         <div className="col-lg-4" id="bom-space"><img src={calendar} />il y a 1h</div>

      //                 </div>
      //             </div>
      //         </div>
      //     </div>

      //     <div class="card mb-3">
      //         <div class="row">
      //             <div class="col-md-4 tof-pad-left">
      //                 <img src={GroupAdobe} />
      //                 </div>
      //                 <div className="col-md-8">

      //                     <div className="row mid-card-left">
      //                         <div className="col-lg-6" id="bom-space">SenDeal</div>
      //                         <div className="col-lg-6" id="bom-space">voir plus de details
      //                         <Link to="/sendeal">
      //                             <i className="fas fa-eye"></i></Link>
      //                             </div>

      //                 </div>

      //                 <div className="row mid-card-left">
      //                         <div className="col-lg-4" id="bom-space">offre de stage/UI/XD design</div>
      //                         <div className="col-lg-4" id="bom-space"></div>
      //                         <div className="col-lg-4" id="bom-space"><img src={heart} /></div>

      //                 </div>

      //                 <div className="row mid-card-left">
      //                         <div className="col-lg-4" id="bom-space"><img src={pen} />Stage</div>
      //                         <div className="col-lg-4" id="bom-space"><img src={mapPin} />Dakar</div>
      //                         <div className="col-lg-4" id="bom-space"><img src={calendar} />il y a 1 jr</div>

      //                 </div>
      //             </div>
      //         </div>
      //     </div>

      //     <div class="card mb-3">
      //         <div class="row">
      //             <div class="col-md-4 tof-pad-left">
      //                 <img src={Rectangle} className="tail-img-rect-card"/>
      //                 </div>
      //                 <div className="col-md-8">

      //                     <div className="row mid-card-left">
      //                         <div className="col-lg-6" id="bom-space">SenDeal</div>
      //                         <div className="col-lg-6" id="bom-space">voir plus de details
      //                         <Link to="/sendeal">
      //                         <i className="fas fa-eye"></i></Link></div>

      //                 </div>

      //                 <div className="row mid-card-left">
      //                         <div className="col-lg-4" id="bom-space">offre de stage/UI/XD design</div>
      //                         <div className="col-lg-4" id="bom-space"></div>
      //                         <div className="col-lg-4" id="bom-space"><img src={heart} /></div>

      //                 </div>

      //                 <div className="row mid-card-left">
      //                         <div className="col-lg-4" id="bom-space"><img src={pen} />Stage</div>
      //                         <div className="col-lg-4" id="bom-space"><img src={mapPin} />Dakar</div>
      //                         <div className="col-lg-4" id="bom-space"><img src={calendar} />il y a 1 jr</div>

      //                 </div>
      //             </div>
      //         </div>
      //     </div>
      //     <div class="col">
      //             <button class="btn btn-primary en-plus-large-buton" type="button">Voir plus</button>
      //         </div>
      //   </div>
      //   );
      <div className="p-b-200">
        <div className="row page-event">
          <div className="col-xl-9 col-lg-9 page-annonce">
            <div className="row m-b-15">
              <div className="col m-b-15">
                {/* <h6 className="h-theme ff-inter fw-600">Annonces</h6>{" "} */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item h-theme ff-inter fw-600"><Link to="/home" className="lien_nav"> Accueil /</Link></li>
                        <li className="breadcrumb-item active" aria-current="page" className="lien_nav_current">Annonces</li>
                    </ol>
                </nav>
              </div>
              <div className="col dis-flex m-b-15"></div>
            </div>
            <div className="card-dashboard m-b-15 p-b-60">
              <div className="component-annonces">
                <h3 className="article my-3 py-1">Annonces</h3>

                <div class="card mb-3">
                  <div class="row">
                    <div class="col-md-4 tof-pad-left">
                      <img src={sendeal} />
                    </div>
                    <div className="col-md-8">
                      <div className="row mid-card-left">
                        <div className="col-lg-6" id="bom-space">
                          SenDeal
                        </div>
                        <div className="col-lg-6" id="bom-space">
                          voir plus de details
                          <Link to="/sendeal">
                            <i className="fas fa-eye"></i>
                          </Link>
                        </div>
                      </div>

                      <div className="row mid-card-left">
                        <div className="col-lg-4" id="bom-space">
                          offre de stage/UI/XD design
                        </div>
                        <div className="col-lg-4" id="bom-space"></div>
                        <div className="col-lg-4" id="bom-space">
                          <img src={heart} />
                        </div>
                      </div>

                      <div className="row mid-card-left">
                        <div className="col-lg-4" id="bom-space">
                          <img src={pen} />
                          Stage
                        </div>
                        <div className="col-lg-4" id="bom-space">
                          <img src={mapPin} />
                          Dakar
                        </div>
                        <div className="col-lg-4" id="bom-space">
                          <img src={calendar} />
                          il y a 1h
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card mb-3">
                  <div class="row">
                    <div class="col-md-4 tof-pad-left">
                      <img src={GroupAdobe} />
                    </div>
                    <div className="col-md-8">
                      <div className="row mid-card-left">
                        <div className="col-lg-6" id="bom-space">
                          SenDeal
                        </div>
                        <div className="col-lg-6" id="bom-space">
                          voir plus de details
                          <Link to="/sendeal">
                            <i className="fas fa-eye"></i>
                          </Link>
                        </div>
                      </div>

                      <div className="row mid-card-left">
                        <div className="col-lg-4" id="bom-space">
                          offre de stage/UI/XD design
                        </div>
                        <div className="col-lg-4" id="bom-space"></div>
                        <div className="col-lg-4" id="bom-space">
                          <img src={heart} />
                        </div>
                      </div>

                      <div className="row mid-card-left">
                        <div className="col-lg-4" id="bom-space">
                          <img src={pen} />
                          Stage
                        </div>
                        <div className="col-lg-4" id="bom-space">
                          <img src={mapPin} />
                          Dakar
                        </div>
                        <div className="col-lg-4" id="bom-space">
                          <img src={calendar} />
                          il y a 1 jr
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card mb-3">
                  <div class="row">
                    <div class="col-md-4 tof-pad-left">
                      <img src={Rectangle} className="tail-img-rect-card" />
                    </div>
                    <div className="col-md-8">
                      <div className="row mid-card-left">
                        <div className="col-lg-6" id="bom-space">
                          SenDeal
                        </div>
                        <div className="col-lg-6" id="bom-space">
                          voir plus de details
                          <Link to="/sendeal">
                            <i className="fas fa-eye"></i>
                          </Link>
                        </div>
                      </div>

                      <div className="row mid-card-left">
                        <div className="col-lg-4" id="bom-space">
                          offre de stage/UI/XD design
                        </div>
                        <div className="col-lg-4" id="bom-space"></div>
                        <div className="col-lg-4" id="bom-space">
                          <img src={heart} />
                        </div>
                      </div>

                      <div className="row mid-card-left">
                        <div className="col-lg-4" id="bom-space">
                          <img src={pen} />
                          Stage
                        </div>
                        <div className="col-lg-4" id="bom-space">
                          <img src={mapPin} />
                          Dakar
                        </div>
                        <div className="col-lg-4" id="bom-space">
                          <img src={calendar} />
                          il y a 1 jr
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <button
                    class="btn btn-primary en-plus-large-buton"
                    type="button"
                  >
                    Voir plus
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 pr-3 my-3 py-4">
            <div className="row pr-5">
              <div className="col-sm-12 width_card">
                <Button />
              </div>
              <div className="col-sm-12 width_card">
                <Adresse />
              </div>
              <div className="col-sm-12 width_card">
                <Webinar />
              </div>
              <div className="col-sm-12 width_card">
                <CardRentree />
              </div>
              <div className="col-sm-12 width_card">
                <CardStage />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ content }) => ({ ...content }),
//     dispatch => bindActionCreators({ ...contentActions }, dispatch)
//   )( content );
