import React, { Component } from "react";
import "./headerDashboard.css";
import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";
import Logo from "../../img/logo.png";
import { Avatar, Dropdown } from "rsuite";
import axios from "axios";
import API from "../../variablesGlobales";
import base_api_url from "../../variablesGlobales";

import { Link } from "react-router-dom";

export default class headerDashboard extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = localStorage.getItem("user");
    this.state = {
      show: false,
      api: API.API,
      token: window.sessionStorage.getItem("token"),
      user: JSON.parse(retrievedObject),
      base_api_url: base_api_url.base_api_url,
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }
  close() {
    this.setState({ show: false });
  }
  open() {
    this.setState({ show: true });
  }
  goToMyEvent = () => {
    window.location = "/mes-evenements";
  };
  doLogout = () => {
    window.sessionStorage.removeItem("token");
    axios.get(this.state.api + "user-logout").then((response) => {
      const result = response.data;
      if (result.success === true) {
        window.location = "/";
      }
    });
  };

  render() {
    const user = this.state.user;
    const userName = user.first_name + " " + user.last_name;
    const userprofile = user.avatar_file;
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-white box-shadow fixed-top px-lg-5 py-md-3 height-header">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <NavLink className="navbar-brand mr-lg-5 mr-0" to="/">
            <img className="logo-header" src={Logo} alt="" />
          </NavLink>

           <Avatar className="avatar-rs-custom no-display-desktop">
            {userprofile !== null ? (
              <img
                className="avatar-account mb-2"
                src={this.state.base_api_url + userprofile}
                alt=""
              />
            ) : (
              userName
                .split(" ")
                .map((n, i, a) =>
                  i === 0 || i + 1 === a.length ? n[0] : null
                )
                .join("")
            )}
          </Avatar> 
                

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="no-view-tablette dis-flex itm-center w-full">
              <div className="pl-xl-5 pl-lg-4 dis-flex pt-lg-0 pt-5 pb-lg-0 pb-5">
                <p className="welcome-header"> Bakeli Network </p>
                <p className="annee-header">2020</p>
              </div>
              <div className="m-auto">
                <a href="https://www.bakeli.tech/" target="blank">
                  <button className="btn-rejoindre my-2 py-2 p-3">
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="log-out"
                    />
                    Visiter Bakeli School
                  </button>
                </a>
              
              </div>
              <div className="m-auto">
                <NavLink to="/articles">
                  <button className="btn-green">
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="book-open"
                    />
                    Nos Articles
                  </button>
                </NavLink>
              </div>
              <div className="d-flex my-2"></div>

              <div className="ml-auto no-view-tablette">
                <div className="avatar-group dis-flex itm-center">
                  
                  <Avatar className="avatar-rs-custom">
                    {userprofile !== null ? (
                      <img
                        className="avatar-account mb-2"
                        src={this.state.base_api_url + userprofile}
                        alt=""
                      />
                    ) : (
                      userName
                        .split(" ")
                        .map((n, i, a) =>
                          i === 0 || i + 1 === a.length ? n[0] : null
                        )
                        .join("")
                    )}
                  </Avatar>
                  <Dropdown
                    className="name-avatar-custom"
                    title={userName}
                    placement="bottomEnd"
                    trigger="hover"
                  >
                    <NavLink
                      className="dropdown-item-header-dashboard"
                      to="/compte-utilisateur"
                    >
                      Mon compte
                    </NavLink>
                  
                    <Dropdown.Item onClick={this.goToMyEvent}>
                      Mes événements
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.doLogout}>
                      Déconnexion
                    </Dropdown.Item>
                  </Dropdown>
                </div>
              </div>
            </div>

            <div className="no-display-desktop">
              <div className="container-nav-vertical">
                <div
                  className="nav flex-column nav-pills"
                  id="v-pills-tabCustom"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/home"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="layout"
                    />
                    Accueil
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/articles"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="grid"
                    />
                    Articles
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/evenement"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="grid"
                    />
                    Evènements
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/candidature"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="folder"
                    />
                    candidatures
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/liste-contenus"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="save"
                    />
                    Contenus
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/mes-evenements"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="save"
                    />
                    Mes événements
                  </NavLink>
                </div>

                <div className="ml-4"></div>
              </div>
            </div>

          </div>
        </nav>
      </div>
    );
  }
}
