import React, { Component } from "react";
import "./detailsContenuView.css";
import HeaderDashboard from "../headerDashboard/index";
import NavVertical from "../navVertical/index";
import DetailsContenu from "../detailsContenu/detailsContenu";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as detailsContenuViewActions from "../../store/detailsContenuView/actions";
export default class detailsContenuView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props?.location?.state?.id,
    };
    console.log("id", this.props?.location?.state?.id);
  }
  render() {
    return (
      <div className="component-contenu-liste-view">
        <HeaderDashboard />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVertical />
            </div>
            <div className="col-lg-10 pt-3">
              <DetailsContenu id={this.state.id} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ detailsContenuView }) => ({ ...detailsContenuView }),
//     dispatch => bindActionCreators({ ...detailsContenuViewActions }, dispatch)
//   )( detailsContenuView );
