import React, { Component } from "react";
import "./button.css";
import { Link } from "react-router-dom";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as buttonActions from "../../store/button/actions";
export default class button extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      <div className="component-button">
        <Link to="/demande-candidature">
          <button className="btn-rejoindre my-2 py-2 p-3">
            Rejoindre l'Aventure
          </button>
        </Link>
      </div>
    );
  }
}
// export default connect(
//     ({ button }) => ({ ...button }),
//     dispatch => bindActionCreators({ ...buttonActions }, dispatch)
//   )( button );
