import React, { Component } from "react";
import "./wppost.css";
import WPAPI from "../wpapi/index";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as wppostActions from "../../store/wppost/actions";
export default class wppost extends Component {
  constructor() {
    super();
    this.state = {
      books: [],
    };
  }

  componentDidMount() {
    const bookUrl = WPAPI.books;

    fetch(bookUrl)
      .then((response) => response.json())
      .then((response) => {
        console.log("response", response);
        this.setState({
          books: response,
        });
      });
  }
  render() {
    let postLoop = this.state.books.map((post, index) => {
      return (
        <article key={index} className="xiong-block">
          <a href={post.link}>
            <h3>{post.title.rendered}</h3>
          </a>
        </article>
      );
    });
    return <div>{postLoop}</div>;
  }
}
// export default connect(
//     ({ wppost }) => ({ ...wppost }),
//     dispatch => bindActionCreators({ ...wppostActions }, dispatch)
//   )( wppost );
