import React, { Component } from "react";
import * as Icon from "react-feather";
import Ellipse from "../../img/Ellipse.png";
import bureautique from "../../img/bureautique.png";
import "./dossierCandidature.css";
import { Link } from "react-router-dom";
import Button from "../button/button";
import Adresse from "../adresse/adresse";
import Webinar from "../webinar/webinar";
import CardRentree from "../cardRentree/cardRentree";
import CardStage from "../cardStage/cardStage";
// import { connect } from "react-redux";
import API from "../../variablesGlobales";

    import axios from "axios";
import frenchShortFormatDate from "../frenchFormatDate";
    
// import { bindActionCreators } from "redux";
// import * as contentActions from "../../store/content/actions";
export default class DossierCandidature extends Component {
  constructor(props) {
    super(props);
    
        var retrievedObject = localStorage.getItem("user");
    this.state = {      userName:"",
      user: JSON.parse(retrievedObject),
      domaine: [],
      api: API.API,
      formatDate: frenchShortFormatDate.frenchShortFormatDate,

    };
  }

  componentDidMount() {
    this.getDomaineById();
  }



  getDomaineById = () => {
    const id = this.state.user.bakeliste_domaine_id
  axios
    .get(this.state.api + "domaines/" + id)
    .then((response) => {
      this.setState({
        domaine: response.data.data,
      });
    })
    .catch((error) => {
      console.log(error.message);
      if (process.env.NODE_ENV !== "production") {
        return;
      }
    });
};



  render() {
    this.state.userName =this.state.user.first_name + " " + this.state.user.last_name;

    return (
      <div className="p-b-200">
        <div className="row">
          <div className="col-lg-9">
            <div className="row m-b-15">
              <div className="col m-b-15">
                <h6 className="h-theme ff-inter fw-600">
                  Dossier de candidature
                </h6>{" "}
              </div>
              <div className="col dis-flex m-b-15"></div>
            </div>

            <div className=" m-b-15 p-b-60">
              <div className="component-dossier-demande-Candidature">
                <div className="card my-1 mt-2 dossier-demande-candidature">
                  <div className="card-body">
                    <div class="card btom-card-dossier-demande-candidature">
                      <div class="card-body images-aita-card-center">
                        <img src={Ellipse} />
                        <h2>{this.state.userName}</h2>
                        <span>Etudiante</span>
                      </div>
                    </div>

                    <div class="card btom-card-dossier-demande-candidature">
                      <div class="card-body">
                        <span className="experience-demande-candidature">
                          Informations personnelles
                        </span>
                        <table class="table table-borderless">
                          <thead>
                            <tr>
                              <td scope="col">Date de naissance</td>
                              <td scope="col">Telephone</td>
                              <td scope="col">Adresse mail</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>{this.state.formatDate(this.props.date)}
                              </th>
                              <th>{this.props.phone}</th>
                              <th>{this.state.user.email}</th>
                            </tr>
                            <tr>
                              <td>Adresse</td>
                              <td>WhatsApp</td>
                              <td>N° carte d'identité</td>
                            </tr>
                            <tr>
                              <th>{this.state.user.address}</th>
                              <th>{this.props.phone}</th>
                              <th>24588456874613</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
{/* 
                    <div class="card btom-card-dossier-demande-candidature">
                      <div class="card-body">
                        <span className="experience-demande-candidature">
                          Formations
                        </span>

                        <table class="table table-borderless">
                          <thead>
                            <tr>
                              <td scope="col">Statut</td>
                              <td scope="col">Niveau</td>
                              <td scope="col">Diplome obtenue</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>Etudiante</th>
                              <th>Master</th>
                              <th>Licence 3</th>
                            </tr>
                          </tbody>
                          <tr>
                            <td>uvs</td>
                          </tr>
                          <tr>
                            <th>école</th>
                          </tr>
                        </table>
                      </div>
                    </div> */}


{/*                     
                    <div class="card btom-card-dossier-demande-candidature">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="card">
                              <div class="card-body">
                                <div class="row">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="intitulé du poste"
                                    aria-label="First name"
                                  />
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="nom de l'entreprise"
                                    aria-label="Last name"
                                  />
                                </div>
                                <div className="row">
                                  <div className="col select-option-demande-candidature">
                                    <select
                                      type="day"
                                      class="form-select select-day-demande-candidature"
                                      id="autoSizingSelect"
                                    >
                                      <option value="1">Mois</option>
                                      <option value="2">Janvier</option>
                                      <option value="3">Fevrier</option>
                                    </select>
                                  </div>
                                  <div className="col select-option-demande-candidature">
                                    <select
                                      type="day"
                                      class="form-select select-day-demande-candidature"
                                      id="autoSizingSelect"
                                    >
                                      <option value="1">Année</option>
                                      <option value="2">2021</option>
                                      <option value="3">2020</option>
                                    </select>
                                  </div>

                                  <div className="col select-option-demande-candidature">
                                    <select
                                      className="form-select select-day-demande-candidature"
                                      id="autoSizingSelect"
                                    >
                                      <option value="1">Mois</option>
                                      <option value="2">Fevrier</option>
                                      <option value="3">Mars</option>
                                    </select>
                                  </div>
                                  <div className="col select-option-demande-candidature">
                                    <select
                                      className="form-select select-day-demande-candidature"
                                      id="autoSizingSelect"
                                    >
                                      <option value="1">Année</option>
                                      <option value="2">2021</option>
                                      <option value="3">2020</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="text-et-buton-check">
                                  <span>à aujourd'hui</span>
                                  <input type="radio" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="card">
                              <div class="card-body">
                                <div class="row">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="intitulé du poste"
                                    aria-label="First name"
                                  />
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="nom de l'entreprise"
                                    aria-label="Last name"
                                  />
                                </div>

                                
                                <div className="row">
                                  <div className="col select-option-demande-candidature">
                                    <select
                                      type="day"
                                      class="form-select select-day-demande-candidature"
                                      id="autoSizingSelect"
                                    >
                                      <option value="1">Mois</option>
                                      <option value="2">Janvier</option>
                                      <option value="3">Fevrier</option>
                                    </select>
                                  </div>
                                  <div className="col select-option-demande-candidature">
                                    <select
                                      type="day"
                                      class="form-select select-day-demande-candidature"
                                      id="autoSizingSelect"
                                    >
                                      <option value="1">Année</option>
                                      <option value="2">2021</option>
                                      <option value="3">2020</option>
                                    </select>
                                  </div>

                                  <div className="col select-option-demande-candidature">
                                    <select
                                      className="form-select select-day-demande-candidature"
                                      id="autoSizingSelect"
                                    >
                                      <option value="1">Mois</option>
                                      <option value="2">Fevrier</option>
                                      <option value="3">Mars</option>
                                    </select>
                                  </div>
                                  <div className="col select-option-demande-candidature">
                                    <select
                                      className="form-select select-day-demande-candidature"
                                      id="autoSizingSelect"
                                    >
                                      <option value="1">Année</option>
                                      <option value="2">2021</option>
                                      <option value="3">2020</option>
                                    </select>
                                  </div>
                                </div>
                               
                               
                               
                                <div className="text-et-buton-check">
                                  <span>à aujourd'hui</span>
                                  <input type="radio" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div class="card btom-card-dossier-demande-candidature">
                      <div class="card-body">
                        <span className="experience-demande-candidature">
                          Motivations
                        </span>
                        <p>
                          {/* Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book */}
                          {this.props.motivation}
                        </p>
                      </div>
                    </div>
                    <div class="card btom-card-dossier-demande-candidature">
                      <div class="card-body">
                        <span className="experience-demande-candidature">
                          Formations à Bakeli
                        </span>
                        <br />
                        <div className="col">
                          <span>
                            Formation souhaitée
                          </span>
                        </div>
                        <div class="row">
                          <div class="col">
                            {this.state.domaine.name}
                            <input type="checkbox" checked />
                          </div>
                        </div>
                        <div className="col">
                          <span>Type de formation</span>
                        </div>
                        <div class="row">
                          <div class="col">
                          {this.props.type_formation} <input type="radio" checked />
                          </div>
                        </div>
                        <div className="col">
                          <span>Durée de formation</span>
                        </div>
                        <div class="row">
                          <div class="col">
                          {this.props.duree_formation} <input type="radio" checked />
                          </div>
                        </div>
                        <div className="col">
                          <span>Lieu de votre formation</span>
                        </div>
                        <div class="row">
                          <div class="col">
                            {this.props.lieu_formation} <input type="radio" checked />
                          </div>

                          <div class="col"></div>
                        </div>
                      </div>
                    </div>
                    {/* <div class="card btom-card-dossier-demande-candidature">
                      <div class="card-body">
                        <span className="experience-demande-candidature">
                          Votre CV
                        </span>
                        <div>
                          <img
                            src={bureautique}
                            className="img-cv-demande-candidature"
                          />
                        </div>
                      </div>
                    </div> */}
                    <div class="card btom-card-dossier-demande-candidature">
                      <div class="card-body">
                        <span className="experience-demande-candidature">
                          Nos conditions de travail
                        </span>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <button
                          type="button"
                          class="btn btn-primary btn-lg"
                          id="annuller-buton-demande"
                        >
                          Supprimer
                        </button>
                      </div>
                      <div className="col-md-6">
                        <Link to="/demande-candidature">
                          {" "}
                          <button
                            type="button"
                            class="btn btn-primary btn-lg"
                            id="annuller-buton-demande"
                          >
                            Modifier
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div className="col-xl-3 col-lg-3 pr-3 my-5 py-4">
            <div className="row">
              <div className="col-sm-12 width_card">
                <Button />
              </div>
              <div className="col-sm-12 width_card">
                <Adresse />
              </div>
              <div className="col-sm-12 width_card">
                <Webinar />
              </div>
              <div className="col-sm-12 width_card">
                <cardRentree />
              </div>
              <div className="col-sm-12 width_card">
                <CardStage />
              </div>
            </div>
          </div>
          
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ content }) => ({ ...content }),
//     dispatch => bindActionCreators({ ...contentActions }, dispatch