import React, { Component } from "react";
import * as Icon from "react-feather";
import "./formations.css";
import prog from "../../img/prog.png";
import Intersect from "../../img/Intersect.png";
import bureautique from "../../img/bureautique.png";
import gestiondepojet from "../../img/gestiondepojet.png";
import design from "../../img/design.png";
import marketing from "../../img/marketing.png";
import { Link } from "react-router-dom";
import Button from "../button/button";
import Adresse from "../adresse/adresse";
import Webinar from "../webinar/webinar";
import CardRentree from "../cardRentree/cardRentree";
import CardStage from "../cardStage/cardStage";
import ContentModal from "../contentModal/contentModal";
export default class Formations extends Component {
  // constructor(props) {
  //     super(props);

  // }

  render() {
    return (
      <div className="p-b-200">
        <div className="row page-event">
          <div className="col-xl-9 col-lg-9 page-form">
            <div className="row">
              <div className="col">
                {/* <h6 className="h-theme ff-inter fw-600">Accueil</h6>{" "} */}
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item h-theme ff-inter fw-600">
                        {" "}
                        Accueil 
                     
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="col d-flex"></div>
            </div>
            <div className="">
              <div className="component-profil">
                <h3 className="article">Formations</h3>
                <div class="row row-cols-1 row-cols-md-2 g-4">
                  <div class="col">
                    <div class="card formations-card-bottom">
                      <Link to="/programmation-web-mobile">
                        <img src={prog} class="card-img-top" alt="..." />
                        <div class="card-body">
                          <h5 class="card-title" id="formation-card-title">
                            Programmation
                          </h5>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div class="col">
                    <div class="card formations-card-bottom">
                      <Link to="/marketing">
                        <img src={marketing} class="card-img-top" alt="..." />
                        <div class="card-body">
                          <h5 class="card-title" id="formation-card-title">
                            Marketing
                          </h5>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div class="col">
                    <div class="card formations-card-bottom">
                      <Link to="/design">
                        <img src={design} class="card-img-top" alt="..." />
                        <div class="card-body">
                          <h5 class="card-title" id="formation-card-title">
                            Design
                          </h5>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card formations-card-bottom">
                      <Link to="/gestion-de-projet">
                        <img
                          src={gestiondepojet}
                          class="card-img-top"
                          alt="..."
                        />
                        <div class="card-body">
                          <h5 class="card-title" id="formation-card-title">
                            Gestion de projet
                          </h5>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 py-4">
            <div className="row pr-5">
              <div className="col-sm-12 width_card">
                <Button />
              </div>
              <div className="col-sm-12 width_card">
                <Adresse />
              </div>
              {/* <div className="col-sm-12 width_card">
                <Webinar />
              </div>
              <div className="col-sm-12 width_card">
                <CardRentree />
              </div>
              <div className="col-sm-12 width_card">
                <CardStage />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
