import React, { Component } from "react";
// import Ellipse from "../../img/Ellipse.png";
// import sendeal from "../../img/sendeal.png";
// import Group from "../../img/Group.png";
// import logokaloma from "../../img/logokaloma.png";
// import smarthome from "../../img/smarthome.png";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import Button from "../button/button";
import Adresse from "../adresse/adresse";
import Webinar from "../webinar/webinar";
import CardRentree from "../cardRentree/cardRentree";
import CardStage from "../cardStage/cardStage";
import { Link } from "react-router-dom";

// import ContentModal from "../contentModal/contentModal";
import "./content.css";
import axios from "axios";
import API from "../../variablesGlobales";
import Skeleton from "react-loading-skeleton";
import { makeStyles } from "@material-ui/core/styles";
// import Card from "@material-ui/core/Card";
// import CardHeader from "@material-ui/core/CardHeader";
// import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
// import Avatar from "@material-ui/core/Avatar";
// import Typography from "@material-ui/core/Typography";
// import IconButton from "@material-ui/core/IconButton";
// import MoreVertIcon from "@material-ui/icons/MoreVert";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentActions from "../../store/content/actions";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
    margin: theme.spacing(2),
  },
  media: {
    height: 190,
  },
}));

export default class content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      image: "",
      api: API.API,
      links: [],
      author: "",
      name: "",
      pictures: [],
      users: [],
      loading: true,
    };
  }

  componentDidMount = () => {
    // axios.get(`https://www.blog.volkeno.sn/wp-json/wp/v2/posts`).then((res) => {
    //   const articles = res.data;
    //   this.setState({ articles });
    //   console.log(res);
    //   console.log(res.data);
    // });
    this.getArticles();
    this.getImages();
    this.getUsers();
  };

  getArticles = () => {
    axios
      .get("https://www.blog.volkeno.sn/wp-json/wp/v2/posts")
      .then((response) => {
        // console.log(response)
        this.setState({
          articles: response.data,
        });
        this.state.articles.map((item, index) =>
          this.setState({
            loading: false,
          })
        );

        this.setState({
          name: this.state.links.author,
          loading: false,
        });
        // console.log("name", this.state.name);
      })
      .catch((error) => {
        console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
      });
  };

  getImages = () => {
    axios
      .get("https://www.blog.volkeno.sn/wp-json/wp/v2/media")
      .then((response) => {
        // console.log(response)
        this.setState({
          pictures: response.data,
        });
        this.state.pictures.map((item, index) => {
          this.setState({
            loading: false,
          });
        });
        // console.log("cand", this.state.pictures);
      })
      .catch((error) => {
        console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
      });
  };

  getUsers = (data) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Access-Control-Allow-Headers": "application/json",
      },
    };
    axios
      .get("https://www.blog.volkeno.sn/wp-json/wp/v2/users", config, {
        body: JSON.stringify(data),
        mode: "cors",
      })
      .then((response) => {
        // console.log(response)
        this.setState({
          users: response.data,
        });
        this.state.users.map((item, index) => {
          this.setState({
            links: item._links,
            loading: false,
          });
        });
        // console.log("auteur", this.state.links.author);
        // console.log("assane", this.state.users);
      })
      .catch((error) => {
        console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
      });
  };

  // const [images, setImages] = useState([]);

  render() {
    return (
      <div className="p-b-200">
        <div className="row">
          <div className="col-lg-9">
            <div className="row m-b-15">
              <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item h-theme ff-inter fw-600">
                  <Link to="/home" className="lien_nav">
                    {" "}
                    Accueil
                  </Link>
                </li>
             
                <li className="breadcrumb-item active" aria-current="page">
                  Articles
                </li>
              </ol>
            </nav>
            </div>
              <h6 className="h-theme ff-inter fw-600">Articles</h6>{" "}

            <div className="col dis-flex m-b-15"></div>

            <div className="m-b-15 p-b-60">
              <div className="component-content">
                <div className="scroll">
                  <div style={{ fontSize: 20, lineHeight: 2 }}>
                    {this.state.pictures.map((picture, index) => (
                      <div className="card my-1 mt-2 content">
                        {this.state.articles.map((article, index) => (
                          <div className="card-body">
                            <div className="bordure">
                              <h5 className="card-title">
                                {/* <img
                                  src={article.guid.rendered}
                                  alt="Logo"
                                  className="imgg"
                                />{" "} */}
                                {this.state.users.map((item, index) => (
                                  <span className="nom mt-4">
                                    {item._links.author.name}
                                  </span>
                                ))}
                                {/* <span className="heure">8h</span> */}
                              </h5>

                              <div className="px-4">
                                <p>{article.title.rendered}</p>
                                <a id="lien" href={article.guid.rendered}>
                                  {article.guid.rendered}
                                </a>
                                <p className="card-text">
                                  {article.excerpt.rendered}
                                </p>
                              </div>

                              {/* <img
                                className="cv my-4 mx-2"
                                alt="example"
                                key={index}
                                src={picture.guid.rendered}
                              /> */}
                            </div>

                            {/* <div className="row my-3 d-flex">
                              <div className="col-3">
                                <a className="nav-link">
                                  <i className="fas fa-comment-alt-lines"></i>
                                  <span className="chiffre">52</span>
                                </a>
                              </div>
                              <div className="col-3">
                                <a className="nav-link">
                                  <i className="fas fa-thumbs-up"></i>
                                  <span className="chiffre">52</span>
                                </a>
                              </div>
                              <div className="col-3">
                                <a className="nav-link">
                                  <i className="fas fa-thumbs-down"></i>
                                  <span className="chiffre">52</span>
                                </a>
                              </div>
                              <div className="col-3 justify-content-end">
                                <div class="dropdown" id="dropdownn">
                                  <button
                                    className="btn d-flex align-items-center pt-1 mr-4"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="fas fa-share-alt"></i>
                                  </button>
                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <a className="dropdown-item" href="#">
                                      <MailOutlineIcon />
                                      Envoyer un message privé{" "}
                                    </a>
                                    <a className="dropdown-item" href="#">
                                      Partager sur le fil d’actualité
                                    </a>
                                    <a className="dropdown-item" href="#">
                                      <MailOutlineIcon />
                                      Partager sur ses réseaux{" "}
                                    </a>
                                    <a className="dropdown-item" href="#">
                                      <InsertLinkIcon />
                                      Copier le lien{" "}
                                    </a>
                                    <a className="dropdown-item" href="#">
                                      <InsertLinkIcon />
                                      Enregistrer l’article
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        ))}
                      </div>
                    ))}

                    {this.state.loading && (
                      <>
                        <span>
                          <Skeleton animation="wave" height={10} width="40%" />
                        </span>
                        <div>
                          <Skeleton
                            animation="wave"
                            variant="circle"
                            width={40}
                            height={40}
                          />
                        </div>
                      </>
                    )}
                    {this.state.loading && (
                      <>
                        <Skeleton animation="wave" height={20} width="40%" />

                        <Skeleton
                          animation="wave"
                          height={100}
                          variant="rect"
                        />
                      </>
                    )}
                    {this.state.loading && (
                      <>
                        <Skeleton
                          animation="wave"
                          height={60}
                          width="20%"
                          variant="rect"
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 py-4">
            <div className="row pr-5">
              <div className="col-sm-12 width_card">
                <Button />
              </div>
              <div className="col-sm-12 width_card">
                <Adresse />
              </div>
              {/* <div className="col-sm-12 width_card">
                <Webinar />
              </div>
              <div className="col-sm-12 width_card">
                <CardRentree />
              </div>
              <div className="col-sm-12 width_card">
                <CardStage />
              </div> */}
            </div>
          </div>
          {/* <div className="col-sm-12 width_card">
                <Webinar />
              </div>
              <div className="col-sm-12 width_card">
                <CardRentree />
              </div>
              <div className="col-sm-12 width_card">
                <CardStage />
              </div> */}
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ content }) => ({ ...content }),
//     dispatch => bindActionCreators({ ...contentActions }, dispatch)
//   )( content );
