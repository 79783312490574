import React, {Component} from 'react';
import * as Icon from 'react-feather';
import './webinar.css';
import {Link} from 'react-router-dom';
import iconzoom from '../../img/icons/iconzoom.png';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as webinarActions from "../../store/webinar/actions";
export default class webinar extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
      <div className="component-webinar py-2">
        <div class="right_sidebar_card">
          <div class="card-body">
            <h6 className="web"><Icon.Calendar className="locc" /><span className="webinar">Webinar</span></h6>
            
              
              <h4 className="tps">Mar 12/10/2020 - 10H00</h4>
              <div className="py-2 d-flex align-items-center">
                    <Link to="/candidature" className="btn-participer">
                        <img src={iconzoom} alt="iconzoom" className="image-zoom"/>PARTICIPER
                    </Link>
                </div>
             
          </div>
        </div>
      </div>
      );
    }
  }
// export default connect(
//     ({ webinar }) => ({ ...webinar }),
//     dispatch => bindActionCreators({ ...webinarActions }, dispatch)
//   )( webinar );