import React, {Component} from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Video from "../../img/Volkeno.mp4";
import Bakeli from "../../img/bakeli school.mp4";
import banner1 from "../../img/banner1.jpg";
import abdoul from "../../img/abdoul.png";
import abdoul1 from "../../img/abdoul1.jpg";
import CardRentree from "../cardRentree/cardRentree";
import { ReactVideo } from "reactjs-media";
import Button from "../button/button";
import Adresse from "../adresse/adresse";
import Webinar from "../webinar/webinar";
import CardStage from "../cardStage/cardStage";
import Rectangle8623photo from '../../img/Rectangle8623photo.png';
import './contentVideo.css'
import { Link } from 'react-router-dom';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentVideoActions from "../../store/contentVideo/actions";
export default class contentVideo extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (


        <div className="p-b-200">
        <div className="row page-event">
          <div className="col-xl-9 col-lg-9 page-annonce">
            <div className="row m-b-15">
              <div className="col m-b-15">
                {/* <h6 className="h-theme ff-inter fw-600">Photo</h6>{" "} */}
                {/* <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item h-theme ff-inter fw-600"><Link to="/home" className="lien_nav"> Événement /</Link></li>
                        <li className="breadcrumb-item active" aria-current="page" className="lien_nav_current">Vidéo</li>
                    </ol>
                </nav> */}
                <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item h-theme ff-inter fw-600">
                  <Link to="/home" className="lien_nav">
                    {" "}
                    Accueil
                  </Link>
                </li>
                <li className="breadcrumb-item h-theme ff-inter fw-600">
                  <Link to="/mes-evenements" className="lien_nav">
                    {" "}
                    Liste de mes évènements
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                Vidéos
                </li>
              </ol>
            </nav>
              </div>
            <div className="col dis-flex m-b-15"></div>
        </div>

        <div className="component-content-video">
                <div className="container-fluid" id="pagevideo">
                    <div className="row py-3">
                        <div className="col-xl-4 col-lg-4 col-md-12 videos">
                            <div className="taille-videos-network">
                                <ReactVideo
                                    src={Video}
                                    poster={abdoul}
                                    primaryColor="red"
                                    // other props 
                                />
                            </div>
                            <div className="video-title">
                            Nom de la vidéo
                            </div>
                            <div className="largeur">
                            <a href="https://www.youtube.com/watch?v=RrHYyualUQI">
                                https://www.youtube.com/watch?v=RrHYyualUQI</a>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-12 videos">
                            <div>
                                <ReactVideo
                                    src={Bakeli}
                                    poster={banner1}
                                    primaryColor="red"
                                />
                            </div>
                            <div className="video-title">
                            Nom de la vidéo
                            </div>
                            <div className="largeur">
                            <a href="https://www.youtube.com/watch?v=RrHYyualUQI">https://www.youtube.com/watch?v=RrHYyualUQI</a>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-12 videos">
                            <div>
                                <ReactVideo
                                    src={Video}
                                    poster={abdoul1}
                                    primaryColor="red"
                                />
                            </div>
                            <div className="video-title">
                            Nom de la vidéo
                            </div>
                            <div className="largeur">
                            <a href="https://www.youtube.com/watch?v=RrHYyualUQI">https://www.youtube.com/watch?v=RrHYyualUQI</a>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-12 videos">
                        <div>
                            <ReactVideo                           
                                src={Video}
                                poster={abdoul}
                                primaryColor="red"
                         />          
                        </div>
                        <div className="video-title">
                            Volkeno SARL
                        </div>
                        <div className="largeur">
                            <a href="https://www.youtube.com/watch?v=RrHYyualUQI">https://www.youtube.com/watch?v=RrHYyualUQI</a>
                        </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-12 videos">
                            <div>
                                <ReactVideo
                                    src={Bakeli}
                                    poster={banner1}
                                    primaryColor="red"
                                />
                            </div>
                            <div className="video-title">
                            Nom de la vidéo
                            </div>
                            <div className="largeur">
                            <a href="https://www.youtube.com/watch?v=RrHYyualUQI">https://www.youtube.com/watch?v=RrHYyualUQI</a>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-12  videos">
                            <div>
                                <ReactVideo
                                    src={Video}
                                    poster={abdoul1}
                                    primaryColor="red"
                                />
                            </div>
                            <div className="video-title">
                            Nom de la vidéo
                            </div>
                            <div className="largeur">
                            <a href="https://www.youtube.com/watch?v=RrHYyualUQI">https://www.youtube.com/watch?v=RrHYyualUQI</a>
                            </div>
                        </div>                  
                        <div className="col-xl-4 col-lg-6 col-md-12  videos">
                            <div>
                                <ReactVideo
                                    src={Video}
                                    poster={abdoul}
                                    primaryColor="red"
                                />
                            </div>
                            <div className="video-title">
                            Nom de la vidéo
                            </div>
                            <div className="largeur">
                            <a href="https://www.youtube.com/watch?v=RrHYyualUQI">https://www.youtube.com/watch?v=RrHYyualUQI</a>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-12  videos">
                            <div>
                                <ReactVideo
                                    src={Video}
                                    poster={banner1}
                                    primaryColor="red"
                                />
                            </div>
                            <div className="video-title">
                                Nom de la vidéo
                            </div>
                            <div className="largeur">
                            <a href="https://www.youtube.com/watch?v=RrHYyualUQI">https://www.youtube.com/watch?v=RrHYyualUQI</a>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-12  videos">
                            <div>
                                <ReactVideo
                                    src={Video} 
                                    poster={abdoul1}
                                    primaryColor="red"
                                />
                            </div>
                            <div className="video-title">
                                Nom de la vidéo
                            </div>
                            <div className="largeur">
                            <a href="https://www.youtube.com/watch?v=RrHYyualUQI">https://www.youtube.com/watch?v=RrHYyualUQI</a>
                            </div>
                        </div>                                                                                                                                                                         
                    </div> 
                </div>      
            </div> 
        </div>
        <div className="col-xl-3 col-lg-3 my-5">
            <div className="row py-5 pr-5">
              <div className="col-sm-12 width_card">
                <Button />
              </div>
              <div className="col-sm-12 width_card">
                <Adresse />
              </div>
              <div className="col-sm-12 width_card">
                <Webinar />
              </div>
              <div className="col-sm-12 width_card">
                <CardRentree />
              </div>
              <div className="col-sm-12 width_card">
                <CardStage />
              </div>
            </div>
          </div>
        </div>
      </div>

      );
    }
  }
// export default connect(
//     ({ contentVideo }) => ({ ...contentVideo }),
//     dispatch => bindActionCreators({ ...contentVideoActions }, dispatch)
//   )( contentVideo );