import React, { Component } from "react";
import "./myAccount.css";
import FeatherIcon from "feather-icons-react";
import API from "../../variablesGlobales";
import base_api_url from "../../variablesGlobales";
import amplitude from "amplitude-js";
import axios from "axios";

export default class myAccount extends Component {
  constructor(props) {
    super(props);
    const userId = window.sessionStorage.getItem("user_id");
    this.state = {
      api: API.API,
      userId: userId,
      loading: true,
      uploadedfile: "",
      base_api_url: base_api_url.base_api_url,
      userFullName: "",
      userPhone: "",
      userAddress: "",
      userEmail: "",
      userAvatar: "",
      userFirstName: "",
      userLastName: "",
      userStatus: "",
      // userPath: window.sessionStorage.getItem('avatar_path'),
      hasFlash: false,
      flashType: "success",
      fields: {},
      flashMessage: "",
      addModificationProgress: false,
      config: {
        headers: {
          Content_Type: "multipart/form-data",
        },
      },
    };
    this.updateProfile = this.updateProfile.bind(this);

    axios.get(API.API + "get-profile/" + this.state.userId).then((res) => {
      const userData = res.data.data;
      this.setState({
        userFirstName: userData.first_name,
        userLastName: userData.last_name,
        userStatus: userData.status,
        userPhone: userData.phone,
        userAddress: userData.address,
        userEmail: userData.email,
        userAvatar: userData.avatar_file,
      });

      // this.setState({userAvatar: userData.avatar_file});
    });
  }
  handleChange = (e) => {
    let value = e.target.value;
    //console.log(value);
    if (e.target.name === "first_name") {
      this.setState({ userFirstName: value });
    } else if (e.target.name === "last_name") {
      this.setState({ userLastName: value });
    } else if (e.target.name === "phone") {
      this.setState({ userPhone: value });
    } else if (e.target.name === "address") {
      this.setState({ userAddress: value });
    } else if (e.target.name === "email") {
      this.setState({ userEmail: value });
    }
  };

  hideAlertAfter = (duration) => {
    setTimeout(() => {
      this.setState({ hasFlash: false });
    }, duration * 1000);
  };

  componentDidMount = () => {
    var eventProperties = {
      user_email: this.state.userEmail,
      user_id: this.state.userId,
      status: this.state.userStatus,
    };
    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    amplitude.getInstance().logEvent("VISITE_PAGE_PROFILE", eventProperties);

    this.isLoading = setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };
  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);
    amplitude.getInstance().init(api_key);
  };
  updateProfile = (e) => {
    e.preventDefault();
    this.setState({
      addModificationProgress: true,
    });

    var fd = new FormData();
    fd.append("first_name", this.state.userFirstName);
    fd.append("last_name", this.state.userLastName);
    fd.append("phone", this.state.userPhone);
    fd.append("address", this.state.userAddress);
    fd.append("email", this.state.userEmail);
    fd.append("avatar_file", this.state.uploadedfile);
    console.log(this.state.uploadedfile, "file");

    // fd.append('avatar_path', this.state.userAvatar);
    const user_id = this.state.userId;
    axios
      .put(this.state.api + "editer-mon-profil/" + user_id, fd)
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          this.setState({
            hasFlash: true,
            flashMessage: "Votre profil a bien été mis à jour.",
            addModificationProgress: false,
          });
          this.updateFormData(response.data.data);
          this.hideAlertAfter(2);
          this.props.history.push("/mon-profil");
        }
      })
      .catch((error) => {
        this.setState({
          successfullUpdate: false,
          successfullMessage:
            "Une erreur est survenue lors de la mise à jour de votre profil.",
        });
      });
  };

  updateFormData = (data) => {
    this.setState({ userFirstName: data.first_name });
    this.setState({ userLastName: data.last_name });
    this.setState({ userFirstName: data.first_name });
    this.setState({ userPhone: data.phone });
    this.setState({ userAddress: data.address });
    this.setState({ userEmail: data.email });
    // this.setState({userAvatar: data.avatar_file});
    // this.setState({userPath: data.avatar_file});
  };

  handlefileChange = (e) => {
    let fields = this.state.fields;
    if (e.target.name === "input_img") {
      if (this.fileValidation("input_img")) {
        this.setState({ uploadedfile: e.target.files[0] });
        console.log(e.target.files[0], this.state.uploadedfile);
        var fd = new FormData();
        fd.append("img", e.target.files[0]);
        fd.append("user_id", this.state.userId);
        axios
          .post(this.state.api + "change-user-pp", fd)
          .then((response) => {
            this.setState({ userAvatar: response.data.avatar_file });
            // if (response.data.success) {
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  fileValidation = (elementID) => {
    var fileInput = document.getElementById(elementID);
    var filePath = fileInput.value;

    // Allowing file type
    var allowedExtensions = /(\.png|\.jpg|\.jpeg|)$/i;

    if (!allowedExtensions.exec(filePath)) {
      fileInput.value = "";
      return false;
    } else {
      return true;
    }
  };

  render() {
    // const { loading } = this.state;
    console.log(this.state.base_api_url, this.state.userAvatar);

    return (
      <div className="card-dashboard p-lg-5 mb-3 pb-5">
        <div className="col-12 mb-md-0 mb-5">
           
       

          {/* <NavLink className="navigation-previous-account" to="/">
                  <FeatherIcon className="icon-navigation-previous" icon="arrow-left" />
                </NavLink> */}
          <p className="titre-navigation-previous-account pl-lg-0 pl-3">
            Mon profil
          </p>
        </div>

        <form
          encType="multipart/form-data"
          onSubmit={(e) => this.updateProfile(e)}
        >
          <div className="row mt-5 mb-3">
            <div className="col-xl-6 col-lg-4 col-md-4 col-sm-6 pr-5 mt-3 mb-2">
              <img
                className="avatar-account mb-2"
                src={this.state.base_api_url + this.state.userAvatar}
                alt=""
              />
            </div>
            <div className="col-xl-6 col-lg-4 col-md-4 col-sm-6 pr-5 mt-3">
              <div className="form-group">
                <label for="inputAvatar" className="label-account">
                  Vous voulez changer votre photo de profil? Cliquer ici{" "}
                </label>
                <input
                  type="file"
                  name="input_img"
                  className="form-control input-account"
                  id="input_img"
                  onChange={(e) => this.handlefileChange(e)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className={`form-group`}>
                <label for="first_name" className="label-account">
                  Prénom
                </label>
                <input
                  type="text"
                  name="first_name"
                  className="form-control input-account"
                  id="first_name"
                  value={this.state.userFirstName}
                  onChange={(e) => this.handleChange(e)}
                />
              </div>
              <div className="form-group">
                <label for="inputNom" className="label-account">
                  Nom
                </label>
                <input
                  type="text"
                  className="form-control input-account"
                  name="last_name"
                  id="last_name"
                  value={this.state.userLastName}
                  onChange={(e) => this.handleChange(e)}
                />
              </div>
            </div>
            <div className="col-md-6">
              {/* <div className="form-group">
                      <label for="inputemail" className="label-account">Adresse mail</label>
                      <input type="text" name="email" className="form-control input-account" id="email" value={this.state.userEmail} onChange={e=> this.handleChange(e)} />
                    </div> */}
              <div className="form-group">
                <label for="inputTel" className="label-account">
                  N° téléphone
                </label>
                <input
                  type="text"
                  name="phone"
                  className="form-control input-account"
                  id="phone"
                  value={this.state.userPhone}
                  onChange={(e) => this.handleChange(e)}
                />
              </div>
              <div className="form-group">
                <label for="inputAdresse" className="label-account">
                  Adresse
                </label>
                <input
                  type="text"
                  name="address"
                  className="form-control input-account"
                  id="address"
                  value={this.state.userAddress}
                  onChange={(e) => this.handleChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="row dis-flex justify-content-end mb-5"></div>
          {this.state.addModificationProgress ? (
            <button
              type="submit"
              className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
              disabled
            >
              Enregistrement en cours &nbsp;
              <FeatherIcon icon="loader" aria-hidden="true" />
            </button>
          ) : (
            <button
              type="submit"
              className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
            >
              ENREGISTRER LES MODIFICATIONS
            </button>
          )}
        </form>
        {this.state.hasFlash === true && (
          <div
            className={`alert alert-${this.state.flashType} text-center upsuccess-alert animated bounceIn`}
          >
            <i className="fas fa-check-double"></i> &nbsp;&nbsp;
            {this.state.flashMessage}
          </div>
        )}
      </div>
    );
  }
}
