import React, {Component} from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

import { Icon } from "leaflet";
import './cardLeaflet.css'
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as cardLeafletActions from "../../store/cardLeaflet/actions";
export default class cardLeaflet extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
        

        const position = [14.7437545, -17.4531848,15];
      return (
      <div className="component-card-leaflet">
          <MapContainer center={position} zoom={13} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={position}>
                        <Popup>
                        Hlm Grand Yoff Villa n°241.<br /> Près du terminus des bus Dakar Dem Dikk<br /> qui vont à l'AIBD Dakar Dakar, 11500
                            
                        </Popup>
                    </Marker>
                </MapContainer>
      </div>
      );
    }
  }
// export default connect(
//     ({ cardLeaflet }) => ({ ...cardLeaflet }),
//     dispatch => bindActionCreators({ ...cardLeafletActions }, dispatch)
//   )( cardLeaflet );