import React, {Component} from 'react';
import * as Icon from 'react-feather';
import {Link} from 'react-router-dom';
import './offreStage.css'
import facebook from '../../img/facebook.png'; 
import twitter from '../../img/twitter.png'; 
import whatsApp from '../../img/whatsApp.png'; 
import linkedin from '../../img/linkedin.png'; 
import instagram from '../../img/instagram.png'; 
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentActions from "../../store/content/actions";
export default class OffreStage extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
      <div className="component-offre-stage">
          <h3 className="article my-3 py-1">offre de stage</h3>
          <div className="card my-1 mt-2 offre-stage">
            <div className="card-body">
                <div className="card btom-card-offre-stage-bord">
               <span id="txt-designer-ui"><b>UI/XD Designer-Dakar, Senegal</b></span>
                <div class="card-body postuler-button-card-center">
                        <Link to="/postuler">
                            <button type="button" class="btn btn-primary btn-lg" id="Postuler-buton">Postuler</button></Link>
                
                    </div>
               
                
                <div className="row">
                    <div className="col-lg-6">
                        <span>Publié le 10/10/2020</span>
                    </div>
                    <div className="col-lg-6">Partager L'annonce
                        <img src={linkedin} className="dimunition-img-reseaux"/>
                        <img src={instagram} className="dimunition-img-reseaux"/>
                        <img src={twitter} className="dimunition-img-reseaux"/>
                        <img src={facebook} className="dimunition-img-reseaux"/>
                        <img src={whatsApp} className="dimunition-img-reseaux"/>
                    </div>
                </div>
                </div>
                
            <div className="col-6 col-sm-3">Détails de l'annonce</div>
            <div className="card offre-stage-card-bordure-non">
                
                <div className="card-body">
                <div className="col"><b>Identification du poste</b></div>
                        <div className="row">
                            <div className="col-6 col-sm-3">Intitulé :</div>
                            <div className="col-6 col-sm-3">UI/UX Design</div>

                        
                            </div>                            
                            <div className="row">
                                <div className="col-6 col-sm-3">Nature :</div>
                                <div className="col-6 col-sm-3">Stage</div>
                          </div> 

                          <div className="row">
                                <div className="col-6 col-sm-3">Lieu :</div>
                                <div className="col-6 col-sm-3">Dakar, Senegal</div>
                          </div> 

                          <div className="row">
                                <div className="col-6 col-sm-3">Date de debut :</div>
                                <div className="col-6 col-sm-3">immediat</div>
                          </div> 
                          <div className="row">
                                <div className="col-6 col-sm-3">Recruteur</div>
                                <div className="col-6 col-sm-3">Volkeno</div>
                          </div>  
                        </div>

                        <div className="row">
                            <span><b>Missions</b></span>
                            <p>taille, par exemple. la hauteur ci-dessus: 2em rendra le bouton
                             à deux fois la hauteur de la ligne. Cela fonctionne également pour 
                             les cases à cocher entrée type = case à cocher.
                             Certains navigateurs sont plus performants que d’autres.
                             Définir la bordure à 0 semble permettre à l’utilisateur 
                            de changer la taille du bouton et de le rendre dans cette 
                            taille, par exemple. la hauteur ci-dessus: 2em rendra le bouton
                             à deux fois la hauteur de la ligne. Cela fonctionne également pour 
                             les cases à cocher entrée type = case à cocher.
                             Certains navigateurs sont plus performants que d’autres.
                            </p>
                        </div>

                  <div className="card-body">
                     <div className="col"><b>Competences requises</b></div>

                     <div className="col">...Competences solides en UX design et humain-centred design thinkthink</div>
                     <div className="col">...Connaissances des langages de Programmation(React js, React Native principalement) et conception web(Front end)</div>
                     <div className="col">...Connaissances des principes d'utilisabilité et d'acceptibilité</div>
                     <div className="col">...Connaissances professionnelle et approfondie des outils UI/UX (suite adobe XD, Figma...)</div>
                     <div className="col">...Connaissances des programmes de graphisme et edition d'images</div>
                     <div className="col">...Créativité  et sans  esthétique</div>
                     <div className="col">...Excellentes capacités de communication</div>
                     <div className="col">...Flexibilité et résolution de problemes</div>
                     
                        <div className="row">
                            <div className="col-6 col-sm-3"><b>Métier</b></div>
                            </div>
                        <div className="row">
                            <div className="col">Informatique, nouvelles Technologies, Marketing communication</div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-sm-3"><b>Secteur</b></div>
                            </div>

                        <div className="row">
                            <div className="col">Informatique, SSII, Marketing, communication, médias</div>
                            </div>

                            <div className="row">
                                <div className="col"><b>Niveau d'experience</b></div>
                            </div>

                        <div className="row">
                            <div className="col">Débutant  2ans</div>
                            </div>

                            <div className="row">
                                <div className="col-6 col-sm-3"><b>Niveau D'études</b></div>
                            </div>

                        <div className="row">
                            <div className="col">BAC  BAC+2</div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-sm-3"><b>Langues exigées</b></div>
                            </div>

                        <div className="row">
                            <div className="col">Français > current</div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-sm-3"><b>Nombres de postes</b></div>
                            </div>

                        <div className="row">
                            <div className="col">20</div>
                            </div>
                         
                        </div>
                        </div>
                        </div>
                    <div class="card-body postuler-button-card-center">
                    <div className="row">
                    <div className="col">
                        <Link to="/postuler"><button type="button" class="btn btn-primary btn-lg" id="Postuler-buton">Postuler</button></Link>
                        </div>
                    </div>
               </div>
          
        </div>
        </div> 

     

      );
    }
  }
// export default connect(
//     ({ demande }) => ({ ...demande }),
//     dispatch => bindActionCreators({ ...contentActions }, dispatch)
//   )( content );