import React, { Component } from "react";
import "./centreInteret.css";
import Coaching from "../../img/coaching.png";
import Nitt from "../../img/nitt.png";
import Rafiki from "../../img/rafiki.png";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as centreInteretActions from "../../store/centreInteret/actions";
export default class centreInteret extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      <div className="component-centre-interet">
        <div className="container-fluid pt-5">
          <div className="row pt-4 pb-5">
            <div className="col-md-4 col-lg-4 pr-md-5 pl-4">
              <div className="d-flex justify-content-center">
                <div
                  className="contain-img-cm-marche"
                  style={{ background: `url(${Coaching})` }}
                ></div>
              </div>
              <h2 className="coaching-txt pt-5">Coaching</h2>
              <p className="title-cm-marche ml-5 pt-2">
                Tout au long de la formation, un coach de votre domaine <br />
                professionnel vous suit de près
              </p>
            </div>

            <div className="col-md-4 col-lg-4 pr-md-5 pl-5">
              <div className="d-flex justify-content-center">
                <div
                  className="contain-img-cm-marche"
                  style={{ background: `url(${Nitt})` }}
                ></div>
              </div>
              <h2 className="coaching-txt pt-5">Diplôme/certification</h2>
              <p className="title-cm-marche ml-5 pt-2">
                A la fin de la formation vous aurez soit un certificat soit un
                diplome delon votre duree de formation.
              </p>
            </div>
            <div className="col-md-4 col-lg-4 pr-md-5 pl-5">
              <div className="d-flex justify-content-center">
                <div
                  className="contain-img-cm-marche"
                  style={{ background: `url(${Rafiki})` }}
                ></div>
              </div>
              <h2 className="coaching-txt pt-5">Stage Garanti</h2>
              <p className="title-cm-marche ml-5 pt-2">
                Nous vous offrons un stage sur votre <br />
                filière à la fin de votre formation
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ centreInteret }) => ({ ...centreInteret }),
//     dispatch => bindActionCreators({ ...centreInteretActions }, dispatch)
//   )( centreInteret );
