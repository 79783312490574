import React, {Component} from 'react';
import './coachItemDashboardBakeliste.css';
import AvatarCoach from '../../img/coach.png';
import { Modal } from 'rsuite';

export default class coachItemDashboardBakeliste extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }
  close() {
    this.setState({ show: false });
  }
  open() {
    this.setState({ show: true });
  }

  render() {
    return  <div className="item-coach-dashboard-bakeliste m-b-15">
              <img className="avatar-coach-dashboard-bakeliste" src={AvatarCoach} alt="" />
              <div className="w-full">
                <p className="name-coach-dashboard-bakeliste">Sadio SANGHARE</p>
                <p className="function-coach-dashboard-bakeliste">Coach</p>
                <div className="dis-flex justify-content-end">
                  <button className="show-details-coach-dashboard-bakeliste" onClick={this.open}>
                  Détails
                  </button>
                </div>

                <div className="modal fade" id="btnShowDetailsCoach" tabindex="-1" role="dialog" aria-labelledby="btnShowDetailsCoachTitle" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content modal-details-coach">
                      <div className="dis-flex justify-content-center m-b-70">
                        <div className="col-md-7 dis-flex text-center flex-col">
                          <img className="avatar-details-coach-dashboard-bakeliste" src={AvatarCoach} alt="" />
                          <p className="name-details-coach-dashboard-bakeliste">Sadio SANGHARE</p>
                          <p className="email-details-coach-dashboard-bakeliste">saadio@volkeno.com</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-8 m-b-15">
                            <p className="h-info-details-coach-dashboard-bakeliste">Spécialité</p>
                            <p className="info-details-coach-dashboard-bakeliste">Développement WEB</p>
                        </div>
                        <div className="col-sm-4">
                            <p className="h-info-details-coach-dashboard-bakeliste">Téléphone</p>
                            <p className="info-details-coach-dashboard-bakeliste">77 000 00 00</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Modal className="border-radius-0 modal-responsive-sm" show={this.state.show} onHide={this.close}>
                  <Modal.Body className="max-height-modal pr-3 pl-3">
                    <div className="dis-flex justify-content-center m-b-70">
                      <div className="col-md-7 dis-flex text-center flex-col">
                        <img className="avatar-details-coach-dashboard-bakeliste" src={AvatarCoach} alt="" />
                        <p className="name-details-coach-dashboard-bakeliste">Sadio SANGHARE</p>
                        <p className="email-details-coach-dashboard-bakeliste">saadio@volkeno.com</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-8 m-b-15">
                          <p className="h-info-details-coach-dashboard-bakeliste">Spécialité</p>
                          <p className="info-details-coach-dashboard-bakeliste">Développement WEB</p>
                      </div>
                      <div className="col-sm-4">
                          <p className="h-info-details-coach-dashboard-bakeliste">Téléphone</p>
                          <p className="info-details-coach-dashboard-bakeliste">77 000 00 00</p>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>;
  }
}
