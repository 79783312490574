import React, { Component } from "react";
import { Link } from "react-router-dom";
import image from "../../img/image.png";
import imagee from "../../img/imagee.png";
import ContentEventAvenir from "../contentEventAvenir/contentEventAvenir";
import ContentEventRecent from "../contentEventRecent/contentEventRecent";
import Button from "../button/button";
import Adresse from "../adresse/adresse";
import Webinar from "../webinar/webinar";
import CardRentree from "../cardRentree/index";
import CardStage from "../cardStage/index";
import HeaderDashboard from "../headerDashboard/index";
import NavVertical from "../navVertical/index";
import axios from "axios";
import API from "../../variablesGlobales";
// import {} from 'react'
import "./contentEvent.css";
import ModalContactUs from "../modalContactUs/index";
import cardRentree from "../cardRentree/cardRentree";
// import Skeleton from 'react-loading-skeleton';
import Skeleton from "react-loading-skeleton";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentEventActions from "../../store/contentEvent/actions";

export default class contentEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      recentEvents: [],
      comingEvents: [],
      loading: true,
      loading2: true,
    };
  }

  componentDidMount = () => {
    this.getRecentEvents();
    this.getComingEvents();
  };

  getRecentEvents = () => {
    const api = this.state.api;
    axios
      .get(api + "get-recent-rencontres")
      .then((response) => {
        const { data } = response.data;
        this.setState({ recentEvents: data, loading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getComingEvents = () => {
    const api = this.state.api;
    axios
      .get(api + "get-coming-rencontres")
      .then((response) => {
        const { data } = response.data;
        this.setState({ comingEvents: data, loading2: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const recentEvents = this.state.recentEvents;
    const comingEvents = this.state.comingEvents;
    return (
      <div className="p-b-200">
        <div className="row page-event">
          <div className="col-xl-9 col-lg-9 event-page">
            <div className="row m-b-15">
              <div className="col m-b-15">
                {/* <h6 className="h-theme ff-inter fw-600">Evènements</h6>{" "} */}
                <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item h-theme ff-inter fw-600">
                  <Link to="/home" className="lien_nav">
                    {" "}
                    Accueil
                  </Link>
                </li>
             
                <li className="breadcrumb-item active" aria-current="page">
                Evènements
                </li>
              </ol>
            </nav>
              </div>
              <div className="col dis-flex m-b-15"></div>
            </div>
            <div className=" m-b-15 p-b-60">
              {/* <div style={{ fontSize: 20, lineHeight: 2 }}>
                <h5 className="h-theme ff-inter fw-600"> Recents</h5>
                {recentEvents.map((event) => (
                  <ContentEventRecent
                    name={event.rencontre_name}
                    description={event.rencontre_description}
                    date={event.rencontre_start_date}
                    year={event.posted_since_year}
                    month={event.posted_since_month}
                    day={event.posted_since_day}
                    hour={event.posted_since_hour}
                  />
                ))}
              </div> */}

              <h5 className="h-theme ff-inter fw-600"> A venir</h5>
              <div style={{ fontSize: 10, lineHeight: 2 }}>
                {comingEvents.map((event) => (
                  <ContentEventAvenir
                    id={event.id}
                    name={event.rencontre_name}
                    date={event.rencontre_start_date}
                  />
                ))}{" "}
                <h1>
                  {this.state.loading2 && (
                    <Skeleton
                      animation="wave"
                      height={300}
                      width="75%"
                      variant="rect"
                    />
                  )}
                </h1>
              </div>
              <div style={{ fontSize: 20, lineHeight: 2 }}>
                <h5 className="h-theme ff-inter fw-600"> Recents</h5>
                {recentEvents.map((event) => (
                  <ContentEventRecent
                    name={event.rencontre_name}
                    description={event.rencontre_description}
                    date={event.rencontre_start_date}
                    year={event.posted_since_year}
                    month={event.posted_since_month}
                    day={event.posted_since_day}
                    hour={event.posted_since_hour}
                  />
                ))}
              </div>
              {this.state.loading && (
                <>
                  <Skeleton
                    animation="wave"
                    height={200}
                    variant="rect"
                    width="75%"
                  />
                </>
              )}
            </div>

            {this.state.loading && (
              <>
                <Skeleton animation="wave" height={200} variant="rect" />
              </>
            )}
          </div>
          {this.state.loading && (
            <>
              <Skeleton animation="wave" height={70} variant="rect" />
            </>
          )}
          <div className="col-xl-3 col-lg-3 py-4">
            <div className="row pr-5">
              <div className="col-sm-12 width_card">
                <Button />
              </div>
              <div className="col-sm-12 width_card">
                <Adresse />
              </div>
              {/* <div className="col-sm-12 width_card">
                <Webinar />
              </div>
              <div className="col-sm-12 width_card">
                <CardRentree />
              </div>
              <div className="col-sm-12 width_card">
                <CardStage />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ contentEvent }) => ({ ...contentEvent }),
//     dispatch => bindActionCreators({ ...contentEventActions }, dispatch)
//   )( contentEvent );
