import React, {Component} from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Carousel from '../carousel/carousel';
import './contentSavoirpluus.css';
import Adresse from "../adresse/adresse";
import Webinar from "../webinar/webinar";
import CardRentree from "../cardRentree/cardRentree";
import CardStage from "../cardStage/cardStage";
import Button from "../button/button";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentSavoirpluusActions from "../../store/contentSavoirpluus/actions";
export default class contentSavoirpluus extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
      <div className="component-content-savoirpluus">
        <div className="row">
          <div className="col-lg-9">
           <div className="article my-3 py-2">
                    <a>Formations <NavigateNextIcon /></a>
                    
                    <a>En savoir plus</a>
                </div>
                <div className="container-fluid px-0 kokutana">
                <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 textgreen py-3 pl-4">
                        En savoir plus sur les offres de stage
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 pb-5 ml-3">
                        Faites défiler pour voir les offres de stages disponibles
                        </div>
                    </div>
                    <div className="test">
                        <Carousel />
                    </div>
                        
                    </div>
                    
                </div>
                <div className="col-xl-3 col-lg-3 py-4">
            <div className="row pr-5">
              <div className="col-sm-12 width_card">
                <Button />
              </div>
              <div className="col-sm-12 width_card">
                <Adresse />
              </div>
              <div className="col-sm-12 width_card">
                <Webinar />
              </div>
              <div className="col-sm-12 width_card">
                <cardRentree />
              </div>
              <div className="col-sm-12 width_card">
                <CardStage />
              </div>
            </div>
          </div>
          </div>
      </div>
      );
    }
  }
// export default connect(
//     ({ contentSavoirpluus }) => ({ ...contentSavoirpluus }),
//     dispatch => bindActionCreators({ ...contentSavoirpluusActions }, dispatch)
//   )( contentSavoirpluus );