import React, { Component } from "react";
import { useState } from "react";

import Content from "../content/content";
import Button from "../button/button";
import Adresse from "../adresse/adresse";
import NavVertical from "../navVertical/index";
import HeaderDashboard from "../headerDashboard/index";
import DossierCandidature from "../dossierCandidature/DossierCandidature";
import API from "../../variablesGlobales";
import axios from "axios";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as viewAccueilActions from "../../store/viewAccueil/actions";

export default class viewDossierCandidature extends Component {
  constructor(props) {
      super(props);
      const id= this.props.location.state.id;
      var retrievedObject = localStorage.getItem("user");
      this.state = {
        user: JSON.parse(retrievedObject),
        dossier: [],
        id: id,
        api:API.API,

      };
  }

  componentDidMount() {
    this.getCandidaturById();
    console.log("dossier", this.state.candidature);
    console.log(this.state.id)

  }



getCandidaturById = () => {
  axios
    .get(this.state.api + "candidatures/" + this.state.id)
    .then((response) => {
      this.setState({
        dossier: response.data.data,
      });
    })
    .catch((error) => {
      console.log(error.message);
      if (process.env.NODE_ENV !== "production") {
        return;
      }
    });
};
  render() {
    return (
      <div className="component-view-message">
        {" "}
        <HeaderDashboard />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVertical /> 
            </div>
            {/* {this.state.dossier.map((item, index) => ( */}
            <div className="col-lg-10 pt-3">
           
              <DossierCandidature
              date={this.state.dossier.date_naissance}
              motivation={this.state.dossier.motivation}
              phone={this.state.dossier.phone_whatsapp}
              domaine_id={this.state.dossier.domaine_id}
              lieu_formation={this.state.dossier.lieu_formation}
              type_formation={this.state.dossier.type_formation}
              duree_formation={this.state.dossier.duree_formation}

              />
           
            </div>
             {/* ))} */}
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ viewAccueil }) => ({ ...viewAccueil }),
//     dispatch => bindActionCreators({ ...viewAccueilActions }, dispatch)
//   )( viewAccueil );
