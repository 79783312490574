import React, {Component} from 'react';
import './accompagnerLeading.css';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as accompagnerLeadingActions from "../../store/accompagnerLeading/actions";
export default class accompagnerLeading extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
        <div className="component-accompagner-leading">
          <div className="container-fluid bg-white pt-4">
              <div className="row boder-component">
                  <div className="col-lg-4 col-md-4 col-xl-4 col-sm-2 dis-flex">
                    <div className="pt-5">
                    <a href="inscription">
                      <button className="btn-rejoint-aventure-leading">Rejoindre l’aventure</button>
                      </a>
                      </div>
                  </div>
                  <div className="col-lg-8 col-md-8 col-xl-8 col-sm-4 dis-flex">
                  <div class="arrow-left-accompagner">
                    <div className="txt-center-acc pt-5">
                      <span className="txt-accompagner-bakeli">Bakeli est là pour vous accompagner</span>
                    </div>
                    </div>
                  </div>
              </div>
          </div>
        </div>
        );
    }
  }
// export default connect(
//     ({ accompagnerLeading }) => ({ ...accompagnerLeading }),
//     dispatch => bindActionCreators({ ...accompagnerLeadingActions }, dispatch)
//   )( accompagnerLeading );