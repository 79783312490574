import React, { Component } from "react";
import "./viewnetworkLeading.css";
import AventureAccueil from "../aventureAccueil/index";
import CentreInteret from "../centreInteret/index";
import NavbarNetworkLeading from "../navbarNetworkLeading/index";
import SommesNous from "../sommesNous/sommesNous";
import TemoignageLeading from "../temoignageLeading/index";
import Footer from "../footer/index";
import AccompagnerLeading from "../accompagnerLeading/index";
import amplitude from "amplitude-js";
import VideoTemoignages from "../videoTemoignages/videoTemoignages";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as viewnetworkLeadingActions from "../../store/viewnetworkLeading/actions";
export default class viewnetworkLeading extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  } 
  componentDidMount() {
    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    amplitude.getInstance().logEvent("VISITED_LANDING_PAGE_NETWORK");
    // console.log(this.state.groupe_id);
    // <!--Start of Tawk.to Script-->
 
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/60c9feb47f4b000ac037ee63/1f8aglsa4";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
    // <!--End of Tawk.to Script-->
  }
  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e]; 
      };
      e.amplitude = n;
    })(window, document);
    amplitude.getInstance().init(api_key);
  };
  render() {
    return (
      <div className="component-viewnetwork-leading theme-body">
        <div>
          <NavbarNetworkLeading />
        </div>
        <div className="avent-bak">
          <AventureAccueil />
        </div>

        <div className="avent-bak-aventure">
          <CentreInteret />
        </div>
        <div className="pt-3">
          <SommesNous />
        </div>
        <div className="pt-3">
          <TemoignageLeading />
        </div>
        <div className="pt-3">
          <VideoTemoignages />
        </div>
        <div className="">
          <AccompagnerLeading />
        </div>
        <div className="">
          <Footer />
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ viewnetworkLeading }) => ({ ...viewnetworkLeading }),
//     dispatch => bindActionCreators({ ...viewnetworkLeadingActions }, dispatch)
//   )( viewnetworkLeading );
