import React, { Component } from "react";
import "./programmation.css";
import axios from "axios";
import prog from "../../img/prog.png";
import { Link } from "react-router-dom";
import Button from "../button/button";
import Adresse from "../adresse/adresse";
import Webinar from "../webinar/webinar";
import CardRentree from "../cardRentree/cardRentree";
import CardStage from "../cardStage/cardStage";
import ReactPlayer from "react-player";
import TutorielSkeleton from "./tutorielSkeleton";
// import 'node_modules/react-modal-video/scss/modal-video.scss';
// import ModalVideo from 'react-modal-video'
import {Modal} from 'rsuite';
// import SweetAlert from 'sweetalert2-react';
import FeatherIcon from 'feather-icons-react';
import ModalVideo from 'react-modal-video'
import  Youtube  from "../../img/youtube.jpeg";
//client id
// 267686537578-2eutodo9uood4u3r31upp43a9hbnbkd2.apps.googleusercontent.com

// id
// QJAeuhwvPcyiQa8ih0Hhrvig

export default class Programmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video: [],
      isOpen: false,
      isLoaded: true,
      show: false,
      tuto:{},
      tutoId: "",
      tutoInfos: [],


    }
    this.openModal = this.openModal.bind(this);
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  } 

  componentWillMount() {
    this.VideoList();
  }
  onGetTutoInfos(tab) {
    this.setState({
      tutoInfos: tab,
      tutoId:tab.snippet.resourceId.videoId
     
    });
    console.log("edre", this.state.tutoInfos)
  }
  VideoList() {
    axios
      .get(
        "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PLOUCG3NjHUwvjLRsOekAxt0kCTrdjnSIf&key=AIzaSyB0L-UNk8-0qwtaZLkSlKjfdGrhONqPdCw"
      )
      .then((response) => {
        // console.log("video", response.data.items);
        this.setState({ 
          video: response.data.items,
          isLoaded: false,

        });
        console.log("test", this.state.video);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  goProgrammation = () => {
    window.location = "/demande-candidature";
  };
  Open = () => {
    this.setState({
      setOpen: true
    })
  }
  oppen = () => {
    this.setState({
      setOpen: false
    })
  }
  openModal () {
    this.setState({isOpen: true})
  }
  close() {
    this.setState({ show: false });
  }
  open(size ) {
    this.setState({ 
      size,
      show: true
     });
  }
  render() {
    // console.log(this.state.video)
    return (
      <div className="p-b-200">
        {this.state.isLoaded ?(
         <TutorielSkeleton />
        )
        :
        (
<div className="row dev-page">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item h-theme ff-inter fw-600">
                  <Link to="/home" className="lien_nav">
                    {" "}
                    Accueil
                  </Link>
                </li>
             
                <li className="breadcrumb-item active" aria-current="page">
                  Programmation
                </li>
              </ol>
            </nav>

   <div className="row mb-5">
             {this.state.video.map((tab) => {
              return (
                <>
            
  <div className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 px-md-2">
                 <img
                   src={tab.snippet.thumbnails.medium.url}
                  />
            
                <button class="ytp-large-play-button ytp-button" aria-label="Lire" size="lg" onClick={() =>  this.onGetTutoInfos(tab) }>
                  <svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%">
                    <path class="ytp-large-play-button-bg" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill="#f00"></path>
                    <path d="M 45,24 27,14 27,34" fill="#fff"></path>
                  </svg>
                </button>
               
                 <Modal className="modal-responsive-lg" size={this.state.size}  show={this.state.show} onHide={this.close}>
                    <button type="button" className="close-modal-bakeliste-title" onClick={this.close}>
                      <FeatherIcon icon="x" aria-hidden="true" />
                    </button>
                    {tab.snippet.resourceId.videoId}
                    <ReactPlayer
                    autoplay
                    url={`https://www.youtube.com/watch?v=${tab.snippet.resourceId.videoId}`}
                  />
                      
                  
                  </Modal>
                </div>
              </>

              
              );
            })}

            </div>
          </div>

          <div className="col-xl-3 col-lg-3 pr-5 my-5 py-4">
            <div className="row pr-5">
              <div className="col-sm-12 width_card">
                <Button />
              </div>
              <div className="col-sm-12 width_card">
                <Adresse />
              </div>
            
            </div>
          </div>
        </div>
        )
        }

        
      </div>
    );
  }
}
