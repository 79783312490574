import React, {Component} from 'react';
import './aventureAccueil.css';
import HomeImage from '../../img/HomeImage.png';
import { Link } from 'react-router-dom';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as aventureAccueilActions from "../../store/aventureAccueil/actions";
export default class aventureAccueil extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return( 
          <div className="component-aventure-accueil">
            <div className="container-fluid">
              <div className="row bg-image-aventure">
                <div className="rejoigner-aventure">
                    <span className="txt-rejoint-aventure">Rejoignez l’aventure <br />de bakeli</span>
                    <div className="pt-5">
                      <a href="inscription">
                        <button className="btn-commencer-aventure">Commencer</button>
                        </a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
  }
// export default connect(
//     ({ aventureAccueil }) => ({ ...aventureAccueil }),
//     dispatch => bindActionCreators({ ...aventureAccueilActions }, dispatch)
//   )( aventureAccueil );